import type { InputProps } from 'components/01-atoms/Input';
import Input from 'components/01-atoms/Input';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import clsx from 'clsx';
import { useToggle } from 'helpers/hooks/useToggle';
import Icon from 'components/01-atoms/Icon';

export type PasswordInputProps = InputProps;

const PasswordInput = forwardRef(
  (
    { className, error, ...inputProps }: PasswordInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const hasError = Boolean(error);
    const [isPasswordVisible, { toggle: togglePasswordVisible }] =
      useToggle(false);

    return (
      <div className={clsx(className, 'relative')}>
        <Input
          {...inputProps}
          ref={ref}
          error={error}
          hasIcon
          type={isPasswordVisible ? 'text' : 'password'}
        />
        <button
          aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
          className={clsx('absolute top-4 right-4', {
            'text-dark-gray': !hasError,
            'text-error': hasError
          })}
          onClick={togglePasswordVisible}
          type="button"
        >
          <Icon
            type={isPasswordVisible ? 'eyeVisible' : 'eyeHidden'}
            width={24}
          />
        </button>
      </div>
    );
  }
);

export default PasswordInput;
