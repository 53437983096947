import {
  BROADCAST_PLAYER_ID,
  DRM_VIDEO_PLAYER_ID,
  LIVE_TV_PLAYER_ID,
  SERIES_PLAYER_ID,
  SHORTS_VIDEO_PLAYER_ID,
  VIDEO_PLAYER_ID
} from 'helpers/constants/jwplayer';
import { noop } from 'helpers/utils/noop';
import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'react-use';

const LOCAL_STORAGE_KEY = 'adComplete';

const ExpirationTime = {
  [BROADCAST_PLAYER_ID]: 10 * 60 * 1000, // 10 min
  Default: 2 * 60 * 1000, // 2 min
  [LIVE_TV_PLAYER_ID]: 10 * 60 * 1000 // 10 min
} as const;

const enabledPlayers = [
  BROADCAST_PLAYER_ID,
  LIVE_TV_PLAYER_ID,
  SERIES_PLAYER_ID,
  VIDEO_PLAYER_ID,
  DRM_VIDEO_PLAYER_ID,
  SHORTS_VIDEO_PLAYER_ID
];

export const useAdsWithExpirationTime = (
  player: Nullable<JWPlayer>,
  playerId: string
) => {
  const [value, setValue] = useLocalStorage<string>(
    LOCAL_STORAGE_KEY,
    JSON.stringify({})
  );

  const isFeatureEnabled = enabledPlayers.includes(playerId);

  const getIsAdsFreeByExpirationTime = useCallback(() => {
    /* check if feature is enabled for current player */
    if (!isFeatureEnabled) return false;

    const adComplete = JSON.parse(value);
    const timestamp = Number(adComplete[playerId]);

    const playerExpirationTime =
      ExpirationTime[playerId] ?? ExpirationTime.Default;

    /* check if the user already seen ads before expiration time */
    if (!timestamp || Date.now() > timestamp + playerExpirationTime)
      return false;

    return true;
  }, [isFeatureEnabled, playerId, value]);

  const handleAdComplete = useCallback(() => {
    setValue(
      JSON.stringify({
        ...JSON.parse(value),
        [playerId]: Date.now()
      })
    );
  }, [playerId, setValue, value]);

  useEffect(() => {
    if (!player || !isFeatureEnabled) return noop;

    player.on('adComplete', handleAdComplete);

    return () => {
      player.off('adComplete', handleAdComplete);
    };
  }, [handleAdComplete, isFeatureEnabled, player]);

  return getIsAdsFreeByExpirationTime();
};
