import clsx from 'clsx';
import Button from 'components/02-molecules/Button';
import Icon from 'components/01-atoms/Icon';
import type { ButtonProps } from 'components/02-molecules/Button/types';

type Props = Omit<ButtonProps, 'as' | 'color' | 'variant'>;

export const PrimaryButton = ({ className, ...restProps }: Props) => (
  <Button
    as="button"
    className={clsx('h-14', className)}
    fullWidth
    variant="contained"
    {...restProps}
  />
);

export const SecondaryButton = ({ className, ...restProps }: Props) => (
  <Button
    as="button"
    className={clsx('p-3 mx-auto', className)}
    variant="text"
    {...restProps}
  />
);

export const BackButton = ({
  className,
  ...restProps
}: Omit<Props, 'text'>) => (
  <Button
    ariaLabel="Back Button"
    as="button"
    className={clsx('text-dark-gray hover:text-dark-gray', className)}
    icon={<Icon className="text-dark-gray" type="chevronLeft" width="24" />}
    size="sm"
    text=""
    variant="text"
    {...restProps}
  />
);

export const CloseButton = ({
  className,
  iconColor,
  ...restProps
}: Omit<Props, 'text'> & { iconColor: string }) => (
  <Button
    ariaLabel="Close Button"
    as="button"
    className={clsx('text-dark-gray hover:text-dark-gray', className)}
    icon={<Icon className={iconColor} type="close" width="24" />}
    size="sm"
    text=""
    variant="text"
    {...restProps}
  />
);
