import Button from 'components/02-molecules/Button';
import type { ButtonProps } from 'components/02-molecules/Button/types';

type Props = Omit<ButtonProps, 'as' | 'color' | 'variant'>;

export const PrimaryButton = (props: Props) => (
  <Button as="button" size="sm" variant="contained" {...props} />
);

export const SecondaryButton = (props: Props) => (
  <Button as="button" size="sm" variant="text" {...props} />
);
