import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import Icon from 'components/01-atoms/Icon';
import Badge from 'components/01-atoms/Badge';

import { Price, TileHeading, TileText } from './Typography';

type SharedSelectorTileProps = {
  className?: ClassValue;
  onSelect?: VoidFunction;
  selected?: boolean;
};

type SelectorTileProps = SharedSelectorTileProps & {
  content: ReactNode;
  footer: ReactNode;
  header: ReactNode;
};

export const SelectorTile = ({
  className,
  content,
  footer,
  header,
  onSelect,
  selected
}: SelectorTileProps) => (
  // TODO: make sure it comply to a11y
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div
    className={clsx(
      className,
      'flex flex-col p-4 border-2 text-left rounded-lg text-white',
      {
        'border-neutral-900': !selected,
        'border-primary': selected
      }
    )}
    onClick={onSelect}
  >
    <div className="mb-2">{header}</div>
    <div className="mb-4 flex-1">{content}</div>
    <div className="flex justify-between items-end">
      {footer}
      {selected && <Icon className="text-primary" type="check" width={24} />}
    </div>
  </div>
);

export const FreeSelectorTile = (props: SharedSelectorTileProps) => (
  <SelectorTile
    {...props}
    content={
      <TileText>
        Get access to a limited amount of content on Outside TV.
      </TileText>
    }
    footer={<TileHeading>Free</TileHeading>}
    header={<TileHeading>Limited Content</TileHeading>}
  />
);

export const PaidSelectorTile = (props: SharedSelectorTileProps) => (
  <SelectorTile
    {...props}
    content={
      <TileText>
        Enjoy unlimited access to films & series, GPS apps, and expert-led
        courses.
      </TileText>
    }
    footer={<Price className="mt-4" />}
    header={
      <div className="flex gap-2">
        <TileHeading>Unlimited Access</TileHeading>
        <Badge text="Upgrade" variant="gold" />
      </div>
    }
  />
);
