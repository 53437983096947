import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import useHasNodes from 'helpers/hooks/useHasNodes';
import PianoMountingPoint from 'components/01-atoms/PianoMountingPoint';
import { usePlayerActions } from 'helpers/hooks/usePlayerActions';
import { useEvent } from 'react-use';
import { PianoEventName, PianoMountingId } from 'helpers/constants/piano';
import { piano } from 'lib/piano';

type Props = {
  className?: ClassValue;
};

const PianoModalMountingPoints = ({ className }: Props) => {
  const pianoContainerExternalRef = useRef<HTMLDivElement>(null);
  const pianoContainerInternalRef = useRef<HTMLDivElement>(null);

  const { block, unblock } = usePlayerActions();

  const { hasNodes: externalHasNodes } = useHasNodes(pianoContainerExternalRef);

  useEvent(PianoEventName.ModalClose, unblock, piano);

  useEffect(() => {
    if (externalHasNodes) {
      block();
    }
  }, [block, externalHasNodes]);

  return (
    <>
      <PianoMountingPoint
        ref={pianoContainerExternalRef}
        className={clsx(
          className,
          /* These classes apply backdrop and content position to the modal based on the presence */
          /* Height is calculated based on the visible parts of the screen excluding the parts of the mobile interface. */
          'bg-[#22222211] sm:flex overflow-y-auto flex-col justify-end items-end h-[100dvh] empty:h-0 backdrop-filter backdrop-blur-md'
        )}
        id={PianoMountingId.Modal}
      />
      <PianoMountingPoint
        ref={pianoContainerInternalRef}
        className={clsx(className)}
        id={PianoMountingId.InternalModal}
      />
    </>
  );
};

export default PianoModalMountingPoints;
