import type { Image } from 'helpers/types/jwplayer-subgraph';

export const getImageByWidth = (items: Image[], width: number) => {
  if (!items?.length) return null;

  return (
    items.find((item) => item.width === width) ||
    items.find((item) => item.width > width) ||
    items[items.length - 1]
  );
};
