import { getPlayerScript } from './media/getPlayerScript';

const PLAYER_PREFIX = 'jwplayer-';
const SCRIPT_PREFIX = 'jwplayer-script-';

export const getJWPlayerInstance = (playerId: string) =>
  window.jwplayer(getPlayerInstanceId(playerId));

export const getPlayerInstanceId = (playerId: string) =>
  `${PLAYER_PREFIX}${playerId}`;

export const getJWPlayerScriptInstance = (playerId: string) =>
  document.querySelector(`script#${getJWPlayerScriptId(playerId)}`);

export const setJWPlayerScriptId = (playerId: string) => {
  const scriptTag = document.querySelector(
    `script[src*="${getPlayerScript(playerId)}"`
  );
  if (scriptTag) {
    scriptTag.id = getJWPlayerScriptId(playerId);
  }
};

export const getJWPlayerScriptId = (playerId: string) =>
  `${SCRIPT_PREFIX}${playerId}`;

export const removeJWPlayerScript = (playerId: string) => {
  getJWPlayerScriptInstance(playerId)?.remove();
  window.jwplayer = null;
};
