import type { ReactNode } from 'react';
import type { ClassValue } from 'clsx';
import clsx from 'clsx';

type Props = {
  children: ReactNode;
  className?: ClassValue;
};

const baseClass = 'font-plus-jakarta-sans whitespace-pre-line';

export const Heading = ({ children, className }: Props) => (
  <h2
    className={clsx(
      baseClass,
      'text-16 md:text-18 font-700 text-primary',
      className
    )}
  >
    {children}
  </h2>
);

export const Text = ({ children, className, ...restProps }: Props) => (
  <p
    {...restProps}
    className={clsx(
      baseClass,
      'text-14 lg:text-16 font-400 text-[#D3D3D3]',
      className
    )}
  >
    {children}
  </p>
);
