import { useEvent } from 'react-use';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import {
  PianoEventName,
  PianoMountingId,
  PianoPlaceholderTemplateId
} from 'helpers/constants/piano';
import { piano } from 'lib/piano';
import type { ShowTemplateEventPayload } from 'lib/piano/types';
import { createPortal } from 'react-dom';
import PianoRegistrationFlow from 'components/04-templates/PianoRegistrationFlow';
import EncouragementDialog from 'components/04-templates/PianoDialogs/EncouragementDialog';
import { usePlayerActions } from 'helpers/hooks/usePlayerActions';
import { getOPlusPageURL } from 'helpers/utils/accountsUrls';

const PianoPlaceholderTemplates = () => {
  const [templateId, setTemplateId] = useState<Nullable<string>>(null);
  const [templateType, setTemplateType] =
    useState<Nullable<'dialog' | 'modal'>>(null);
  const router = useRouter();

  const mountingPoint = useMemo(() => {
    if (templateType === 'dialog') return PianoMountingId.InternalDialog;
    return PianoMountingId.InternalModal;
  }, [templateType]);

  const { block, unblock } = usePlayerActions();

  const unmountTemplate = useCallback(() => {
    piano.sendRemoteActionToTemplate('close', 'dialog');
    piano.sendRemoteActionToTemplate('close', 'modal');
    unblock();
    setTemplateId(null);
    setTemplateType(null);
  }, []);

  useEvent(
    PianoEventName.ShowTemplate,
    (event: CustomEvent<ShowTemplateEventPayload>) => {
      const { containerSelector, templateId: newTemplateId } = event.detail;
      const newTemplateType =
        containerSelector === `#${PianoMountingId.Dialog}` ? 'dialog' : 'modal';

      setTemplateId(newTemplateId);
      setTemplateType(newTemplateType);
    },
    piano
  );

  useEffect(
    () => unmountTemplate,
    [router.query.id, router.query.episode, unmountTemplate]
  );

  const getTemplate = () => {
    switch (templateId) {
      case PianoPlaceholderTemplateId.FullyLockedRegistrationWall:
        return (
          <PianoRegistrationFlow onClose={unmountTemplate} variant="locked" />
        );
      case PianoPlaceholderTemplateId.PartiallyLockedRegistrationWall:
        return (
          <PianoRegistrationFlow
            onClose={unmountTemplate}
            variant="partially-locked"
          />
        );
      case PianoPlaceholderTemplateId.FreeRegistrationWall:
        return (
          <PianoRegistrationFlow onClose={unmountTemplate} variant="free" />
        );
      case PianoPlaceholderTemplateId.RegisterEncouragementDialog:
        return (
          <EncouragementDialog
            encourageTo="register"
            onAction={() => {
              block();
              piano.sendRemoteActionToTemplate('close', 'dialog');
              setTemplateType('modal');
              setTemplateId(PianoPlaceholderTemplateId.FreeRegistrationWall);
            }}
            onReject={unmountTemplate}
          />
        );
      case PianoPlaceholderTemplateId.UpgradeEncouragementDialog:
        return (
          <EncouragementDialog
            encourageTo="upgrade"
            onAction={() => {
              block();
              router.push(getOPlusPageURL());
            }}
            onReject={unmountTemplate}
          />
        );
      default:
        return null;
    }
  };

  if (!templateId || !templateType) return null;

  return createPortal(getTemplate(), document.getElementById(mountingPoint));
};

export default PianoPlaceholderTemplates;
