import { useEffect, useState } from 'react';
import { useUserType } from 'helpers/hooks/useUserType';
import { useRouter } from 'next/router';
import { useEvent, useInterval } from 'react-use';
import { usePianoStoreContext } from 'contexts/PianoStoreContext';
import { saveVideoWatchTime } from 'apis/watchTimes/queryFunctions';
import { piano } from 'lib/piano';

import {
  calculateTimeLeft,
  getInitialValues,
  isReportDataValid
} from './helpers';
import { REPORTING_INTERVAL, TIME_LEFT_PUSHING_INTERVAL } from './constants';

type Input = {
  enabled: boolean;
  mediaItemLink: string;
  playerRef: JWPlayer;
};

export const usePianoWatchTimes = ({
  enabled,
  mediaItemLink,
  playerRef
}: Input) => {
  const router = useRouter();
  const { isLoggedIn } = useUserType();
  // Null values means uninitialized
  const { timeLimit } = usePianoStoreContext();
  const [startTime, setStartTime] = useState<number>(null);
  const [watchedTime, setWatchedTime] = useState<number>(null);
  const isReady =
    timeLimit !== null && watchedTime !== null && startTime !== null;

  useEffect(() => {
    if (!enabled || !isLoggedIn) return;
    getInitialValues(playerRef).then(
      ([initialWatchedTime, initialStartTime]) => {
        setWatchedTime(initialWatchedTime);
        setStartTime(initialStartTime);
      }
    );
  }, [
    enabled,
    isLoggedIn,
    router.query.id,
    router.query.episode,
    playerRef,
    router
  ]);

  useEvent('seeked', () => setStartTime(playerRef.getPosition()), playerRef);

  useInterval(
    async () => {
      const currentTime = playerRef.getPosition();
      const timeDifference = currentTime - startTime;
      // This line fixes the issue when the player reaches the end and replays the content.
      // The time difference had a value equal to the negative length of the content. (startTime was left at the end of the content)
      if (timeDifference < 0) setStartTime(currentTime);
      // We don't want to report when player is paused nor seeking glitches
      if (timeDifference < 5 || timeDifference > 20) return;

      const reportData = {
        mediaItemLink,
        watchTimeEnd: Math.round(currentTime),
        watchTimeStart: Math.round(startTime)
      };
      if (!isReportDataValid(reportData)) return;
      const reportResponse = await saveVideoWatchTime(reportData);
      setWatchedTime(reportResponse.saveVideoWatchTime.meter);
      setStartTime(currentTime);
    },
    isReady ? REPORTING_INTERVAL : null
  );

  // Every time timeLeft has changed
  useEffect(() => {
    if (!isReady) return;
    const timeLeft = calculateTimeLeft(timeLimit, watchedTime);
    if (timeLeft === null) return;

    // Start pushing timeLeft to piano dialog
    const intervalId = setInterval(
      () => piano.sendTimeCountingUpdate(timeLeft, 'dialog'),
      TIME_LEFT_PUSHING_INTERVAL
    );

    const limitWasReached = timeLeft <= 0;

    piano.setCustomVariable('timeLimitReached', limitWasReached);

    if (limitWasReached) {
      piano.sendRemoteActionToTemplate('close', 'dialog');
      piano.executeExperience();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      piano.removeCustomVariable('timeLimitReached');
      clearInterval(intervalId);
    };
  }, [isReady, timeLimit, watchedTime, router.query.id, router.query.episode]);

  // Once all variables are initialized we want to execute experience
  useEffect(() => {
    if (!isReady) return;
    // TODO: temporary fix for Select your level of access displaying twice on PBC after registration
    piano.setCustomVariable('firstSignIn', null);
    piano.executeExperience();
  }, [isReady, router.query.id, router.query.episode]);
};
