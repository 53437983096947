import clsx from 'clsx';

type Variant = 'darkGray' | 'gold' | 'primary' | 'secondary';

type Props = {
  classes?: string;
  text: string;
  variant?: Variant;
};

const Badge = ({ classes, text, variant = 'primary' }: Props) => (
  <div
    className={clsx(
      classes,
      'flex items-center p-1 text-12 leading-5 rounded-sm font-bold font-plus-jakarta-sans whitespace-nowrap',
      {
        'bg-[#FFD100] text-black': variant === 'gold',
        'bg-neutral-900 text-white': variant === 'primary',
        'bg-new-neutral-900 text-white font-normal': variant === 'darkGray',
        'bg-secondary-500 text-white': variant === 'secondary'
      }
    )}
    suppressHydrationWarning
  >
    {text}
  </div>
);

export default Badge;
