import type { AnchorHTMLAttributes, ReactNode } from 'react';
import type { ClassValue } from 'clsx';
import clsx from 'clsx';

type Props = {
  children: ReactNode;
  className?: ClassValue;
};

const baseClass = 'font-plus-jakarta-sans whitespace-pre-line';

export const Heading = ({ children, className }: Props) => (
  <h2
    className={clsx(
      baseClass,
      'text-headline-2 font-800 text-white',
      className
    )}
  >
    {children}
  </h2>
);

export const TileHeading = ({ children, className }: Props) => (
  <h3 className={clsx(baseClass, 'text-20 font-700 text-white', className)}>
    {children}
  </h3>
);

export const SubHeading = ({ children, className }: Props) => (
  <h3 className={clsx(baseClass, 'text-20 font-700 text-primary', className)}>
    {children}
  </h3>
);

export const Link = ({
  children,
  className,
  ...restProps
}: AnchorHTMLAttributes<HTMLAnchorElement> & Props) => (
  <a className={clsx('text-[#009CDD]', className)} {...restProps}>
    {children}
  </a>
);

export const Text = ({ children, className, ...restProps }: Props) => (
  <p
    {...restProps}
    className={clsx(baseClass, 'text-20 font-400 text-[#D3D3D3]', className)}
  >
    {children}
  </p>
);

export const TileText = ({ children, className, ...restProps }: Props) => (
  <Text {...restProps} className={clsx('text-14 text-white', className)}>
    {children}
  </Text>
);

export const Price = ({ className }: Pick<Props, 'className'>) => (
  <div className={clsx('flex gap-2 items-baseline', className)}>
    <TileHeading>$29.99</TileHeading>
    <TileText className="text-10">Billed annually</TileText>
  </div>
);
