import type { EmailModalPropTypes } from '../PianoModals';
import { EmailModal } from '../PianoModals';

import type { FlowVariant } from './types';

interface Props extends Partial<EmailModalPropTypes> {
  variant: FlowVariant;
}

const EmailStep = ({ variant, ...props }: Props) => {
  switch (variant) {
    case 'locked':
      return (
        <EmailModal
          backgroundVariant="1"
          heading="Join our exclusive community"
          subHeading="Access only with Outside+ subscription"
          {...props}
        />
      );
    case 'partially-locked':
      return (
        <EmailModal
          backgroundVariant="1"
          heading="Create an account and enjoy your movie"
          subHeading="Free access with Outside account"
          {...props}
        />
      );
    case 'free':
      return (
        <EmailModal
          backgroundVariant="2"
          heading="Create an Outside account"
          subHeading="Welcome Explorer!"
          text="Get access to exclusive free content."
          {...props}
        />
      );
    default:
      return null;
  }
};

export default EmailStep;
