import { useFullscreenModeObserver } from 'helpers/hooks/useFullscreenModeObserver';

type Props = {
  toggle: VoidFunction;
};

const FullscreenControlButton = ({ toggle }: Props) => {
  const isFullscreenModeOn = useFullscreenModeObserver();

  return (
    <button
      className="jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-logo"
      onClick={toggle}
      type="button"
    >
      {isFullscreenModeOn ? (
        <svg
          className="jw-svg-icon jw-svg-icon-fullscreen-off"
          focusable="false"
          viewBox="0 0 240 240"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M109.2,134.9l-8.4,50.1c-0.4,2.7-2.4,3.3-4.4,1.4L82,172l-27.9,27.9l-14.2-14.2l27.9-27.9l-14.4-14.4c-1.9-1.9-1.3-3.9,1.4-4.4l50.1-8.4c1.8-0.5,3.6,0.6,4.1,2.4C109.4,133.7,109.4,134.3,109.2,134.9L109.2,134.9z M172.1,82.1L200,54.2L185.8,40l-27.9,27.9l-14.4-14.4c-1.9-1.9-3.9-1.3-4.4,1.4l-8.4,50.1c-0.5,1.8,0.6,3.6,2.4,4.1c0.5,0.2,1.2,0.2,1.7,0l50.1-8.4c2.7-0.4,3.3-2.4,1.4-4.4L172.1,82.1z" />
        </svg>
      ) : (
        <svg
          className="jw-svg-icon jw-svg-icon-fullscreen-on"
          focusable="false"
          viewBox="0 0 240 240"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M96.3,186.1c1.9,1.9,1.3,4-1.4,4.4l-50.6,8.4c-1.8,0.5-3.7-0.6-4.2-2.4c-0.2-0.6-0.2-1.2,0-1.7l8.4-50.6c0.4-2.7,2.4-3.4,4.4-1.4l14.5,14.5l28.2-28.2l14.3,14.3l-28.2,28.2L96.3,186.1z M195.8,39.1l-50.6,8.4c-2.7,0.4-3.4,2.4-1.4,4.4l14.5,14.5l-28.2,28.2l14.3,14.3l28.2-28.2l14.5,14.5c1.9,1.9,4,1.3,4.4-1.4l8.4-50.6c0.5-1.8-0.6-3.6-2.4-4.2C197,39,196.4,39,195.8,39.1L195.8,39.1z" />
        </svg>
      )}
      <div
        className="jw-reset-text jw-tooltip jw-tooltip-fullscreen"
        dir="auto"
      >
        <div className="jw-text">Fullscreen</div>
      </div>
    </button>
  );
};

export default FullscreenControlButton;
