import {
  usePlayerStateContext,
  usePlayerUpdaterContext
} from 'contexts/PlayerContext';
import { useCallback, useEffect, useMemo } from 'react';

export type PlayerActionMethods = {
  block: VoidFunction;
  unblock: VoidFunction;
};

export const usePlayerActions = (): PlayerActionMethods => {
  const { currentState, playerRef, targetState } = usePlayerStateContext();

  const { setCurrentState, setTargetState } = usePlayerUpdaterContext();

  const alignPlayerState = useCallback(() => {
    if (currentState !== 'paused' && targetState === 'blocked') {
      playerRef.pause();
      playerRef.pauseAd(true);
      playerRef.setControls(false);
    }

    if (playerRef.getControls() !== true && targetState === 'ready') {
      playerRef.play();

      playerRef.setControls(true);
    }
  }, [currentState, playerRef, targetState]);

  // This effect registers watch methods on playerRef
  useEffect(() => {
    if (!playerRef) return;

    const setPlaying = () => setCurrentState('playing');
    const setPause = () => setCurrentState('paused');

    playerRef.on('time', alignPlayerState);
    playerRef.on('seek', alignPlayerState);
    playerRef.on('play', setPlaying);
    playerRef.on('pause', setPause);
    playerRef.on('adPlay', setPlaying);
    playerRef.on('adPause', setPause);

    // eslint-disable-next-line consistent-return
    return () => {
      playerRef.off('time', alignPlayerState);
      playerRef.off('seek', alignPlayerState);
      playerRef.off('play', setPlaying);
      playerRef.off('pause', setPause);
      playerRef.off('adPlay', setPlaying);
      playerRef.off('adPause', setPause);
    };
  }, [playerRef, setCurrentState, alignPlayerState]);

  // This effect controls player state
  useEffect(() => {
    if (!playerRef) return;

    alignPlayerState();
  }, [currentState, playerRef, targetState, alignPlayerState]);

  const playerActions = useMemo(
    () => ({
      block: () => setTargetState('blocked'),
      unblock: () => setTargetState('ready')
    }),
    [setTargetState]
  );

  return playerActions;
};
