import { getFullscreenModeStatus } from 'helpers/utils/fullscreenPolyfill';
import { getJWPlayerInstance } from 'helpers/utils/jwplayerInstance';
import { useEffect, useState } from 'react';

/* this function disable native double-click fullscreen mode
so we can override it with our custom behavior */
export const disableNativeJWPlayerFullscreen = (playerId: string) => {
  const player = getJWPlayerInstance(playerId);
  player.setAllowFullscreen(false);
};

/* this custom hook allows to observe while fullscreen mode is changed
document.fullscreenElement will point to the element that
is in fullscreen mode if there is one. If not, the value
of the property is null. */
export const useFullscreenModeObserver = () => {
  const [isFullscreenModeOn, setFullscreenMode] = useState(
    getFullscreenModeStatus
  );

  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreenMode(getFullscreenModeStatus());
    };

    document.onfullscreenchange = handleFullscreenChange;

    return () => {
      document.onfullscreenchange = undefined;
    };
  }, []);

  return isFullscreenModeOn;
};
