import type { Strength } from 'components/01-atoms/PasswordStrengthBar';
import PasswordStrengthBar from 'components/01-atoms/PasswordStrengthBar';
import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import type { ClassValue } from 'clsx';

import { getPasswordQuality, getPasswordStrength } from './helpers';

type Props = {
  className?: ClassValue;
  value: string;
};

const PasswordQualityChecker = ({ className, value }: Props) => {
  const [strength, setStrength] = useState<Strength>(0);

  const debouncedCheck = useMemo(
    () =>
      debounce(async (val: string) => {
        try {
          const quality = await getPasswordQuality(val);
          const passwordStrength = getPasswordStrength(quality);
          setStrength(passwordStrength);
        } catch (error) {
          setStrength(0);
        }
      }, 300),
    []
  );

  useEffect(() => {
    debouncedCheck(value);

    return debouncedCheck.cancel;
  }, [debouncedCheck, value]);

  return <PasswordStrengthBar className={className} strength={strength} />;
};

export default PasswordQualityChecker;
