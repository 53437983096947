/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';
import { useCallback } from 'react';

import TrackingLink from '../TrackingLink';

import {
  getContainedButtonClasses,
  getIconShiftClasses,
  getIconWrapperSizes,
  getTextButtonClasses
} from './helpers';
import type { ButtonProps, LinkProps } from './types';

export type Props = ButtonProps | LinkProps;

const Button = ({ iconPosition = 'left', ...props }: Props) => {
  const {
    analytics,
    ariaLabel,
    as,
    className,
    color,
    fullWidth,
    icon,
    size,
    text,
    title,
    type,
    variant
  } = props;

  const { elementClicked } = useAnalyticsContext();

  const buttonStyles =
    variant === 'contained'
      ? getContainedButtonClasses({ color, fullWidth, size })
      : getTextButtonClasses({ color, size });

  const iconStyles =
    variant === 'contained' ? getIconWrapperSizes(size) : 'w-6';

  const iconShift =
    icon && variant === 'contained' && text
      ? getIconShiftClasses(iconPosition, size)
      : null;

  const renderButtonContent = () => (
    <>
      {icon && iconPosition === 'left' && (
        <span className={clsx(iconStyles, iconShift)}>{icon}</span>
      )}
      {text}
      {icon && iconPosition === 'right' && (
        <span className={clsx(iconStyles, iconShift)}>{icon}</span>
      )}
    </>
  );

  const withAnalytics = useCallback(
    (callback: VoidFunction) => () => {
      elementClicked({
        name: analytics.name,
        placement: analytics.placement,
        type: 'button'
      });
      callback();
    },
    [analytics, elementClicked]
  );

  const mergedClasses = clsx(buttonStyles, className);

  if (as === 'link') {
    const { href, target } = props;
    return (
      <TrackingLink
        analytics={analytics}
        classes={mergedClasses}
        href={href ?? '#'}
        target={target ?? undefined}
        title={title ?? text}
      >
        {/* these jsx-a11y rules are disabled because the Next.js Link component resolves them */}
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        {renderButtonContent()}
      </TrackingLink>
    );
  }

  const { disabled, onClick } = props;

  const buttonTitle = title ?? text;

  return (
    <button
      // Because we require type to be constant (readonly) it's non issue
      // eslint-disable-next-line react/button-has-type
      aria-label={ariaLabel ?? buttonTitle}
      className={mergedClasses}
      disabled={disabled}
      onClick={analytics ? withAnalytics(onClick) : onClick}
      title={buttonTitle}
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
    >
      {renderButtonContent()}
    </button>
  );
};

export default Button;
