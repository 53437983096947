import { z } from 'zod';

export const requiredStringSchema = z
  .string({ required_error: 'Field is required' })
  .trim()
  .min(1, 'Field is required');

export const emailModalSchema = z.object({
  email: requiredStringSchema.email()
});
export type EmailModalValues = z.infer<typeof emailModalSchema>;

export const registrationModalSchema = z.object({
  agreements: z.boolean(),
  email: requiredStringSchema.email(),
  password: requiredStringSchema
    .min(8, 'Your password must contain at least 8 characters')
    .max(128, 'Your password can contain maximum of 128 characters')
    .regex(/[a-z]/, 'Your password must contain at least 1 lowercase letter')
    .regex(/[A-Z]/, 'Your password must contain at least 1 uppercase letter')
    .regex(/\d/, 'Your password must contain at least 1 digit')
});
export type RegistrationModalValues = z.infer<typeof registrationModalSchema>;

export const loginModalSchema = z.object({
  email: requiredStringSchema.email(),
  password: requiredStringSchema
});
export type LoginModalValues = z.infer<typeof loginModalSchema>;
