import { saveVideoProgress } from 'apis/mediaItem/queryFunctions';
import { useUserType } from 'helpers/hooks/useUserType';
import throttle from 'lodash/throttle';

export const VIDEO_PROGRESS_DELAY = 20 * 1000;

const useSaveVideoProgress = (
  mediaItemLink,
  trailerId = null,
  delay = VIDEO_PROGRESS_DELAY
) => {
  const { isLoggedIn } = useUserType();

  const saveProgress = (position: number) => {
    if (!isLoggedIn) return;

    saveVideoProgress({
      mediaItemLink,
      position: Math.floor(position),
      trailerId
    });
  };

  const saveProgressThrottled = throttle(saveProgress, delay);

  return {
    saveProgress: saveProgressThrottled
  };
};

export default useSaveVideoProgress;
