import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PianoModal from 'components/03-organisms/PianoModal';
import Input from 'components/01-atoms/Input';
import PasswordInput from 'components/02-molecules/PasswordInput';
import Button from 'components/02-molecules/Button';
import IconLoader from 'components/01-atoms/Loader/IconLoader';
import { ACCOUNTS_OUTSIDE_ONLINE_URLS } from 'helpers/constants';

import type { ModalPropTypes } from './modalPropTypes';
import type { LoginModalValues } from './schemas';
import { loginModalSchema } from './schemas';

export interface LoginModalPropTypes extends ModalPropTypes<LoginModalValues> {
  heading: string;
  subHeading?: string;
  text?: string;
}

export const LoginModal = ({
  backgroundVariant,
  heading,
  initialValues,
  onBack,
  onSubmit,
  subHeading,
  text
}: LoginModalPropTypes) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      ...initialValues
    },
    resolver: zodResolver(loginModalSchema)
  });

  const internalOnSubmit = async (values: LoginModalValues) => {
    try {
      await onSubmit(values);
    } catch (error) {
      if (error.isAxiosError && error.response.status === 403) {
        setError('password', {
          message:
            'Incorrect email or password. Please retry or tap Forgot Password.'
        });
      } else {
        setError('password', {
          message: 'An unexpected error occurred, please try again later.'
        });
      }
    }
  };

  return (
    <PianoModal
      backgroundVariant={backgroundVariant}
      onBack={onBack}
      slogan={'Welcome back!\n Ready to explore\n Outside TV?'}
    >
      {subHeading && (
        <PianoModal.SubHeading className="mb-1">
          {subHeading}
        </PianoModal.SubHeading>
      )}
      {heading && (
        <PianoModal.Heading className="mb-4">{heading}</PianoModal.Heading>
      )}
      {text && <PianoModal.Text>{text}</PianoModal.Text>}
      <form
        className="mx-auto max-w-[400px] mt-8"
        onSubmit={handleSubmit(internalOnSubmit)}
      >
        <Input
          aria-label="Email"
          autoFocus
          className="mb-3"
          disabled={isSubmitting}
          error={errors.email?.message}
          id="email"
          label="Email Address"
          {...register('email')}
        />
        <PasswordInput
          className="mb-6"
          disabled={isSubmitting}
          error={errors.password?.message}
          placeholder="Password"
          {...register('password')}
        />
        <PianoModal.PrimaryButton
          className="mb-6"
          icon={isSubmitting ? <IconLoader /> : null}
          text="Log in"
          type="submit"
        />
        <PianoModal.AgreementsReminder className="mb-3">
          By selecting &quot;Log in&quot;, you agree to Outside’s <br />
        </PianoModal.AgreementsReminder>
        {/* TODO: This should be PianoModal.SecondaryButton but I couldn't untangle prop types to support both as Button and as Link */}
        <Button
          as="link"
          className="p-3 mx-auto w-max"
          href={ACCOUNTS_OUTSIDE_ONLINE_URLS.PASSWORD_RESET}
          text="Forgot your password?"
          variant="text"
        />
      </form>
    </PianoModal>
  );
};
