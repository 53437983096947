import PianoDialog from 'components/03-organisms/PianoDialog';

export type EncourageTo = 'register' | 'upgrade';

export type EncouragementDialogProps = {
  encourageTo: EncourageTo;
  onAction?: VoidFunction;
  onReject?: VoidFunction;
};

const content: Record<EncourageTo, { button: string; text: string }> = {
  register: {
    button: 'Create account',
    text: 'Get access to exclusive free content by joining our Outside community.'
  },
  upgrade: {
    button: 'Upgrade',
    text: 'Get exclusive access to the best content with Outside+'
  }
} as const;

const EncouragementDialog = ({
  encourageTo,
  onAction,
  onReject
}: EncouragementDialogProps) => (
  <PianoDialog className="flex gap-4 flex-wrap">
    <div className="flex-grow">
      <PianoDialog.Heading className="mb-2">
        Enjoying our free content?
      </PianoDialog.Heading>
      <PianoDialog.Text>{content[encourageTo].text}</PianoDialog.Text>
    </div>
    <div className="flex gap-4 items-center">
      <PianoDialog.PrimaryButton
        onClick={onAction}
        text={content[encourageTo].button}
      />
      <PianoDialog.SecondaryButton
        className="p-4"
        onClick={onReject}
        text="Not now"
      />
    </div>
  </PianoDialog>
);

export default EncouragementDialog;
