import JWPlayer from '@jwplayer/jwplayer-react';
import { useCallback } from 'react';
import { setupJWPlayerTimeEventHandlers } from 'helpers/utils/setupJWPlayerTimeEvents';
import merge from 'lodash/merge';
import {
  getJWPlayerInstance,
  getPlayerInstanceId,
  removeJWPlayerScript,
  setJWPlayerScriptId
} from 'helpers/utils/jwplayerInstance';
import { useUserType } from 'helpers/hooks/useUserType';

import type { VideoPlayerProps } from './types';
import { defaultOnProps } from './constants';

const VideoPlayer = ({
  advertising,
  aspectRatio = '16:9',
  cast = {},
  classes,
  controls = true,
  file,
  hideCaptions = false,
  image,
  isAutoPlay,
  isMuted = false,
  onEnterFullScreen,
  onFiftyPercent,
  onOneHundredPercent,
  onPause,
  onPlay,
  onReady,
  onRemove,
  onResume,
  onSeek,
  onSeventyFivePercent,
  onTime,
  onTwentyFivePercent,
  onVideoLoad,
  playerId,
  playerScript,
  playlist,
  repeat = false,
  stretching = 'uniform',
  width = '100%'
}: VideoPlayerProps) => {
  // watch-regwall
  const { isLoggedIn } = useUserType();

  const handleOnReady = useCallback(() => {
    if (window.jwplayer) {
      const player = getJWPlayerInstance(playerId);
      setJWPlayerScriptId(playerId);

      setupJWPlayerTimeEventHandlers(
        player,
        {
          onFiftyPercent,
          onOneHundredPercent,
          onSeventyFivePercent,
          onTwentyFivePercent
        },
        onTime
      );

      if (hideCaptions) {
        player.setCurrentCaptions(0);
      }

      // watch-regwall
      if (!isLoggedIn) {
        player.pause();
      }

      onReady?.(player);
    }
  }, [
    hideCaptions,
    isLoggedIn,
    onFiftyPercent,
    onOneHundredPercent,
    onReady,
    onSeventyFivePercent,
    onTime,
    onTwentyFivePercent,
    playerId
  ]);

  const jwPlayerProps = merge({}, defaultOnProps, {
    advertising,
    aspectratio: aspectRatio,
    autostart: isLoggedIn ? !!isAutoPlay : false,
    cast,
    controls,
    file,
    image,
    library: playerScript,
    mute: !!isMuted,
    onEnterFullScreen,
    onPause,
    onPlay,
    onReady: handleOnReady,
    onResume,
    onSeek,
    onVideoLoad,
    playerId,
    playerScript,
    playlist,
    repeat,
    stretching,
    width
  });

  const handleUnmountPlayer = useCallback(() => {
    onRemove?.();
    removeJWPlayerScript(playerId);
  }, [onRemove, playerId]);

  return (
    <div className={classes} id="video-player">
      <JWPlayer
        {...jwPlayerProps}
        key={playlist || file}
        id={getPlayerInstanceId(playerId)}
        willUnmountCallback={handleUnmountPlayer}
      />
    </div>
  );
};

export default VideoPlayer;
