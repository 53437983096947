import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';
import type {
  TrackingAdEvent,
  TrackingContentType,
  TrackingPlaybackEvent
} from 'providers/AnalyticsProvider/types';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { getArrayOfTags } from 'helpers/utils/media/getArrayOfTags';
import type { Events, PlaybackEvent } from 'helpers/utils/registerEvents';
import { registerEvents } from 'helpers/utils/registerEvents';
import { useUserType } from 'helpers/hooks/useUserType';
import { noop } from 'helpers/utils/noop';

type Input = {
  contenttype: TrackingContentType;
  mediaItem: MediaItem;
  player: Nullable<JWPlayer>;
  playerId: string;
};

export const useRegisterPlayerAnalyticsEvents = ({
  contenttype,
  mediaItem,
  player,
  playerId
}: Input) => {
  const { isLoggedIn } = useUserType();
  const isPlayAfterStart = useRef(true);

  const {
    category,
    duration,
    episodeMeta,
    mediaId,
    meterFlow,
    seriesPlaylistId,
    seriesSlug,
    tags,
    title
  } = mediaItem;

  const {
    videoAdClicked,
    videoAdCompleted,
    videoAdImpression,
    videoCompleted,
    videoMute,
    videoPaused,
    videoPlayed,
    videoStarted
  } = useAnalyticsContext();

  const handlePlayback = useCallback(
    (trackingEvent: TrackingPlaybackEvent) => (event?: PlaybackEvent) => {
      const position = player.getPosition();

      return trackingEvent({
        assetID: playerId,
        category,
        contenttype,
        duration,
        jwTags: getArrayOfTags(tags),
        mediaId,
        meterflow: meterFlow,
        mute: (event as jwplayer.MuteParam)?.mute ?? undefined,
        pauseReason:
          // there is no type in jwplayer with pauseReason prop
          (event as unknown as { pauseReason: string })?.pauseReason ??
          undefined,
        playReason:
          // there is no type in jwplayer with playReason prop
          (event as unknown as { playReason: string })?.playReason ?? undefined,
        playerId,
        playlistid: seriesPlaylistId,
        position,
        seriesslug: seriesSlug,
        signedIn: isLoggedIn,
        videoTitle: title,
        viewable: (event as jwplayer.TimeParam)?.viewable ?? undefined,
        ...(episodeMeta
          ? { episode: episodeMeta.episode, season: episodeMeta.season }
          : {})
      });
    },
    [
      category,
      contenttype,
      duration,
      episodeMeta,
      isLoggedIn,
      mediaId,
      meterFlow,
      player,
      playerId,
      seriesPlaylistId,
      seriesSlug,
      tags,
      title
    ]
  );

  const handleAd = useCallback(
    (trackingEvent: TrackingAdEvent) =>
      ({
        adposition,
        adtitle,
        clickThroughUrl,
        duration: adDuration,
        viewable
      }) =>
        trackingEvent({
          adposition,
          adtitle,
          category,
          clickThroughUrl,
          duration: adDuration,
          jwTags: getArrayOfTags(tags),
          mediaId,
          meterflow: meterFlow,
          playerId,
          signedIn: isLoggedIn,
          videoTitle: title,
          viewable
        }),
    [category, isLoggedIn, mediaId, meterFlow, playerId, tags, title]
  );

  const handleAdComplete = handleAd(videoAdCompleted);
  const handleAdImpression = handleAd(videoAdImpression);
  const handleAdClick = handleAd(videoAdClicked);
  const handlePause = handlePlayback(videoPaused);
  const handlePlay = useCallback(
    (event: PlaybackEvent) => {
      if (!isPlayAfterStart.current) {
        const handler = handlePlayback(videoPlayed);
        handler(event);
      }
      isPlayAfterStart.current = false;
    },
    [handlePlayback, videoPlayed]
  );
  const handleFirstFrame = handlePlayback(videoStarted);
  const handleMute = handlePlayback(videoMute);
  const handleCompleted = handlePlayback(videoCompleted);

  const events = useMemo(
    (): Partial<Events> => ({
      adClick: handleAdClick,
      adComplete: handleAdComplete,
      adImpression: handleAdImpression,
      complete: handleCompleted,
      firstFrame: handleFirstFrame,
      mute: handleMute,
      pause: handlePause,
      play: handlePlay
    }),
    [
      handleAdClick,
      handleAdComplete,
      handleAdImpression,
      handlePause,
      handlePlay,
      handleFirstFrame,
      handleCompleted,
      handleMute
    ]
  );

  useEffect(() => {
    if (!player) return noop;

    registerEvents('on', player, events);

    return () => {
      registerEvents('off', player, events);
    };
  }, [events, player]);

  return {
    handleAd,
    handlePlayback
  };
};
