import type {
  ContextState,
  ContextUpdater,
  PlayerLocked,
  PlayerState
} from 'contexts/PlayerContext';
import {
  PlayerStateContext,
  PlayerUpdaterContext
} from 'contexts/PlayerContext';
import { useState } from 'react';

type Props = { children: React.ReactNode };

const PlayerStateContextProvider = PlayerStateContext.Provider;
const PlayerUpdaterContextProvider = PlayerUpdaterContext.Provider;

export const PlayerContextProvider = ({ children }: Props) => {
  const [playerRef, setPlayerRef] = useState<JWPlayer>(null);
  // This state is updated by the player events
  const [currentState, setCurrentState] = useState<PlayerState>('idle');
  // This state is updated by the higher order actions,
  // and player should always be in sync with this state
  const [targetState, setTargetState] = useState<PlayerLocked>('ready');
  const [isPlayerReady, setPlayerReady] = useState<boolean>(false);

  const state: ContextState = {
    currentState,
    isPlayerReady,
    playerRef,
    targetState
  };

  const updater: ContextUpdater = {
    setCurrentState,
    setPlayerReady,
    setPlayerRef,
    setTargetState
  };

  return (
    <PlayerStateContextProvider value={state}>
      <PlayerUpdaterContextProvider value={updater}>
        {children}
      </PlayerUpdaterContextProvider>
    </PlayerStateContextProvider>
  );
};
