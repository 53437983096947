import { BackButton } from './BackButton';

interface ErrorStateProps {
  onClose: VoidFunction;
}

export const ErrorState = ({ onClose }: ErrorStateProps) => (
  <>
    <BackButton onClick={onClose} />
    <p className="text-network-white text-center font-plus-jakarta-sans text-16">
      The video did not load. Please close this modal, reload the page, and try
      signing in again.
    </p>
  </>
);
