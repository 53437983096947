import { noop } from 'helpers/utils/noop';

export const defaultOnProps = {
  onEnterFullScreen: noop,
  onPause: noop,
  onPlay: noop,
  onReady: noop,
  onResume: noop,
  onSeek: noop,
  onVideoLoad: noop
};
