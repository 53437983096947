import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import type { ReactNode } from 'react';

import { Heading, Text } from './Typography';
import { PrimaryButton, SecondaryButton } from './Buttons';

type Props = {
  children: ReactNode;
  className?: ClassValue;
};

const PianoDialog = ({ children, className }: Props) => (
  <div className={clsx('text-white bg-new-neutral-900 p-4 lg:p-6', className)}>
    {children}
  </div>
);

PianoDialog.Heading = Heading;
PianoDialog.Text = Text;
PianoDialog.PrimaryButton = PrimaryButton;
PianoDialog.SecondaryButton = SecondaryButton;

export default PianoDialog;
