// players without ads
export const DRM_VIDEO_PLAYER_ID = 'j1wGDuvV';
export const TRAILER_PLAYER_ID = 'XicBCKlw';
// players with ads
export const BROADCAST_PLAYER_ID = '5QMSbTsD';
export const LIVE_TV_PLAYER_ID = 'Q4vwX7L2';
export const SERIES_PLAYER_ID = 'fDDfekEh';
export const SHORTS_VIDEO_PLAYER_ID = 'mleyXjrt';
export const VIDEO_PLAYER_ID = 'ELWMGAyH';

export const PLAYERS_WITH_ADS = {
  BROADCAST_PLAYER_ID,
  LIVE_TV_PLAYER_ID,
  SERIES_PLAYER_ID,
  SHORTS_VIDEO_PLAYER_ID,
  VIDEO_PLAYER_ID
} as const;

export const AD_CONFIG = {
  DEFAULT: '685xyaAr'
} as const;

export const AD_CONFIG_BY_PLAYER: Record<
  ValueOf<typeof PLAYERS_WITH_ADS>,
  ValueOf<typeof AD_CONFIG>
> = {
  [BROADCAST_PLAYER_ID]: AD_CONFIG.DEFAULT,
  [LIVE_TV_PLAYER_ID]: AD_CONFIG.DEFAULT,
  [SERIES_PLAYER_ID]: AD_CONFIG.DEFAULT,
  [SHORTS_VIDEO_PLAYER_ID]: AD_CONFIG.DEFAULT,
  [VIDEO_PLAYER_ID]: AD_CONFIG.DEFAULT
};

export const JW_PLAYER_SITE_IDS = {
  OUTSIDE_VIDEO: 'f902XiZc'
};
