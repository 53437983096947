import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import {
  PIANO_DIALOG_DOM_ID,
  PIANO_DIALOG_DOM_ID_INTERNAL
} from 'helpers/constants/piano';

type Props = {
  className?: ClassValue;
};

const COMMON_DIALOG_STYLES =
  'z-[100] w-full lg:w-[376px] overflow-hidden bg-new-neutral-900 lg:rounded border-primary lg:border-l-4 lg:m-8';

const PianoDialogMountingPoints = ({ className }: Props) => (
  <>
    <div
      className={clsx(className, COMMON_DIALOG_STYLES, 'max-h-[99px]')}
      id={PIANO_DIALOG_DOM_ID}
    />
    <div
      className={clsx(className, COMMON_DIALOG_STYLES)}
      id={PIANO_DIALOG_DOM_ID_INTERNAL}
    />
  </>
);

export default PianoDialogMountingPoints;
