import { gql } from 'graphql-request';

export const SAVE_VIDEO_WATCH_TIME = gql`
  mutation SaveVideoWatchTime($data: VideoWatchTimeInput!) {
    saveVideoWatchTime(data: $data) {
      free
      locked
      meter
    }
  }
`;

export const GET_VIDEO_WATCH_TIMES = gql`
  query getVideoWatchTimes {
    videoWatchTimes {
      free
      locked
      meter
    }
  }
`;
