/* eslint-disable no-console */
import type { SaveVideoWatchTimeParams } from 'apis/watchTimes/types';
import { getVideoWatchTimes } from 'apis/watchTimes/queryFunctions';

export const calculateTimeLeft = (timeLimit: number, watchedTime: number) => {
  if (watchedTime === null || timeLimit === null) return null;

  const timeLeft = Math.round(timeLimit - watchedTime);
  return Math.max(0, timeLeft);
};

export const isReportDataValid = (data: SaveVideoWatchTimeParams) =>
  !Number.isNaN(data.watchTimeEnd) && !Number.isNaN(data.watchTimeStart);

const getInitialStartTime = (player: JWPlayer) =>
  new Promise<number>((resolve) => {
    player.once('time', () => resolve(player.getPosition()));
  });

export const getInitialValues = async (player: JWPlayer) =>
  Promise.all([
    getVideoWatchTimes().then(({ videoWatchTimes }) => videoWatchTimes.meter),
    getInitialStartTime(player)
  ]);
