import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PianoModal from 'components/03-organisms/PianoModal';
import Input from 'components/01-atoms/Input';
import PasswordInput from 'components/02-molecules/PasswordInput';
import PasswordQualityChecker from 'components/02-molecules/PasswordQualityChecker';
import IconLoader from 'components/01-atoms/Loader/IconLoader';

import type { ModalPropTypes } from './modalPropTypes';
import type { RegistrationModalValues } from './schemas';
import { registrationModalSchema } from './schemas';

export const RegistrationModal = ({
  backgroundVariant,
  initialValues,
  onBack,
  onSubmit
}: ModalPropTypes<RegistrationModalValues>) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError,
    watch
  } = useForm({
    defaultValues: {
      agreements: false,
      email: '',
      password: '',
      ...initialValues
    },
    resolver: zodResolver(registrationModalSchema)
  });

  const internalOnSubmit = async (values: RegistrationModalValues) => {
    try {
      await onSubmit(values);
    } catch (error) {
      if (error.isAxiosError) {
        const { details } = error.response.data;
        if (details.password1) {
          setError('password', { message: details.password1[0].message });
        }
        if (details.email) {
          setError('email', {
            message:
              'This email is unavailable, please sign in or chose another.'
          });
        }
      } else {
        setError('password', {
          message: 'An unexpected error occurred, please try again later.'
        });
      }
    }
  };

  return (
    <PianoModal
      backgroundVariant={backgroundVariant}
      onBack={onBack}
      slogan={
        'The beginning of your\n journey starts with\n an Outside account'
      }
    >
      <PianoModal.Heading className="mb-8">
        Pick your password
      </PianoModal.Heading>
      <form
        className="mx-auto max-w-[400px]"
        onSubmit={handleSubmit(internalOnSubmit)}
      >
        <Input
          aria-label="Email"
          autoFocus
          className="mb-3"
          disabled={isSubmitting}
          error={errors.email?.message}
          id="email"
          label="Email Address"
          {...register('email')}
        />
        <PasswordInput
          additionalComponent={
            <PasswordQualityChecker value={watch('password')} />
          }
          className="mb-6"
          error={errors.password?.message}
          placeholder="Password"
          {...register('password')}
        />
        <PianoModal.PrimaryButton
          className="mb-6"
          icon={isSubmitting ? <IconLoader /> : null}
          text="Create Account"
          type="submit"
        />
        <PianoModal.RegistrationAgreements
          hasError={!!errors.agreements}
          {...register('agreements')}
        />
      </form>
    </PianoModal>
  );
};
