import type { NextRouter } from 'next/router';

type Input = {
  paramsToRemove: string[];
  router: NextRouter;
  shallow?: boolean;
};

export const removeQueryParams = ({
  paramsToRemove,
  router,
  shallow = false
}: Input) => {
  const { asPath } = router;

  const [pathname, params] = asPath.split('?');

  const searchParams = new URLSearchParams(params);

  paramsToRemove.forEach((paramToRemove) => {
    searchParams.delete(paramToRemove);
  });

  const searchParamsMap = Array.from(searchParams.entries()).reduce(
    (acc, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {}
  );

  router.replace(
    {
      pathname,
      query: searchParamsMap
    },
    undefined,
    {
      shallow
    }
  );
};
