import type { MutableRefObject } from 'react';
import { useEffect, useState } from 'react';

const useHasNodes = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const [hasNodes, setHasNodes] = useState<boolean>(false);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      setHasNodes(mutationsList[0].addedNodes.length > 0);
    });

    if (ref.current) {
      observer.observe(ref.current, { childList: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return { hasNodes };
};

export default useHasNodes;
