import axios from 'axios';
import type { Strength } from 'components/01-atoms/PasswordStrengthBar';

type PasswordQualityPercentage = Nullable<number>;

export const getPasswordQuality = async (
  passwordToCheck: string
): Promise<PasswordQualityPercentage> => {
  if (passwordToCheck.length < 2) return null;

  const postResponse = await axios.post(
    process.env.NEXT_PUBLIC_PASSWORD_QUALITY_URL,
    { password: passwordToCheck }
  );

  const { rank } = postResponse.data;

  return rank;
};

export const getPasswordStrength = (
  percentage: PasswordQualityPercentage
): Strength => {
  if (percentage === null) return 0;
  if (percentage >= 0.9) return 3;
  if (percentage >= 0.6) return 2;
  return 1;
};
