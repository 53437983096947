import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PianoModal from 'components/03-organisms/PianoModal';
import Input from 'components/01-atoms/Input';
import IconLoader from 'components/01-atoms/Loader/IconLoader';

import type { ModalPropTypes } from './modalPropTypes';
import type { EmailModalValues, LoginModalValues } from './schemas';
import { emailModalSchema } from './schemas';

export interface EmailModalPropTypes extends ModalPropTypes<EmailModalValues> {
  heading: string;
  onSecondary?: VoidFunction;
  subHeading?: string;
  text?: string;
}

export const EmailModal = ({
  backgroundVariant,
  heading,
  initialValues,
  onBack,
  onClose,
  onSecondary,
  onSubmit,
  subHeading,
  text
}: EmailModalPropTypes) => {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    setError
  } = useForm({
    defaultValues: { email: '', ...initialValues },
    resolver: zodResolver(emailModalSchema)
  });

  const internalOnSubmit = async (values: LoginModalValues) => {
    try {
      await onSubmit(values);
    } catch (error) {
      setError('email', {
        message: 'An unexpected error occurred, please try again later.'
      });
    }
  };

  return (
    <PianoModal
      backgroundVariant={backgroundVariant}
      onBack={onBack}
      onClose={onClose}
      slogan={
        'The beginning of your\n journey starts with\n an Outside account'
      }
    >
      {subHeading && (
        <PianoModal.SubHeading className="mb-2">
          {subHeading}
        </PianoModal.SubHeading>
      )}
      {heading && (
        <PianoModal.Heading className="mb-4">{heading}</PianoModal.Heading>
      )}
      {text && <PianoModal.Text>{text}</PianoModal.Text>}
      <form
        className="mx-auto max-w-[400px]"
        onSubmit={handleSubmit(internalOnSubmit)}
      >
        <Input
          aria-label="Email"
          autoFocus
          className="mt-8 mb-8"
          disabled={isSubmitting}
          error={errors.email?.message}
          id="email"
          label="Email Address"
          {...register('email')}
        />
        <PianoModal.PrimaryButton
          className="mb-6"
          icon={isSubmitting ? <IconLoader /> : null}
          text="Continue"
          type="submit"
        />
        <PianoModal.Text className="!text-14 text-white">
          Already have an account?
          <PianoModal.SecondaryButton
            className="!text-14 !p-1 inline-flex text-btn-secondary-hover"
            onClick={onSecondary}
            text="Sign in!"
          />
        </PianoModal.Text>
      </form>
    </PianoModal>
  );
};
