import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { DRM_VIDEO_PLAYER_ID } from 'helpers/constants/jwplayer';

import {
  getDrmMediaItem,
  getDrmPlayerUrl,
  getMediaItem,
  saveSeriesProgress
} from './queryFunctions';
import type { UseMediaItemParams, UseDrmVideoParams } from './types';

export const MEDIA_ITEM_QUERY_KEY = 'mediaItem';
export const DRM_MEDIA_ITEM_QUERY_KEY = 'drmMediaItem';
export const SAVE_SERIES_PROGRESS_QUERY_KEY = 'saveSeriesProgress';
export const PLAYER_URL_QUERY_KEY = 'playerUrl';

export const useSaveSeriesProgressMutation = () =>
  useMutation({
    mutationFn: saveSeriesProgress,
    mutationKey: [SAVE_SERIES_PROGRESS_QUERY_KEY]
  });

export const useMediaItem = ({ id, ...options }: UseMediaItemParams) =>
  useQuery({
    queryFn: () => getMediaItem({ id }),
    queryKey: [MEDIA_ITEM_QUERY_KEY, id],
    ...options
  });

// TODO
export const useDrmVideo = ({ enabled, id }: UseDrmVideoParams) =>
  useQueries({
    queries: [
      {
        enabled,
        queryFn: () => getDrmMediaItem({ id }),
        queryKey: [DRM_MEDIA_ITEM_QUERY_KEY, id],
        staleTime: 0
      },
      {
        enabled,
        queryFn: () =>
          getDrmPlayerUrl({
            playerId: DRM_VIDEO_PLAYER_ID
          }),
        queryKey: [PLAYER_URL_QUERY_KEY, DRM_VIDEO_PLAYER_ID]
      }
    ]
  });
