import clsx from 'clsx';
import { BackButton } from 'components/03-organisms/EnhancedVideoPlayer/BackButton';

type Ratio = '16:9' | 'fullscreen';

type Props = {
  onBack?: VoidFunction;
  ratio: Ratio;
};

const FallbackErrorPlayer = ({ onBack, ratio }: Props) => (
  <div
    className={clsx('bg-foreground-default', {
      'fixed w-screen h-screen left-0 top-0 z-50': ratio === 'fullscreen',
      'relative sm:relative w-full aspect-w-16 aspect-h-9': ratio === '16:9'!
    })}
  >
    {onBack && <BackButton onClick={onBack} />}
    <div>
      <div className="absolute bg-black right-0 top-20 narrow-x:top-6 narrow-x:right-5 text-white px-8 py-4 text-14 font-400 font-plus-jakarta-sans rounded-[4px] border-l-4 border-primary animate-enter">
        We are currently down for maintenance. Thanks for your patience!
      </div>
    </div>
  </div>
);

export default FallbackErrorPlayer;
