// we could pass size & colors props here, but this Loader is work in progress according to designers
const IconLoader = () => (
  <svg
    className="animate-spin h-6 w-6 text-white"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
    <path
      className="opacity-75"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      fill="black"
      strokeWidth="2"
    />
  </svg>
);

export default IconLoader;
