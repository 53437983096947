type AdEvent = {
  adposition: string;
  adtitle: string;
  duration: number;
  viewable: number;
};

// we want the event to be one of JWPlayer's events
export type PlaybackEvent = jwplayer.EventParams[keyof jwplayer.EventParams];

export type Events = Record<
  jwplayer.NoParamEvent | keyof jwplayer.EventParams,
  AdEventCallback | PlaybackEventCallback
>;

type AdEventCallback = (event: AdEvent) => void;
type PlaybackEventCallback = (event: PlaybackEvent) => void;

type Method = 'off' | 'on';

export const registerEvents = (
  method: Method,
  player: JWPlayer,
  events: Partial<Events>
) =>
  (Object.entries(events) as Entries<typeof events>).forEach(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ([eventName, callback]) => player[method](eventName, callback)
  );
