import type { MutationSaveVideoWatchTimeArgs } from 'helpers/types/jwplayer-subgraph';

import { client } from '../../lib/graphqlRequest';

import type {
  SaveVideoWatchTimeParams,
  SaveVideoWatchTimeResponse,
  VideoWatchTimesResponse
} from './types';
import { GET_VIDEO_WATCH_TIMES, SAVE_VIDEO_WATCH_TIME } from './graphql';

export const saveVideoWatchTime = (data: SaveVideoWatchTimeParams) =>
  client.request<SaveVideoWatchTimeResponse, MutationSaveVideoWatchTimeArgs>(
    SAVE_VIDEO_WATCH_TIME,
    { data }
  );

export const getVideoWatchTimes = () =>
  client.request<VideoWatchTimesResponse>(GET_VIDEO_WATCH_TIMES);
