import type { VideoPlayerProps } from 'components/01-atoms/VideoPlayer/types';
import {
  AD_CONFIG_BY_PLAYER,
  JW_PLAYER_SITE_IDS
} from 'helpers/constants/jwplayer';

type Input = {
  isAdsFree: boolean;
  mediaId: string;
  playerId: string;
};

export type Advertising = VideoPlayerProps['advertising'];

const isDev = process.env.NODE_ENV === 'development';

const freeAdvertisingConfig = null;

export const getAdsConfig = ({
  isAdsFree,
  mediaId,
  playerId
}: Input): Advertising => {
  /* do not play ads while developing, in case you want to test ads functionality, you can temporarily comment this line  */
  if (isDev) return freeAdvertisingConfig;

  if (isAdsFree) return freeAdvertisingConfig;

  const adConfigId = AD_CONFIG_BY_PLAYER[playerId];
  return {
    client: 'vast',
    schedule: `https://cdn.jwplayer.com/v2/sites/${JW_PLAYER_SITE_IDS.OUTSIDE_VIDEO}/ad_configs/${adConfigId}/schedule.xml?media_id=${mediaId}`,
    vpaidcontrols: true
  };
};
