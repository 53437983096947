import type { VideoPlayerProps } from 'components/01-atoms/VideoPlayer/types';

type Handlers = Pick<
  VideoPlayerProps,
  | 'onFiftyPercent'
  | 'onOneHundredPercent'
  | 'onSeventyFivePercent'
  | 'onTwentyFivePercent'
>;

type HandlersKey = keyof Handlers;

export const setupJWPlayerTimeEventHandlers = (
  player: JWPlayer,
  handlers: Handlers,
  originalOnTime: VideoPlayerProps['onTime']
) => {
  const hasFired = Object.keys(handlers).reduce(
    (acc, key) => {
      acc[key] = false;
      return acc;
    },
    {} as Record<HandlersKey, boolean>
  );

  player.on('time', (event) => {
    const { duration, position } = event;
    originalOnTime?.(event);

    if (!hasFired.onTwentyFivePercent && (position / duration) * 100 > 25) {
      handlers.onTwentyFivePercent?.();
      hasFired.onTwentyFivePercent = true;
    }

    if (!hasFired.onFiftyPercent && (position / duration) * 100 > 50) {
      handlers.onFiftyPercent?.();
      hasFired.onFiftyPercent = true;
    }

    if (!hasFired.onSeventyFivePercent && (position / duration) * 100 > 75) {
      handlers.onSeventyFivePercent?.();
      hasFired.onSeventyFivePercent = true;
    }
  });

  player.on('beforeComplete', () => handlers.onOneHundredPercent?.());
};
