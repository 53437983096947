export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Date: any;
  DateTime: any;
  DateTimeISO: any;
  Decimal: any;
  JSON: any;
  JSONObject: any;
};

export type Ar_ExternalAthleticEvent = IAthleticEventDisplay & {
  __typename?: 'AR_ExternalAthleticEvent';
  appType: ApplicationType;
  city?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  /** Country the event is happening in */
  eventCountry?: Maybe<Scalars['String']>;
  eventEndDate: Scalars['DateTime'];
  eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  eventUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ARegSanctioningBody = {
  __typename?: 'ARegSanctioningBody';
  /** The site this sanctioning body sanctions events on */
  appType: ApplicationType;
  /** Whether this sanctioning body should show up as a filter on the calendar */
  filterableOnCalendar: Scalars['Boolean'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

/** Relates a sanctioning body to an event that is sanctioned by them */
export type ARegSanctioningBodyEventInfo = {
  __typename?: 'ARegSanctioningBodyEventInfo';
  appType: ApplicationType;
  eventID: Scalars['Int'];
  sanctioningBodyID: Scalars['Int'];
  /**
   * Get the name of the sanctioning body for the event
   *
   *
   * **Returns:**
   * String name of the associated sanctioning body
   */
  sanctioningBodyName?: Maybe<Scalars['String']>;
};

export type ActivitytypeInfo = {
  __typename?: 'ActivitytypeInfo';
  action?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  fontIcon?: Maybe<Scalars['String']>;
  garmin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Boolean']>;
  primary?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  titlePlural?: Maybe<Scalars['String']>;
  trail?: Maybe<Scalars['Boolean']>;
  verbPast?: Maybe<Scalars['String']>;
  winter?: Maybe<Scalars['Boolean']>;
};

export type AddBookmarkInput = {
  contentType: BookmarkContentType;
  contentUid: Scalars['String'];
  listName: Scalars['String'];
};

export type AddReactionInput = {
  contentType: BookmarkContentType;
  contentUid: Scalars['String'];
  /** Toggle reaction count. 0 to remove reaction, 1 to add reaction. */
  count: Scalars['Int'];
  reactionName: ReactionName;
};

/** Application type (usually the website name) */
export enum ApplicationType {
  Athletereg = 'ATHLETEREG',
  Bikereg = 'BIKEREG',
  /** For payment processing only */
  Outside = 'OUTSIDE',
  Pledgereg = 'PLEDGEREG',
  Raceday = 'RACEDAY',
  Runreg = 'RUNREG',
  Skireg = 'SKIREG',
  /** For the test results database */
  Testresults = 'TESTRESULTS',
  Trireg = 'TRIREG',
  Undefined = 'UNDEFINED'
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type Asset = {
  __typename?: 'Asset';
  blockchain?: Maybe<Blockchain>;
  contract?: Maybe<Contract>;
  description: Scalars['String'];
  externalUrl: Scalars['String'];
  id: Scalars['Float'];
  image: Scalars['String'];
  lastSaleDate?: Maybe<Scalars['DateTime']>;
  metadata: Scalars['JSON'];
  name: Scalars['String'];
  publicKey?: Maybe<Scalars['String']>;
  seriesNumber: Scalars['Float'];
  set: Set;
  token?: Maybe<Scalars['JSON']>;
};

export type AssetFilters = {
  name?: InputMaybe<Scalars['String']>;
};

export type AssetPage = {
  __typename?: 'AssetPage';
  items: Array<Asset>;
  totalCount: Scalars['Int'];
};

export type AssetSort = {
  field: Asset_List_Sort_Keys;
  order: Sort_Direction;
};

/** GraphQL representation of EventHeaderInfo */
export type AthleticEvent = IAthleticEventDisplay & {
  __typename?: 'AthleticEvent';
  /** Which AthleteReg site the data is on */
  appType: ApplicationType;
  /**
   * Resolver for loading the event's categories
   *
   *
   * **Returns:**
   * Set of EventCategory associated with this event
   */
  categories?: Maybe<Array<Maybe<EventCategory>>>;
  /** City the event is happening in */
  city?: Maybe<Scalars['String']>;
  /** When registration closes for the event */
  closeRegDate: Scalars['DateTime'];
  contentItem?: Maybe<ContentItem>;
  /**
   * Get the URL of the event's header photo
   *
   *
   * **Returns:**
   * The URL of the event's photo banner
   */
  coverPhoto?: Maybe<Scalars['String']>;
  /** Date of the earliest category in the event */
  date: Scalars['DateTime'];
  /**
   * Resolver for loading all the event's category dates
   *
   *
   * **Returns:**
   * List of Dates associated with the event
   */
  eventDates?: Maybe<Array<Scalars['Date']>>;
  /** Date of the latest category in the event */
  eventEndDate: Scalars['DateTime'];
  /** ID in the given application of the athletic event */
  eventId: Scalars['ID'];
  /**
   * Get the event type names from the DB, or cached list. These are strings that can differ based on the application type
   *
   *
   * **Returns:**
   * IEnumerable of event types for this event
   */
  eventTypes: Array<Maybe<Scalars['String']>>;
  /** Full URL chosen by the event director. Note this is not guaranteed to lead to this event as directors reuse these, and it can change */
  eventUrl: Scalars['String'];
  /** True if the event director has paid to visually highlight this event */
  isHighlighted: Scalars['Boolean'];
  /** True if users are currently able to register for the event */
  isOpen: Scalars['Boolean'];
  /** Latitude for event precise location */
  latitude?: Maybe<Scalars['Float']>;
  /** The URL of the event's logo */
  logo?: Maybe<Scalars['String']>;
  /** Longitude for event precise location */
  longitude?: Maybe<Scalars['Float']>;
  /** Plaintext of the event name */
  name?: Maybe<Scalars['String']>;
  /** First opportunity to register for the event */
  openRegDate: Scalars['DateTime'];
  /**
   * Get the sanctioning bodies for this event
   *
   *
   * **Returns:**
   * List of the sanctioning bodies that are associated with this event
   */
  sanctioningBodies?: Maybe<Array<Maybe<ARegSanctioningBodyEventInfo>>>;
  /** State the event is happening in */
  state?: Maybe<Scalars['String']>;
  /** Full URL that will always refer to this event */
  staticUrl: Scalars['String'];
  /** String given by the event director to use in the URL. Not a full URL. */
  vanityUrl?: Maybe<Scalars['String']>;
  /** Zip code for the event (not necessarily US) */
  zip?: Maybe<Scalars['String']>;
};

/** GraphQL representation of EventHeaderInfo */
export type AthleticEventCoverPhotoArgs = {
  imageSize?: ImageSize;
};

/** GraphQL representation of EventHeaderInfo */
export type AthleticEventLogoArgs = {
  imageSize?: ImageSize;
};

/** A connection to a list of items. */
export type AthleticEventCalendarConnection = {
  __typename?: 'AthleticEventCalendarConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AthleticEventCalendarEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<AthleticEventSearchContainer>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AthleticEventCalendarEdge = {
  __typename?: 'AthleticEventCalendarEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AthleticEventSearchContainer>;
};

export type AthleticEventSearchContainer = {
  __typename?: 'AthleticEventSearchContainer';
  /** The reg site the event lives on */
  appType: ApplicationType;
  /**
   * Retrieves the associated Athletic Event object from the DB
   *
   *
   * **Returns:**
   * The athletic event associated with the searchEvent. Multiple
   */
  athleticEvent?: Maybe<IAthleticEventDisplay>;
  /** City the event is in */
  city?: Maybe<Scalars['String']>;
  /** Date that registration closes for the event */
  closeRegDate: Scalars['DateTime'];
  /** String of event distances to display in the UI */
  distanceString?: Maybe<Scalars['String']>;
  /** Latest date of the event. If the current entry is a sub-entry, this is the last date of the group of category dates */
  endDate: Scalars['DateTime'];
  /** Event id of the event on the specific reg site - not unique betw */
  eventId: Scalars['Int'];
  /** ID for the search information */
  id: Scalars['ID'];
  /** If the event is a membership event */
  isMembership: Scalars['Int'];
  /** Latitude of the event location */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude of the event location */
  longitude?: Maybe<Scalars['Float']>;
  /** Maximum distance of a category on the event in meters. Null if no categories have a linear distance */
  maxDistanceMeters?: Maybe<Scalars['Int']>;
  /** Maximum distance of a category on the event in minutes. Null if no categories have a time distance */
  maxDistanceMinutes?: Maybe<Scalars['Int']>;
  /** Minimum distance of a category on the event in meters. Null if no categories have a linear distance */
  minDistanceMeters?: Maybe<Scalars['Int']>;
  /** Minimum distance of a category on the event in minutes. Null if no categories have a time distance */
  minDistanceMinutes?: Maybe<Scalars['Int']>;
  /** Display name of the event */
  name?: Maybe<Scalars['String']>;
  /** Date that registration opens for the event */
  openRegDate: Scalars['DateTime'];
  /** Permit number of a sanctioned event */
  permitNumber?: Maybe<Scalars['String']>;
  /** Promoter ID who owns the event. The same ID can refer to different promoters if the event is on different reg sites */
  promoterId: Scalars['Int'];
  /** Level of promotion the event director has paid for */
  promotionLevel: Scalars['Int'];
  /** Sanctioning body ID of the event. Null if not sanctioned */
  sanctioningBodyId?: Maybe<Scalars['Int']>;
  /** What type of event entry this is */
  searchEntryType: SearchEntryType;
  /** Earliest date of the event. If the current entry is a sub-entry, this is the first date of the group of category dates */
  startDate: Scalars['DateTime'];
  /** Geographic state the event is in */
  state?: Maybe<Scalars['String']>;
};

export type AttachedImage = {
  __typename?: 'AttachedImage';
  altText: Scalars['String'];
  file?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  sizes: ImageSizes;
  width?: Maybe<Scalars['Int']>;
};

export type Benefit = {
  __typename?: 'Benefit';
  description: Scalars['String'];
  id: Scalars['BigInt'];
  image: GraphImage;
  metadata: BenefitMetadata;
  title: Scalars['String'];
};

export type BenefitMetadata = {
  __typename?: 'BenefitMetadata';
  pixelArt: BenefitPixelArt;
};

export enum BenefitPixelArt {
  Coins = 'COINS',
  Gear = 'GEAR',
  None = 'NONE',
  Stars = 'STARS',
  Tent = 'TENT'
}

export type BenefitsSection = {
  __typename?: 'BenefitsSection';
  content: Scalars['String'];
  footer: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
};

export type Bookmark = {
  __typename?: 'Bookmark';
  bookmarkListId: Scalars['Int'];
  contentType: BookmarkContentType;
  contentUid: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['Int'];
  reference: BookmarkReference;
  updatedAt: Scalars['DateTimeISO'];
};

export enum BookmarkContentType {
  ContentItem = 'ContentItem',
  MediaItem = 'MediaItem'
}

export type BookmarkList = {
  __typename?: 'BookmarkList';
  /** Returns a page of bookmarked elements from the list */
  bookmarks: BookmarkPage;
  createdAt: Scalars['DateTimeISO'];
  /** If the content is present on the list, returns a Bookmark object, otherwise returns `null` */
  find?: Maybe<Bookmark>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type BookmarkListBookmarksArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

export type BookmarkListFindArgs = {
  data: FindOnListInput;
};

export type BookmarkPage = {
  __typename?: 'BookmarkPage';
  items: Array<Bookmark>;
  totalCount: Scalars['Int'];
};

export type BookmarkReference = ContentItem | MediaItem;

export type BrandPartnersSection = {
  __typename?: 'BrandPartnersSection';
  brands: Array<OutsideioBrand>;
  header: Scalars['String'];
  subheader: Scalars['String'];
};

export type BucketSponsor = {
  __typename?: 'BucketSponsor';
  image: Scalars['String'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Button = {
  __typename?: 'Button';
  link: Scalars['String'];
  text: Scalars['String'];
};

/** The scope of a cache hint. */
export enum CacheControlScope {
  /** The value to cache is specific to a single user. */
  Private = 'PRIVATE',
  /** The value to cache is not tied to a single user. */
  Public = 'PUBLIC'
}

export type CallToAction = {
  __typename?: 'CallToAction';
  buttonText: Scalars['String'];
  description: Scalars['String'];
  disclaimer?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  headline: Scalars['String'];
};

/** Information about category type that determines how a given type is to be handled */
export enum CategoryType {
  Fis = 'FIS',
  Standard = 'STANDARD'
}

export type ChatResult = {
  __typename?: 'ChatResult';
  message?: Maybe<Scalars['String']>;
  sourceUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CollaborateSection = {
  __typename?: 'CollaborateSection';
  brandButtonText: Scalars['String'];
  creatorButtonText: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
};

export type Collection = {
  __typename?: 'Collection';
  additionalDetailsHtml?: Maybe<Scalars['String']>;
  additionalDetailsImageUrl?: Maybe<Scalars['String']>;
  assets: AssetPage;
  assetsCount: Scalars['Float'];
  assetsSoldCount: Scalars['Float'];
  callToActionText?: Maybe<Scalars['String']>;
  collectionAddress?: Maybe<Scalars['String']>;
  communityUrl?: Maybe<Scalars['String']>;
  contracts: Array<Contract>;
  creditCardPurchaseUrl?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  descriptionImageUrl?: Maybe<Scalars['String']>;
  dropType: CollectionDropType;
  endDate?: Maybe<Scalars['DateTime']>;
  externalUrl: Scalars['String'];
  headerImageUrl: Scalars['String'];
  headerText?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  offers: Array<Offer>;
  payoutAddress?: Maybe<Scalars['String']>;
  postMintHtml: Scalars['String'];
  project: Project;
  secondaryMarket?: Maybe<SecondaryMarket>;
  secondaryMarketUrl?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  sets: Array<Set>;
  slug: Scalars['String'];
  startDate: Scalars['DateTime'];
  status: CollectionStatus;
  symbol: Scalars['String'];
  termsOfServiceHtml?: Maybe<Scalars['String']>;
  titleImageUrl?: Maybe<Scalars['String']>;
  traits: Scalars['JSON'];
  unlockHtml?: Maybe<Scalars['String']>;
};

export type CollectionAssetsArgs = {
  filter?: InputMaybe<AssetFilters>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<AssetSort>>;
};

export type CollectionAddress = {
  __typename?: 'CollectionAddress';
  collectionAddress?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
};

export enum CollectionDropType {
  CandyMachine = 'CANDY_MACHINE',
  ExternalMarket = 'EXTERNAL_MARKET'
}

export type CollectionFilters = {
  address?: InputMaybe<Scalars['String']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CollectionStatusQuery>;
};

export enum CollectionStatus {
  Closed = 'CLOSED',
  EarlyAccess = 'EARLY_ACCESS',
  Hidden = 'HIDDEN',
  Ongoing = 'ONGOING',
  Paused = 'PAUSED',
  Soldout = 'SOLDOUT',
  Upcoming = 'UPCOMING'
}

export enum CollectionStatusQuery {
  Closed = 'CLOSED',
  Ongoing = 'ONGOING',
  Upcoming = 'UPCOMING'
}

export type CollectionsPage = {
  __typename?: 'CollectionsPage';
  items: Array<Collection>;
  totalCount: Scalars['Int'];
};

export type CollectionsSort = {
  field: Collection_List_Sort_Keys;
  order: Sort_Direction;
};

export type Content = {
  __typename?: 'Content';
  description: Scalars['String'];
  title: Scalars['String'];
};

export type ContentArticle = {
  __typename?: 'ContentArticle';
  /** Affiliate Link Disclaimer comes from wordpress $post->optional_affiliate-link_text */
  affiliateLinkDisclaimerText?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<ContentAuthor>>;
  /** This is a collection associated with the Article */
  collection?: Maybe<ContentCollection>;
  content: Scalars['String'];
  contentItem?: Maybe<ContentItem>;
  /** a bunch of data from content modules that dictate the display. Currently use: (editor, video, image, pullquote, data_table, channel, iframe) */
  contentModules?: Maybe<Scalars['JSON']>;
  /** comes from Wordpress get_field("corrections", $post) */
  corrections?: Maybe<Scalars['JSON']>;
  createdDate: Scalars['DateTime'];
  denormalizedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** comes from Wordpress get_post_meta($post_id, "article_type", true) */
  displayType?: Maybe<Scalars['String']>;
  /** This is a field returned by distributed content: dt_full_connection */
  distFullConnection?: Maybe<Scalars['Boolean']>;
  /** This is a field returned by distributed content: dt_original_post_id */
  distOriginalPostId?: Maybe<Scalars['String']>;
  /** This is a field returned by distributed content: dt_original_post_url */
  distOriginalPostUrl?: Maybe<Scalars['String']>;
  /** This is a field returned by distributed content: dt_original_site_name */
  distOriginalSiteName?: Maybe<Scalars['String']>;
  /** This is a field returned by distributed content: dt_syndicate_time */
  distSyndicateTime?: Maybe<Scalars['DateTime']>;
  /** This is a field returned by distributed content: dt_unlinked */
  distUnlinked?: Maybe<Scalars['Boolean']>;
  /** comes from Wordpress get_field("layout", $post) and used for display layouts.  We return layout_text, layout_image_with_blockquote, layout_image, layout_image_side_by_side,  layout_image_slideshow, layout_image_grid, layout_video, layout_looped_video, layout_iframe_embed */
  flexibleLayouts?: Maybe<Scalars['JSON']>;
  /** This is a gallery associated with the Article */
  galleryArticle?: Maybe<GalleryArticle>;
  /** This is a gear associated with the Article */
  gear?: Maybe<Gear>;
  heroVideo?: Maybe<MediaItem>;
  hot?: Maybe<Scalars['Float']>;
  id: Scalars['Float'];
  imageUrl?: Maybe<Scalars['String']>;
  /** comes from Wordpress get_post_meta($post_id, "sponsored", true ) */
  isSponsored?: Maybe<Scalars['Boolean']>;
  jwPlayerId?: Maybe<Scalars['String']>;
  memberOnly: Scalars['Boolean'];
  modificationDate: Scalars['DateTime'];
  photoCredits?: Maybe<Scalars['String']>;
  /** This is a podcast associated with the Article */
  podcast?: Maybe<Podcast>;
  postStatus?: Maybe<Scalars['String']>;
  publicationDate: Scalars['DateTime'];
  /** This is a recipe associated with the Article */
  recipe?: Maybe<Recipe>;
  /** comes from Wordpress get_field("review_rating, basics etc", $post) */
  review?: Maybe<Scalars['JSON']>;
  source: Scalars['String'];
  sourceId: Scalars['String'];
  sourceUuid: Scalars['String'];
  /** comes from Wordpress get_post_meta($post_id, "sponsor_name", true ) */
  sponsorName?: Maybe<Scalars['String']>;
  sqImageUrl?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: ContentArticleType;
  updatedDate: Scalars['DateTime'];
  url: Scalars['String'];
  /** This is a video associated with the Article */
  video?: Maybe<Video>;
  videoUrl?: Maybe<Scalars['String']>;
};

export enum ContentArticleType {
  Article = 'article',
  Collection = 'collection',
  GalleryArticle = 'gallery_article',
  Gear = 'gear',
  Podcast = 'podcast',
  Recipe = 'recipe',
  Video = 'video'
}

export type ContentArticleUpdatesReport = {
  __typename?: 'ContentArticleUpdatesReport';
  contentArticles: Array<ContentArticle>;
  cursor?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type ContentAuthor = {
  __typename?: 'ContentAuthor';
  createdDate: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  sourceId: Scalars['String'];
  updatedDate: Scalars['DateTime'];
  url: Scalars['String'];
};

export type ContentCollection = {
  __typename?: 'ContentCollection';
  buttonLink?: Maybe<Scalars['String']>;
  collectionLogoUrl?: Maybe<Scalars['String']>;
  collectionSubhead?: Maybe<Scalars['String']>;
  collectionType?: Maybe<Scalars['String']>;
  collections?: Maybe<Array<ContentCollectionBundle>>;
  date?: Maybe<Scalars['DateTime']>;
  editorLetter?: Maybe<Scalars['String']>;
  featuredStory?: Maybe<ContentArticle>;
  featuredStoryTitle?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  longDescription?: Maybe<Scalars['String']>;
  promotedContent?: Maybe<Array<ContentArticle>>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type ContentCollectionBundle = {
  __typename?: 'ContentCollectionBundle';
  articles?: Maybe<Array<ContentArticle>>;
  staticImage?: Maybe<ContentCollectionStaticImage>;
  staticImageLink?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videoSeeAllLink?: Maybe<Scalars['String']>;
  videos?: Maybe<Array<ContentCollectionVideo>>;
};

export type ContentCollectionStaticImage = {
  __typename?: 'ContentCollectionStaticImage';
  altText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  creditType?: Maybe<Scalars['String']>;
  mobileAltText?: Maybe<Scalars['String']>;
  mobileCaption?: Maybe<Scalars['String']>;
  mobileCreditType?: Maybe<Scalars['String']>;
  mobileImageUrl?: Maybe<Scalars['String']>;
  mobilePhotoCredit?: Maybe<Scalars['String']>;
  photoCredit?: Maybe<Scalars['String']>;
  staticImageUrl?: Maybe<Scalars['String']>;
};

export type ContentCollectionVideo = {
  __typename?: 'ContentCollectionVideo';
  video?: Maybe<MediaItem>;
  videoId?: Maybe<Scalars['String']>;
  videoPlayer?: Maybe<Scalars['String']>;
  videoText?: Maybe<Scalars['String']>;
  videoTitle?: Maybe<Scalars['String']>;
};

export type ContentItem = {
  __typename?: 'ContentItem';
  /** Cross reference to a AthleticEvent, served mostly by the Athlete Reg graph. */
  athleticEvent?: Maybe<AthleticEvent>;
  /** @deprecated no longer supported */
  authorId?: Maybe<Scalars['Int']>;
  /** @deprecated no longer supported */
  authorName?: Maybe<Scalars['String']>;
  /** @deprecated no longer supported */
  authorUrl?: Maybe<Scalars['String']>;
  bodyContent?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  categoryName?: Maybe<Scalars['String']>;
  categoryUrl?: Maybe<Scalars['String']>;
  /** Cross reference to a ContentArticle, served mostly by the editorial graph. */
  contentArticle?: Maybe<ContentArticle>;
  denormalizedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated no longer supported */
  dynamicTopics?: Maybe<Array<Maybe<Scalars['String']>>>;
  formattedContent?: Maybe<Scalars['String']>;
  /** Cross reference to a Gaia Track, served mostly by the Gaia graph. */
  gaiaTrack?: Maybe<GaiaTrack>;
  hot?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  itemCreatedAt?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['Int']>;
  itemImageUrl?: Maybe<Scalars['String']>;
  itemSource?: Maybe<Scalars['String']>;
  itemSubtitle?: Maybe<Scalars['String']>;
  itemTitle?: Maybe<Scalars['String']>;
  itemType?: Maybe<Scalars['String']>;
  itemUuid?: Maybe<Scalars['String']>;
  /** Cross reference to a MediaItem, served mostly by the jwplayer graph. */
  mediaItem?: Maybe<MediaItem>;
  memberOnly?: Maybe<Scalars['Int']>;
  meta?: Maybe<Scalars['JSON']>;
  pageview?: Maybe<Scalars['Int']>;
  popularity?: Maybe<Scalars['Float']>;
  /** Get user's reactions to outside content */
  reactions: ReactionCountMap;
  sourceDisplay?: Maybe<Scalars['String']>;
  /** @deprecated no longer supported */
  staticTopics?: Maybe<Array<Maybe<Scalars['String']>>>;
  subcategory?: Maybe<Scalars['String']>;
  tfActivityTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  tfCondition?: Maybe<Scalars['String']>;
  trailforksPhoto?: Maybe<TrailforksPhoto>;
  trailforksReport?: Maybe<TrailforksReport>;
  trailforksRidelog?: Maybe<TrailforksRidelog>;
  trailforksRoute?: Maybe<TrailforksRoute>;
  /** Cross reference to a PB Subgraph TF Data, served mostly by the Trailforks graph. */
  trailforksTrail?: Maybe<TrailforksTrail>;
  /** Cross reference to a Ugc Post, served mostly by the Ugc graph. */
  ugcPost?: Maybe<UgcPost>;
  url?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type ContentItemFilter = {
  /**
   * Filter out content that has not item UUID
   * `true` returns only null UUIDs
   * `false` returns only not-null UUIDs
   */
  itemUuid__isnull?: InputMaybe<Scalars['Boolean']>;
  /**
   * Filter member only content
   * `true` returns only locked content
   * `false` returns only unlocked content
   */
  memberOnly?: InputMaybe<Scalars['Boolean']>;
  /** Filter content by item source */
  sources?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter content by tags */
  topics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter content by item type */
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentItemInput = {
  authorId?: InputMaybe<Scalars['Int']>;
  authorName?: InputMaybe<Scalars['String']>;
  authorUrl?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryUrl?: InputMaybe<Scalars['String']>;
  hot?: InputMaybe<Scalars['Float']>;
  itemCreatedAt?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['Int']>;
  itemImageUrl?: InputMaybe<Scalars['String']>;
  itemSource?: InputMaybe<Scalars['String']>;
  itemSubtitle?: InputMaybe<Scalars['String']>;
  itemTitle?: InputMaybe<Scalars['String']>;
  itemType?: InputMaybe<Scalars['String']>;
  itemUrl?: InputMaybe<Scalars['String']>;
  memberOnly?: InputMaybe<Scalars['Int']>;
  pageview?: InputMaybe<Scalars['Int']>;
  popularity?: InputMaybe<Scalars['Float']>;
};

export type ContentItemMutation = IAddBookmarkMutation & {
  __typename?: 'ContentItemMutation';
  /** Creates a bookmark on user's list. */
  addBookmark: Bookmark;
  /** Registers a user's reaction to outside content */
  addReaction: ReactionEdge;
  importBookmark: Bookmark;
  /** Get user's reactions to outside content */
  reaction: ReactionEdge;
  reference: ContentItem;
};

export type ContentItemMutationAddBookmarkArgs = {
  data: AddBookmarkInput;
};

export type ContentItemMutationAddReactionArgs = {
  data: AddReactionInput;
};

export type ContentItemMutationImportBookmarkArgs = {
  data: ImportBookmarkInput;
};

export type ContentItemMutationReactionArgs = {
  data: GetReactionInput;
};

export type ContentItemSearchResult = {
  __typename?: 'ContentItemSearchResult';
  authorName?: Maybe<Scalars['String']>;
  bodyContent?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  denormalizedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  formattedContent?: Maybe<Scalars['String']>;
  hot?: Maybe<Scalars['Float']>;
  itemCreatedAt?: Maybe<Scalars['String']>;
  itemImageUrl?: Maybe<Scalars['String']>;
  itemSource?: Maybe<Scalars['String']>;
  itemSubtitle?: Maybe<Scalars['String']>;
  itemTitle?: Maybe<Scalars['String']>;
  itemType?: Maybe<Scalars['String']>;
  itemUuid?: Maybe<Scalars['String']>;
  memberOnly?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Float']>;
  sourceDisplay?: Maybe<Scalars['String']>;
  sponsored?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
};

export type ContentItemSearchResultsPage = {
  __typename?: 'ContentItemSearchResultsPage';
  items?: Maybe<Array<Maybe<ContentItemSearchResult>>>;
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  took?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ContentItemsPage = {
  __typename?: 'ContentItemsPage';
  items?: Maybe<Array<Maybe<ContentItem>>>;
  page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** Returns a union of a jw-player MediaItem and an editorial ContentArticle. */
export type ContentReference =
  | AthleticEvent
  | ContentArticle
  | MediaItem
  | SocialProfile;

export type Contract = {
  __typename?: 'Contract';
  address: Scalars['String'];
  assets: AssetPage;
  blockchain?: Maybe<Blockchain>;
  collection?: Maybe<Collection>;
  contract: Scalars['JSON'];
  defaultMintAmount: Scalars['Int'];
  id: Scalars['Float'];
  maxMintAmount: Scalars['Int'];
  name: Scalars['String'];
  symbol: Scalars['String'];
};

export type ContractAssetsArgs = {
  filter?: InputMaybe<AssetFilters>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<AssetSort>>;
};

export type Course = {
  __typename?: 'Course';
  brand?: Maybe<CourseBrand>;
  callToAction: CallToAction;
  coverage: Coverage;
  curriculum?: Maybe<Curriculum>;
  duration?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  extraContent?: Maybe<Array<Content>>;
  hero: Hero;
  heroImage?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  instructors: Array<Instructor>;
  instructorsLabel?: Maybe<Scalars['String']>;
  lessons: Array<Lesson>;
  mediaBlocks: Array<MediaBlock>;
  name: Scalars['String'];
  overview: Overview;
  relatedCourses: Array<Course>;
  seo?: Maybe<LearnSeo>;
  tableOfContents: Array<TableOfContentItem>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CourseCurriculumArgs = {
  isPreview?: InputMaybe<Scalars['Boolean']>;
};

export type CourseHeroImageArgs = {
  thumbnailId?: InputMaybe<Scalars['BigInt']>;
};

export type CourseLessonsArgs = {
  isPreview?: InputMaybe<Scalars['Boolean']>;
};

export type CourseRelatedCoursesArgs = {
  relatedCoursesCount?: InputMaybe<Scalars['Int']>;
};

export type CourseSeoArgs = {
  isPreview?: InputMaybe<Scalars['Boolean']>;
};

export type CourseTableOfContentsArgs = {
  isPreview?: InputMaybe<Scalars['Boolean']>;
};

export type CourseBrand = {
  __typename?: 'CourseBrand';
  image: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CourseCarousel = {
  __typename?: 'CourseCarousel';
  count?: Maybe<Scalars['Int']>;
  courses?: Maybe<Array<Course>>;
  title: Scalars['String'];
};

export type CourseImage = {
  __typename?: 'CourseImage';
  file?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type Coverage = {
  __typename?: 'Coverage';
  /** @deprecated Use imageAttachment instead. */
  image?: Maybe<Scalars['String']>;
  imageAttachment?: Maybe<AttachedImage>;
  text: Array<Scalars['String']>;
  title: Scalars['String'];
  video?: Maybe<Scalars['String']>;
};

export type CreateBookmarkListInput = {
  listName: Scalars['String'];
};

export type CreateOrUpdateContentArticleInput = {
  affiliateLinkDisclaimerText?: InputMaybe<Scalars['String']>;
  authors: Array<CreateOrUpdateContentAuthorInput>;
  collection?: InputMaybe<CreateOrUpdateContentCollectionInput>;
  content: Scalars['String'];
  contentModules?: InputMaybe<Scalars['JSON']>;
  corrections?: InputMaybe<Scalars['JSON']>;
  displayType?: InputMaybe<Scalars['String']>;
  distFullConnection?: InputMaybe<Scalars['Boolean']>;
  distOriginalPostId?: InputMaybe<Scalars['String']>;
  distOriginalPostUrl?: InputMaybe<Scalars['String']>;
  distOriginalSiteName?: InputMaybe<Scalars['String']>;
  distSyndicateTime?: InputMaybe<Scalars['DateTime']>;
  distUnlinked?: InputMaybe<Scalars['Boolean']>;
  flexibleLayouts?: InputMaybe<Scalars['JSON']>;
  galleryArticle?: InputMaybe<CreateOrUpdateGalleryArticleInput>;
  gear?: InputMaybe<CreateOrUpdateGearInput>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isSponsored?: InputMaybe<Scalars['Boolean']>;
  jwPlayerId?: InputMaybe<Scalars['String']>;
  memberOnly: Scalars['Boolean'];
  modificationDate: Scalars['DateTime'];
  photoCredits?: InputMaybe<Scalars['String']>;
  podcast?: InputMaybe<CreateOrUpdatePodcastInput>;
  postStatus?: InputMaybe<Scalars['String']>;
  publicationDate: Scalars['DateTime'];
  recipe?: InputMaybe<CreateOrUpdateRecipeInput>;
  review?: InputMaybe<Scalars['JSON']>;
  source: Scalars['String'];
  sourceId: Scalars['String'];
  sourceUuid: Scalars['String'];
  sponsorName?: InputMaybe<Scalars['String']>;
  sqImageUrl?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: ContentArticleType;
  url: Scalars['String'];
  video?: InputMaybe<CreateOrUpdateVideoInput>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateContentArticlePayload = {
  __typename?: 'CreateOrUpdateContentArticlePayload';
  article?: Maybe<ContentArticle>;
};

export type CreateOrUpdateContentAuthorInput = {
  name: Scalars['String'];
  sourceId: Scalars['String'];
  url: Scalars['String'];
};

export type CreateOrUpdateContentCollectionBundleInput = {
  articleUrls?: InputMaybe<Scalars['JSON']>;
  staticImage?: InputMaybe<CreateOrUpdateContentCollectionStaticImageInput>;
  staticImageLink?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  videoSeeAllLink?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Array<CreateOrUpdateContentCollectionVideoInput>>;
};

export type CreateOrUpdateContentCollectionInput = {
  buttonLink?: InputMaybe<Scalars['String']>;
  collectionLogoUrl?: InputMaybe<Scalars['String']>;
  collectionSubhead?: InputMaybe<Scalars['String']>;
  collectionType?: InputMaybe<Scalars['String']>;
  collections?: InputMaybe<Array<CreateOrUpdateContentCollectionBundleInput>>;
  date?: InputMaybe<Scalars['DateTime']>;
  editorLetter?: InputMaybe<Scalars['String']>;
  featuredStoryTitle?: InputMaybe<Scalars['String']>;
  featuredStoryUrl?: InputMaybe<Scalars['String']>;
  longDescription?: InputMaybe<Scalars['String']>;
  promotedContentUrls?: InputMaybe<Scalars['JSON']>;
  shortDescription?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateContentCollectionStaticImageInput = {
  altText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  creditType?: InputMaybe<Scalars['String']>;
  mobileAltText?: InputMaybe<Scalars['String']>;
  mobileCaption?: InputMaybe<Scalars['String']>;
  mobileCreditType?: InputMaybe<Scalars['String']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  mobilePhotoCredit?: InputMaybe<Scalars['String']>;
  photoCredit?: InputMaybe<Scalars['String']>;
  staticImageUrl?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateContentCollectionVideoInput = {
  videoId?: InputMaybe<Scalars['String']>;
  videoPlayer?: InputMaybe<Scalars['String']>;
  videoText?: InputMaybe<Scalars['String']>;
  videoTitle?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateGalleryArticleInput = {
  displayTitle: Scalars['String'];
  images?: InputMaybe<Array<CreateOrUpdateGalleryImageInput>>;
  photographers: Array<CreateOrUpdateContentAuthorInput>;
  photosCredit?: InputMaybe<Scalars['String']>;
  subhead?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateGalleryImageInput = {
  altText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  creditType?: InputMaybe<Scalars['String']>;
  imageText?: InputMaybe<Scalars['String']>;
  mobileAltText?: InputMaybe<Scalars['String']>;
  mobileCaption?: InputMaybe<Scalars['String']>;
  mobileCreditType?: InputMaybe<Scalars['String']>;
  mobileImageUrl?: InputMaybe<Scalars['String']>;
  mobilePhotoCredit?: InputMaybe<Scalars['String']>;
  photoCredit?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type CreateOrUpdateGearInput = {
  brand?: InputMaybe<Scalars['String']>;
  buyUrls?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  releaseDate?: InputMaybe<Scalars['DateTime']>;
  review?: InputMaybe<Scalars['JSON']>;
  sku?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdatePodcastInput = {
  disableAds?: InputMaybe<Scalars['Boolean']>;
  displayTitle: Scalars['String'];
  excludePost?: InputMaybe<Scalars['Boolean']>;
  podcastFileUrl: Scalars['String'];
  showNotes?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Scalars['String']>;
  transcript?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateRecipeInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']>;
  ingredients?: InputMaybe<Scalars['JSON']>;
  instructions: Scalars['String'];
};

export type CreateOrUpdateVideoInput = {
  disablePrerollAds: Scalars['Boolean'];
  mediaId: Scalars['String'];
  videoPlatform: Scalars['String'];
};

export type Creator = {
  __typename?: 'Creator';
  avatarUrl: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  projects: Array<CreatorProjectEdge>;
};

export type CreatorPage = {
  __typename?: 'CreatorPage';
  items: Array<Creator>;
  totalCount: Scalars['Int'];
};

export type CreatorProjectEdge = {
  __typename?: 'CreatorProjectEdge';
  project: Project;
  role: CreatorProjectRole;
};

export enum CreatorProjectRole {
  Contributor = 'CONTRIBUTOR',
  Owner = 'OWNER'
}

export type CreatorsSection = {
  __typename?: 'CreatorsSection';
  content: Scalars['String'];
  creators: Array<OutsideioCreator>;
  header: Scalars['String'];
  subheader: Scalars['String'];
};

export type Curriculum = {
  __typename?: 'Curriculum';
  lessons?: Maybe<Array<Lesson>>;
  title?: Maybe<Scalars['String']>;
};

export type DrmMetadata = {
  __typename?: 'DrmMetadata';
  fairplay?: Maybe<FairplayMetadata>;
  playready?: Maybe<DrmUrl>;
  widevine?: Maybe<DrmUrl>;
};

export type DrmUrl = {
  __typename?: 'DrmUrl';
  url: Scalars['String'];
};

export type EcoCampaign = {
  __typename?: 'EcoCampaign';
  description: Scalars['String'];
  id: Scalars['BigInt'];
  image: GraphImage;
  title: Scalars['String'];
};

export type EcoCampaignSection = {
  __typename?: 'EcoCampaignSection';
  content: Scalars['String'];
  header: Scalars['String'];
  headerImage: GraphImage;
  sideImage: GraphImage;
  subheader: Scalars['String'];
};

export type EducationPost = {
  __typename?: 'EducationPost';
  educationPostMetadata?: Maybe<Array<EducationPostMeta>>;
  id?: Maybe<Scalars['BigInt']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type EducationPostEducationPostMetadataArgs = {
  keys: Array<Scalars['String']>;
};

export type EducationPostMeta = {
  __typename?: 'EducationPostMeta';
  id: Scalars['BigInt'];
  key?: Maybe<Scalars['String']>;
  postId: Scalars['BigInt'];
  value?: Maybe<Scalars['String']>;
};

export type EpgSchedule = {
  __typename?: 'EpgSchedule';
  /** Returns the show that is currently live */
  currentShow?: Maybe<EpgShow>;
  /** Returns the next show */
  nextShow?: Maybe<EpgShow>;
  /** Return the schedule for the next 7 days */
  weeklySchedule: Array<EpgShow>;
};

export type EpgShow = {
  __typename?: 'EpgShow';
  endTime: Scalars['DateTimeISO'];
  startTime: Scalars['DateTimeISO'];
  title: Scalars['String'];
};

export type Episode = {
  __typename?: 'Episode';
  associatedSeasonNumber: Scalars['Float'];
  associatedSeriesId?: Maybe<Scalars['String']>;
  episodeNumber: Scalars['Float'];
  mediaItem: MediaItem;
  nextEpisodeMedia?: Maybe<MediaItem>;
};

export type EpisodeMeta = {
  __typename?: 'EpisodeMeta';
  episode: Scalars['Float'];
  season: Scalars['Float'];
};

export type EpisodeSlide = {
  __typename?: 'EpisodeSlide';
  episodeItem?: Maybe<MediaItem>;
  id: Scalars['BigInt'];
  image: Scalars['String'];
  imageAltTag: Scalars['String'];
  isContentFree: Scalars['Boolean'];
  mediaId: Scalars['String'];
  optionalTitle?: Maybe<Scalars['String']>;
  postId: Scalars['BigInt'];
  series: Series;
  seriesId: Scalars['String'];
  type: HeroSlideType;
};

export type EventCategory = {
  __typename?: 'EventCategory';
  /** Which AthleteReg site the data is on */
  appType: ApplicationType;
  /** Distance for this event. Can be quite varied. Do not use without the unit. */
  distance?: Maybe<Scalars['String']>;
  /**
   * The distance unit for the category (i.e. miles or minutes)
   *
   *
   * **Returns:**
   * distance units the category is using
   */
  distanceUnit?: Maybe<Scalars['String']>;
  /** ID of the athletic event this category is part of */
  eventId: Scalars['Int'];
  /** Name of the event category, often a race but can be almost anything other than merchandise */
  name?: Maybe<Scalars['String']>;
  /**
   * Resolver for loading the category's dates.
   * If you don't need category specific data, use the EventDates property on the event instead
   *
   *
   * **Returns:**
   * List of Dates associated with the category
   */
  raceDates?: Maybe<Array<Scalars['Date']>>;
  /** Category ID */
  raceRecId?: Maybe<Scalars['ID']>;
  /** This category's start time */
  startTime?: Maybe<Scalars['DateTime']>;
};

export type EventDistanceFilterInput = {
  /** Maximum distance from the user, in miles */
  distance: Scalars['Float'];
  /** User latitude to search from */
  latitude: Scalars['Decimal'];
  /** User longitude to search from */
  longitude: Scalars['Decimal'];
};

/**
 * Units that can be stored in the SearchEventCategoryDistances table
 * If units can be converted between each other, they shouldn't be added here and instead converted to an existing unit
 */
export enum EventSearchDistanceUnit {
  Meters = 'METERS',
  Minutes = 'MINUTES'
}

export enum EventSortOptions {
  Distance = 'DISTANCE',
  StartDate = 'START_DATE'
}

/** Broadcast state for every event. May change at any time */
export enum EventState {
  Live = 'LIVE',
  Upcoming = 'UPCOMING',
  Vod = 'VOD'
}

export type EventType = {
  __typename?: 'EventType';
  /** Whether this event type should show up as a filter on the calendar */
  filterableOnCalendar: Scalars['Boolean'];
  typeDesc?: Maybe<Scalars['String']>;
  typeID: Scalars['Int'];
  typePriority?: Maybe<Scalars['Int']>;
};

export type EventsBucket = {
  __typename?: 'EventsBucket';
  description: Scalars['String'];
  eventType: Scalars['ID'];
  firstEventDate: Scalars['DateTimeISO'];
  heroImage: Scalars['String'];
  id: Scalars['BigInt'];
  lastEventDate: Scalars['DateTimeISO'];
  live?: Maybe<EventsBucketLive>;
  numberOfEvents: Scalars['Float'];
  playlist?: Maybe<Playlist>;
  previewImage: Scalars['String'];
  slug: Scalars['String'];
  sponsors: Array<BucketSponsor>;
  title: Scalars['String'];
};

export type EventsBucketLive = {
  __typename?: 'EventsBucketLive';
  date?: Maybe<Scalars['DateTimeISO']>;
  hls: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  meterFlow: MeterFlow;
  title: Scalars['String'];
};

export type EventsBucketSummary = {
  __typename?: 'EventsBucketSummary';
  slug: Scalars['ID'];
  title: Scalars['String'];
};

export type FairplayMetadata = {
  __typename?: 'FairplayMetadata';
  certificateUrl?: Maybe<Scalars['String']>;
  processSpcUrl?: Maybe<Scalars['String']>;
};

export type Faq = {
  __typename?: 'Faq';
  answer: Scalars['String'];
  id: Scalars['BigInt'];
  question: Scalars['String'];
};

/** Data around a paid featured event listing */
export type FeaturedAthleticEvent = {
  __typename?: 'FeaturedAthleticEvent';
  /** The event this listing is for */
  event?: Maybe<AthleticEvent>;
  /** End text for a featured listing - usually the event date or similar */
  listingDateText?: Maybe<Scalars['String']>;
  /** Mid text for a featured listing - usually the event location or similar */
  listingLocationText?: Maybe<Scalars['String']>;
  /** Starting text for a featured listing - usually the event name or similar */
  listingName?: Maybe<Scalars['String']>;
  /** URL for the featured listing */
  listingUrl?: Maybe<Scalars['String']>;
};

export type FeaturedPlaylist = {
  __typename?: 'FeaturedPlaylist';
  featuredId: Scalars['ID'];
};

/** FeedServiceActivity is Stream Activity instance. */
export type FeedServiceActivity = {
  __typename?: 'FeedServiceActivity';
  /** The Stream ID, as a UUID 1. */
  id: Scalars['ID'];
};

export type FeedServiceActivityReactionsResult = {
  __typename?: 'FeedServiceActivityReactionsResult';
  /** A cursor representing the last reaction ID of the current set of reactions. Use it to retrieve the next set of reactions. */
  lastReactionId?: Maybe<Scalars['String']>;
  /** A list of reactions for the feed activity. */
  reactions?: Maybe<Array<Maybe<FeedServiceReaction>>>;
};

export type FeedServiceActorReference = SocialProfile;

export type FeedServiceAggregateActivitiesResult = {
  __typename?: 'FeedServiceAggregateActivitiesResult';
  /** A result Entity for our feed query. */
  activities?: Maybe<Array<Maybe<FeedServiceAggregateActivity>>>;
  /** A cursor representing the last activity ID of the current set of activities. Use it to retrieve the next set of activities. */
  lastActivityId?: Maybe<Scalars['String']>;
};

/** Feed Service Activity is Stream Activity instance. */
export type FeedServiceAggregateActivity = {
  __typename?: 'FeedServiceAggregateActivity';
  /**
   * A composite key of <Entity>:<ID>, or JSON for shared activity.
   * @deprecated Use actors instead.
   */
  actor?: Maybe<Scalars['JSON']>;
  /** For aggregated feeds, the count of actors that performed the activity. */
  actorCount?: Maybe<Scalars['Int']>;
  /**
   * A graph reference to the actor of the Activity.
   * @deprecated Use actorReferences instead.
   */
  actorReference?: Maybe<FeedServiceActorReference>;
  /** A graph reference to the actor of the Activity. */
  actorReferences?: Maybe<Array<Maybe<FeedServiceActorReference>>>;
  /** For aggregated feeds, the list of actors that performed the activity. */
  actors?: Maybe<Array<Maybe<Scalars['String']>>>;
  commentCount?: Maybe<Scalars['Int']>;
  /** Any data on the Activity that is not defined above will be copied into this JSON field. */
  extra?: Maybe<Scalars['JSON']>;
  /** The client supplied ID of the Activity.  Can be used to reference the Activity in the Feed API. */
  foreignId: Scalars['ID'];
  /** The Stream ID, as a UUID 1. */
  id: Scalars['ID'];
  /** A composite key of <Entity>:<ID>, or JSON for shared activity. */
  object?: Maybe<Scalars['JSON']>;
  /** A graph reference to the object of the Activity. */
  objectReference?: Maybe<FeedServiceObjectReference>;
  /** The feed Id this Activity originated from. */
  origin?: Maybe<Scalars['String']>;
  /** The FeedId and graph reference to the social profile this Activity orginated from. */
  originReference?: Maybe<FeedServiceActorReference>;
  /** A list of primary content creators for the activity. */
  primaryContentCreators?: Maybe<Array<Maybe<SocialProfile>>>;
  /** Privacy Level of activity */
  privacyLevel?: Maybe<Scalars['String']>;
  /** The list of reactions for the feed activity. */
  reactions?: Maybe<FeedServiceReactions>;
  /** An ISO-8601 timestamp in UTC.  Example: '2020-01-01T00:00:00Z' */
  time?: Maybe<Scalars['String']>;
  /** The verb of the Activity. */
  verb?: Maybe<Scalars['String']>;
};

export type FeedServiceAggregateActivityResult = {
  __typename?: 'FeedServiceAggregateActivityResult';
  /** A single aggregate activity. */
  activity?: Maybe<FeedServiceAggregateActivity>;
};

/** Block/Unblock return type */
export type FeedServiceBlockUnblockResult = {
  __typename?: 'FeedServiceBlockUnblockResult';
  /** The user that is going to be unblocked/blocked */
  blocked_user?: Maybe<Scalars['String']>;
  /**
   * deprecating this down the line, carried over from the old response
   * @deprecated we are pulling out the fields from the data field to the top level
   */
  data?: Maybe<Scalars['JSON']>;
  /** The status after the unblock/block operation */
  followStatus?: Maybe<Scalars['String']>;
};

export type FeedServiceBlockedUserResults = {
  __typename?: 'FeedServiceBlockedUserResults';
  /** A list of feeds that the requestor has blocked. */
  blockedUsers?: Maybe<Array<Maybe<FeedServiceFollower>>>;
};

export type FeedServiceFollowRequestsResult = {
  __typename?: 'FeedServiceFollowRequestsResult';
  /** A list of feeds that follow the input identity. */
  followRequests?: Maybe<Array<Maybe<FeedServiceFollower>>>;
};

export type FeedServiceFollowStat = {
  __typename?: 'FeedServiceFollowStat';
  /** The number of followers / following for the feed target. */
  count?: Maybe<Scalars['Int']>;
  /**
   * The feed target.
   * @deprecated The identity will be the one requested.
   */
  feed?: Maybe<Scalars['String']>;
};

export type FeedServiceFollowStatResult = {
  __typename?: 'FeedServiceFollowStatResult';
  followers?: Maybe<FeedServiceFollowStat>;
  following?: Maybe<FeedServiceFollowStat>;
};

export type FeedServiceFollower = {
  __typename?: 'FeedServiceFollower';
  createdAt?: Maybe<Scalars['String']>;
  /** @deprecated Use identity instead */
  feedId: Scalars['String'];
  /** The identity of the feed user. Generally in the format rivt_<uuid>. */
  identity?: Maybe<Scalars['String']>;
  socialProfile?: Maybe<SocialProfile>;
  /** @deprecated Use identity instead */
  targetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type FeedServiceFollowersResult = {
  __typename?: 'FeedServiceFollowersResult';
  /** A list of feeds that follow the input feed target. */
  followers?: Maybe<Array<Maybe<FeedServiceFollower>>>;
};

export type FeedServiceFollowingResult = {
  __typename?: 'FeedServiceFollowingResult';
  /** A list of feeds the input feed target follows. */
  following?: Maybe<Array<Maybe<FeedServiceFollower>>>;
};

export type FeedServiceNotificationActivity = {
  __typename?: 'FeedServiceNotificationActivity';
  activity?: Maybe<FeedServiceAggregateActivity>;
  createdAt?: Maybe<Scalars['String']>;
  isRead?: Maybe<Scalars['Boolean']>;
  isSeen?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type FeedServiceNotifications = {
  __typename?: 'FeedServiceNotifications';
  activities?: Maybe<Array<Maybe<FeedServiceNotificationActivity>>>;
  unread?: Maybe<Scalars['Int']>;
  unseen?: Maybe<Scalars['Int']>;
};

export type FeedServiceObjectReference =
  | AthleticEvent
  | ContentArticle
  | GaiaTrack
  | MediaItem
  | PinkbikeArticle
  | TrailforksPhoto
  | TrailforksReport
  | TrailforksRidelog
  | TrailforksRoute
  | TrailforksTrail
  | UgcPost;

export type FeedServiceReaction = {
  __typename?: 'FeedServiceReaction';
  /** The number of reactions for the feed activity. */
  count?: Maybe<Scalars['Int']>;
  /** The profiles of users who reacted to the feed activity. */
  profiles?: Maybe<Array<Maybe<SocialProfile>>>;
  /** The type of reaction. Example: 'like', 'save', 'share', etc. */
  type?: Maybe<Scalars['String']>;
};

export type FeedServiceReactions = {
  __typename?: 'FeedServiceReactions';
  all?: Maybe<Array<Maybe<FeedServiceReaction>>>;
  own?: Maybe<Array<Maybe<FeedServiceReaction>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type FeedServiceResult = {
  __typename?: 'FeedServiceResult';
  /** Hack around return types for now. */
  data?: Maybe<Scalars['JSON']>;
  from_user?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  to_user?: Maybe<Scalars['String']>;
};

export type FilmCategory = {
  __typename?: 'FilmCategory';
  featuredPlaylist?: Maybe<FeaturedPlaylist>;
  imageId: Scalars['String'];
  imageUrl: Scalars['String'];
  playlist: Playlist;
  seriesPlaylist?: Maybe<SeriesPlaylist>;
  title: Scalars['ID'];
};

export type FilmSlide = {
  __typename?: 'FilmSlide';
  id: Scalars['BigInt'];
  image: Scalars['String'];
  imageAltTag: Scalars['String'];
  isContentFree: Scalars['Boolean'];
  mediaId: Scalars['String'];
  mediaItem?: Maybe<MediaItem>;
  optionalTitle?: Maybe<Scalars['String']>;
  postId: Scalars['BigInt'];
  type: HeroSlideType;
};

/** Type of Id used to query JWPlayer: Media or Feed */
export enum FilterType {
  Feed = 'Feed',
  Media = 'Media'
}

export type FindOnListInput = {
  contentType: BookmarkContentType;
  contentUid: Scalars['String'];
};

export type FooterCta = {
  __typename?: 'FooterCta';
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  subhead?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type GaiaTrack = {
  __typename?: 'GaiaTrack';
  activities?: Maybe<Array<Maybe<Scalars['String']>>>;
  averageSpeed?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  gaiaTrackId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  movingSpeed?: Maybe<Scalars['Float']>;
  movingTime?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  stoppedTime?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  totalAscent?: Maybe<Scalars['Float']>;
  totalDescent?: Maybe<Scalars['Float']>;
  totalTime?: Maybe<Scalars['Float']>;
  user?: Maybe<GaiaTrackUser>;
};

export type GaiaTrackResponse = {
  __typename?: 'GaiaTrackResponse';
  track?: Maybe<GaiaTrack>;
};

export type GaiaTrackUser = {
  __typename?: 'GaiaTrackUser';
  activities?: Maybe<Array<Maybe<Scalars['String']>>>;
  bio?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  rivtUuid?: Maybe<Scalars['String']>;
};

export type GalleryArticle = {
  __typename?: 'GalleryArticle';
  createdDate: Scalars['DateTime'];
  displayTitle: Scalars['String'];
  id: Scalars['Float'];
  images?: Maybe<Array<GalleryImage>>;
  photographers?: Maybe<Array<ContentAuthor>>;
  photosCredit?: Maybe<Scalars['String']>;
  subhead?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
};

export type GalleryImage = {
  __typename?: 'GalleryImage';
  altText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  creditType?: Maybe<Scalars['String']>;
  imageText?: Maybe<Scalars['String']>;
  mobileAltText?: Maybe<Scalars['String']>;
  mobileCaption?: Maybe<Scalars['String']>;
  mobileCreditType?: Maybe<Scalars['String']>;
  mobileImageUrl?: Maybe<Scalars['String']>;
  mobilePhotoCredit?: Maybe<Scalars['String']>;
  photoCredit?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type Gear = {
  __typename?: 'Gear';
  brand?: Maybe<Scalars['String']>;
  buyUrls?: Maybe<Scalars['String']>;
  createdDate: Scalars['DateTime'];
  id: Scalars['Float'];
  model?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  releaseDate?: Maybe<Scalars['DateTime']>;
  review?: Maybe<Scalars['JSON']>;
  sku?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
  weight?: Maybe<Scalars['String']>;
};

export type GeoRestriction = {
  __typename?: 'GeoRestriction';
  countries: Array<Scalars['String']>;
  rule: GeoRestrictionRule;
};

export enum GeoRestrictionRule {
  Allow = 'ALLOW',
  Deny = 'DENY'
}

export type GetBookmarkListInput = {
  listName: Scalars['String'];
};

export type GetReactionInput = {
  contentType: BookmarkContentType;
  contentUid: Scalars['String'];
  reactionName: ReactionName;
};

export type GraphImage = {
  __typename?: 'GraphImage';
  alt?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type Hero = {
  __typename?: 'Hero';
  /** @deprecated Use heroImage on the Course object type. */
  image?: Maybe<Scalars['String']>;
  subhead: Scalars['String'];
  title: Scalars['String'];
  video?: Maybe<Scalars['String']>;
};

export type HeroSection = {
  __typename?: 'HeroSection';
  content: Scalars['String'];
  headerImage: GraphImage;
  sideImage: GraphImage;
};

export type HeroSlide =
  | EpisodeSlide
  | FilmSlide
  | LinkSlide
  | SeasonSlide
  | SeriesSlide;

export enum HeroSlideType {
  Episode = 'EPISODE',
  Film = 'FILM',
  Link = 'LINK',
  Season = 'SEASON',
  Series = 'SERIES'
}

export type Hike = {
  __typename?: 'Hike';
  bounds: Array<Scalars['Float']>;
  centroid: Array<Scalars['Float']>;
  country: Scalars['String'];
  cover_photo_id?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  known_route_id: Scalars['Float'];
  kr_ascent: Scalars['Float'];
  kr_difficulty: Scalars['Float'];
  kr_difficulty_class: Scalars['String'];
  kr_driveable: Scalars['Boolean'];
  kr_length: Scalars['Float'];
  kr_route_type: Scalars['String'];
  kr_tobler_time?: Maybe<Scalars['Boolean']>;
  land?: Maybe<Scalars['String']>;
  location: Array<Scalars['Float']>;
  osm_id: Scalars['Float'];
  permalink: Scalars['String'];
  permalink_api: Scalars['String'];
  permalink_api_v2: Scalars['String'];
  stars: Scalars['Float'];
  state: Scalars['String'];
  subtitle: Scalars['String'];
  thumbnail_url?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type HikeFilter = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type Hikes = {
  __typename?: 'Hikes';
  hikes: Array<Hike>;
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type HomepageAttachments = {
  __typename?: 'HomepageAttachments';
  litepaper: OutsideioAttachment;
  mobileTrailmap: OutsideioAttachment;
  whitepaper: OutsideioAttachment;
};

export type HomepageCourseBlock = {
  __typename?: 'HomepageCourseBlock';
  courses?: Maybe<Array<Course>>;
  subhead?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type HttpStatus = {
  __typename?: 'HttpStatus';
  code: Scalars['Int'];
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  message: Scalars['String'];
};

export type IAddBookmarkMutation = {
  addBookmark: Bookmark;
  addReaction: ReactionEdge;
  reaction: ReactionEdge;
};

export type IAddBookmarkMutationAddBookmarkArgs = {
  data: AddBookmarkInput;
};

export type IAddBookmarkMutationAddReactionArgs = {
  data: AddReactionInput;
};

export type IAddBookmarkMutationReactionArgs = {
  data: GetReactionInput;
};

export type IAthleticEventDisplay = {
  /** City the event is happening in */
  city?: Maybe<Scalars['String']>;
  /** Date of the earliest category in the event */
  date: Scalars['DateTime'];
  /** Date of the latest category in the event */
  eventEndDate: Scalars['DateTime'];
  /**
   * Get the event type names from the DB, or cached list. These are strings that can differ based on the application type
   *
   *
   * **Returns:**
   * IEnumerable of event types for this event
   */
  eventTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Full URL chosen by the event director. Note this is not guaranteed to lead to this event as directors reuse these, and it can change */
  eventUrl?: Maybe<Scalars['String']>;
  /** Latitude for event precise location */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude for event precise location */
  longitude?: Maybe<Scalars['Float']>;
  /** Plaintext of the event name */
  name?: Maybe<Scalars['String']>;
  /** State the event is happening in */
  state?: Maybe<Scalars['String']>;
  /** Zip code for the event (not necessarily US) */
  zip?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  src: Scalars['String'];
  type: Scalars['String'];
  width: Scalars['Float'];
};

export enum ImageSize {
  FullSize = 'FULL_SIZE',
  Mobile = 'MOBILE',
  Thumbnail = 'THUMBNAIL'
}

export type ImageSizes = {
  __typename?: 'ImageSizes';
  large?: Maybe<CourseImage>;
  medium?: Maybe<CourseImage>;
  mediumLarge?: Maybe<CourseImage>;
  thumbnail?: Maybe<CourseImage>;
};

export type ImportBookmarkInput = {
  contentUid: Scalars['String'];
  listName: Scalars['String'];
  secret: Scalars['String'];
  userUuid: Scalars['String'];
};

export type Instructor = {
  __typename?: 'Instructor';
  bio?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instagram?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type InstructorsBlock = {
  __typename?: 'InstructorsBlock';
  instructors?: Maybe<Array<Instructor>>;
  subhead?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type JoinTheCommunitySection = {
  __typename?: 'JoinTheCommunitySection';
  header: Scalars['String'];
  subheader: Scalars['String'];
};

export type LatLongInput = {
  latitude: Scalars['Decimal'];
  longitude: Scalars['Decimal'];
};

export type LearnArchivePage = {
  __typename?: 'LearnArchivePage';
  courseCarousels?: Maybe<Array<CourseCarousel>>;
  featuredCourses: Array<Course>;
  hero?: Maybe<LearnArchivePageHero>;
  id: Scalars['BigInt'];
  seo?: Maybe<LearnSeo>;
};

export type LearnArchivePageHero = {
  __typename?: 'LearnArchivePageHero';
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  subhead?: Maybe<Scalars['String']>;
};

export type LearnCta = {
  __typename?: 'LearnCTA';
  buttonText: Scalars['String'];
  description: Scalars['String'];
  headline: Scalars['String'];
  priceDisclaimer?: Maybe<Scalars['String']>;
  priceDiscount?: Maybe<Scalars['String']>;
};

export type LearnCategory = {
  __typename?: 'LearnCategory';
  courseCount: Scalars['Int'];
  courses?: Maybe<Array<Course>>;
  description?: Maybe<Scalars['String']>;
  featuredCourses: Array<Course>;
  hero?: Maybe<LearnArchivePageHero>;
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  seo?: Maybe<LearnSeo>;
  standaloneLessons?: Maybe<Array<Lesson>>;
};

export type LearnHomepage = {
  __typename?: 'LearnHomepage';
  courseBlock?: Maybe<HomepageCourseBlock>;
  footerCta?: Maybe<FooterCta>;
  hero?: Maybe<LearnHomepageHero>;
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  instructorsBlock?: Maybe<InstructorsBlock>;
  promotionalSection: Array<PromotionalSection>;
  seo?: Maybe<LearnSeo>;
  title: Scalars['String'];
};

export type LearnHomepageHero = {
  __typename?: 'LearnHomepageHero';
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  subhead?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  video?: Maybe<Scalars['String']>;
};

export type LearnMarketingModal = {
  __typename?: 'LearnMarketingModal';
  benefitsList?: Maybe<Array<Scalars['String']>>;
  detailsLink?: Maybe<Scalars['String']>;
  detailsText?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  discountPrice?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceDuration?: Maybe<Scalars['String']>;
};

export type LearnSeo = {
  __typename?: 'LearnSeo';
  description?: Maybe<Scalars['String']>;
  modifiedTime: Scalars['DateTime'];
  ogDescription?: Maybe<Scalars['String']>;
  ogImage?: Maybe<Scalars['String']>;
  ogImageHeight?: Maybe<Scalars['Float']>;
  ogImageWidth?: Maybe<Scalars['Float']>;
  ogTitle: Scalars['String'];
  publishedTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type Lesson = {
  __typename?: 'Lesson';
  completed?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  instructors?: Maybe<Array<Instructor>>;
  isStandalone: Scalars['Boolean'];
  name: Scalars['String'];
  overview?: Maybe<LessonOverview>;
  previewContent?: Maybe<Scalars['String']>;
  resources?: Maybe<Array<Resource>>;
  seo?: Maybe<LearnSeo>;
  title: Scalars['String'];
  video?: Maybe<Scalars['ID']>;
};

export type LessonImageArgs = {
  thumbnailId?: InputMaybe<Scalars['BigInt']>;
};

export type LessonSeoArgs = {
  isPreview?: InputMaybe<Scalars['Boolean']>;
};

export type LessonCompletionInput = {
  courseId?: InputMaybe<Scalars['BigInt']>;
  isCompleted: Scalars['Boolean'];
  lessonId: Scalars['BigInt'];
};

export type LessonOverview = {
  __typename?: 'LessonOverview';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LinkSlide = {
  __typename?: 'LinkSlide';
  id: Scalars['BigInt'];
  image: Scalars['String'];
  imageAltTag: Scalars['String'];
  imageLink: Scalars['String'];
  isContentFree: Scalars['Boolean'];
  postId: Scalars['BigInt'];
  title: Scalars['String'];
  type: HeroSlideType;
};

export type MediaBlock = {
  __typename?: 'MediaBlock';
  description: Scalars['String'];
  image?: Maybe<AttachedImage>;
  title: Scalars['String'];
  video?: Maybe<Scalars['String']>;
};

export type MediaFilter = {
  /** Short form or long form */
  length?: InputMaybe<Scalars['String']>;
};

export type MediaItem = {
  __typename?: 'MediaItem';
  additionalFields?: Maybe<Scalars['JSONObject']>;
  associatedFilmId?: Maybe<Scalars['ID']>;
  associatedTrailerId?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['ID']>;
  contentItem?: Maybe<ContentItem>;
  contentType?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  creditTimestamp: Scalars['Float'];
  description: Scalars['String'];
  duration: Scalars['Float'];
  episodeMeta?: Maybe<EpisodeMeta>;
  feedId?: Maybe<Scalars['ID']>;
  filmDuration?: Maybe<Scalars['Int']>;
  filmId?: Maybe<Scalars['String']>;
  filmMediaItem?: Maybe<MediaItem>;
  geoRestriction?: Maybe<GeoRestriction>;
  images: Array<Image>;
  isAdsFree: Scalars['Boolean'];
  isDrmProtected: Scalars['Boolean'];
  /** @deprecated No longer in use. The value was replaced bt the "meterFlow" field to control the access */
  isFreeContent: Scalars['Boolean'];
  link: Scalars['String'];
  liveState?: Maybe<EventState>;
  location?: Maybe<Scalars['String']>;
  mediaId: Scalars['ID'];
  mediaItemLink: Scalars['String'];
  meterFlow: MeterFlow;
  modifiedTime?: Maybe<Scalars['DateTimeISO']>;
  pageview?: Maybe<Scalars['Int']>;
  poster?: Maybe<Scalars['String']>;
  productionYear?: Maybe<Scalars['ID']>;
  pubDate: Scalars['Float'];
  publishedTime?: Maybe<Scalars['DateTimeISO']>;
  querystring?: Maybe<Scalars['String']>;
  scheduledStart?: Maybe<Scalars['DateTimeISO']>;
  /** List of seasons progress. Not available for films. */
  seasonsProgress?: Maybe<Array<SeasonProgress>>;
  seriesCount?: Maybe<SeriesCount>;
  seriesId?: Maybe<Scalars['String']>;
  seriesMeterflow?: Maybe<MeterFlow>;
  seriesPlaylistId?: Maybe<Scalars['String']>;
  /** Series progress. For films use "userProgress" */
  seriesProgress?: Maybe<SeriesProgress>;
  seriesSlug?: Maybe<Scalars['String']>;
  shoulderProgrammingPlaylist?: Maybe<Playlist>;
  shoulderProgrammingPlaylistid?: Maybe<Scalars['String']>;
  shoulderProgrammingTitle?: Maybe<Scalars['String']>;
  sources: Array<Source>;
  subcategory?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  tracks: Array<Track>;
  trailerMediaItem?: Maybe<MediaItem>;
  url?: Maybe<Scalars['String']>;
  /** Film progress. For series use "seriesProgress" */
  userProgress?: Maybe<VideoProgress>;
};

export type MediaItemShoulderProgrammingPlaylistArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type MediaItemMutation = IAddBookmarkMutation & {
  __typename?: 'MediaItemMutation';
  /** Creates a bookmark on user's list. */
  addBookmark: Bookmark;
  /** Registers a user's reaction to outside content */
  addReaction: ReactionEdge;
  /** Get user's reactions to outside content */
  reaction: ReactionEdge;
  reference: MediaItem;
};

export type MediaItemMutationAddBookmarkArgs = {
  data: AddBookmarkInput;
};

export type MediaItemMutationAddReactionArgs = {
  data: AddReactionInput;
};

export type MediaItemMutationReactionArgs = {
  data: GetReactionInput;
};

export type MediaSummary = {
  __typename?: 'MediaSummary';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type MediaUrl = {
  __typename?: 'MediaUrl';
  url: Scalars['String'];
};

/** Information about media availability for corresponding meter flow. If the media doesn't have the meterflow parameter, it defaults to FREE for videos without DRM protection and to LOCKED for videos protected by DRM */
export enum MeterFlow {
  Free = 'FREE',
  Locked = 'LOCKED',
  Meter = 'METER',
  None = 'NONE',
  Register = 'REGISTER'
}

export type Milestone = {
  __typename?: 'Milestone';
  label: Scalars['String'];
  milestoneItems: Array<MilestoneItem>;
};

export type MilestoneItem = {
  __typename?: 'MilestoneItem';
  title: Scalars['String'];
  type: MilestoneItemType;
};

/** Type of Milestone Item */
export enum MilestoneItemType {
  Irl = 'IRL',
  Nft = 'NFT'
}

export type MisoForUserContentArticle = {
  __typename?: 'MisoForUserContentArticle';
  items?: Maybe<Array<Maybe<ContentReference>>>;
};

export type MisoSearchContentArticle = {
  __typename?: 'MisoSearchContentArticle';
  items?: Maybe<Array<Maybe<ContentReference>>>;
};

export type MisoSimilarContentArticle = {
  __typename?: 'MisoSimilarContentArticle';
  items?: Maybe<Array<Maybe<ContentReference>>>;
};

export type MisoTrendingContentArticle = {
  __typename?: 'MisoTrendingContentArticle';
  items?: Maybe<Array<Maybe<ContentReference>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Wrapper mutation to provide an interface with AddBookmark resolver. */
  createAddBookmarkMutation?: Maybe<IAddBookmarkMutation>;
  /** Creates a new bookmark list */
  createBookmarkList?: Maybe<BookmarkList>;
  /** Creates or updates a content article */
  createOrUpdateContentArticle: CreateOrUpdateContentArticlePayload;
  /**
   * Accept or deny a follow request from another user.
   * followerId: Identity of the user that will be accepted or denied.
   * accept: Boolean value to accept or deny the follow request.
   */
  feedServiceAcceptOrDenyFollowRequest: FeedServiceResult;
  /**
   * Block a user from another user.
   *
   * The blocking user will be pulled from the JWT Payload.
   */
  feedServiceBlock: FeedServiceBlockUnblockResult;
  /**
   * Follow a feed target from the Front End / Auth Hub BFF.
   *
   * The follower user will be pulled from the JWT Payload.
   *
   * feedTarget: A composite key of <FeedGroup>:<Identity>.  Example: 'source:rivt_<uuid> - DEPRECATED
   *
   * identity: The identity of the feed user. Generally in the format rivt_<uuid>. OUTSIDE, etc, is also ok
   */
  feedServiceFollow: FeedServiceResult;
  /**
   * Toggle reaction to a Stream Activity by its Id. Adds a reaction of the specified type if it
   * does not exist, removes it if it does, and updates it if the existing type is different.
   * Also supports reacting to an atomic object using objectId.
   *
   * streamId: The Stream Activity ID, as a UUID 1.
   *
   * objectId: An internal Object ID, such as tf_Ridelog:71608801, UgcPost:5f52241a-2036-4d7d-9246-206a2d55aa3d
   *
   * type: The type of reaction. Example: 'highfive', 'like', 'save', 'share', etc. DEFAULT: 'highfive'
   *
   * domain: The source domain of the reaction. Example: 'outside', 'trailforks', etc. DEFAULT: none
   */
  feedServiceReact: FeedServiceResult;
  /**
   * Share a Stream Activity by Id into a feed target.
   *
   * The feed the activity will be shared to will be:  source:rivt_<uuid> from the JWT Payload.
   *
   * streamId: The Stream ID, as a UUID 1.
   */
  feedServiceShare: FeedServiceResult;
  /**
   * Unblock a user from another user.
   *
   * The blocking user will be pulled from the JWT Payload.
   */
  feedServiceUnblock: FeedServiceBlockUnblockResult;
  /**
   * Unfollow a feed target from the Front End / Auth Hub BFF.
   *
   * The follower user will be pulled from the JWT Payload.
   *
   * feedTarget: A composite key of <FeedGroup>:<Identity>.  Example: 'source:rivt_<uuid>' - DEPRECATED
   *
   * identity: The identity of the feed user. Generally in the format rivt_<uuid>. OUTSIDE, etc, is also ok
   */
  feedServiceUnfollow: FeedServiceResult;
  importBookmark: ContentItemMutation;
  redeemOffer: RedeemOfferOutput;
  /** @deprecated Use generic redeemOffer mutation */
  redeemOutsidePlus: RedeemOfferOutput;
  /** Deletes a bookmark from a list based by `bookmarkId`. Returns deleted `Bookmark` object or `null` if nothing was deleted. */
  removeBookmark?: Maybe<Bookmark>;
  /** Deletes a bookmark from a list based on it's `contentUid`. */
  removeBookmarkByUid: Scalars['Boolean'];
  saveLessonCompletion: SaveLessonCompletionPayload;
  /** Save series episode progress */
  saveSeriesEpisodeProgress?: Maybe<VideoProgressResponse>;
  saveVideoWatchTime: VideoWatchTimeResponse;
  /** Soft deletes a content article */
  softDeleteContentArticle: SoftDeleteContentArticlePayload;
  /** Create a new UGC Comment. */
  ugcCreateComment?: Maybe<UgcComment>;
  /** Create a new UGC Post. */
  ugcCreatePost?: Maybe<UgcPost>;
  /** Delete a UGC Comment. */
  ugcDeleteComment?: Maybe<Scalars['Boolean']>;
  /** Delete a UGC Post. */
  ugcDeletePost?: Maybe<Scalars['Boolean']>;
  /** Report a UGC Comment. */
  ugcReportComment?: Maybe<Scalars['Boolean']>;
  /** Report a UGC Post. */
  ugcReportPost?: Maybe<Scalars['Boolean']>;
  /** Report a UGC User. */
  ugcReportUser?: Maybe<Scalars['Boolean']>;
  /** Updates the status of a content article */
  updateContentArticleStatus: UpdateContentArticleStatusPayload;
  /** Save film progress */
  videoProgress?: Maybe<VideoProgressResponse>;
};

export type MutationCreateAddBookmarkMutationArgs = {
  data: AddBookmarkInput;
};

export type MutationCreateBookmarkListArgs = {
  data: CreateBookmarkListInput;
};

export type MutationCreateOrUpdateContentArticleArgs = {
  data: CreateOrUpdateContentArticleInput;
};

export type MutationFeedServiceAcceptOrDenyFollowRequestArgs = {
  accept?: InputMaybe<Scalars['Boolean']>;
  followerId?: InputMaybe<Scalars['String']>;
};

export type MutationFeedServiceBlockArgs = {
  blockedUserId?: InputMaybe<Scalars['String']>;
  siteSource?: InputMaybe<Scalars['String']>;
};

export type MutationFeedServiceFollowArgs = {
  feedTarget?: InputMaybe<Scalars['String']>;
  identity?: InputMaybe<Scalars['String']>;
};

export type MutationFeedServiceReactArgs = {
  domain?: InputMaybe<Scalars['String']>;
  objectId?: InputMaybe<Scalars['String']>;
  streamId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MutationFeedServiceShareArgs = {
  streamId: Scalars['String'];
};

export type MutationFeedServiceUnblockArgs = {
  siteSource?: InputMaybe<Scalars['String']>;
  unblockedUserId?: InputMaybe<Scalars['String']>;
};

export type MutationFeedServiceUnfollowArgs = {
  feedTarget?: InputMaybe<Scalars['String']>;
  identity?: InputMaybe<Scalars['String']>;
};

export type MutationImportBookmarkArgs = {
  data: ImportBookmarkInput;
};

export type MutationRedeemOfferArgs = {
  data: RedeemOfferInput;
};

export type MutationRedeemOutsidePlusArgs = {
  data: RedeemOfferInput;
};

export type MutationRemoveBookmarkArgs = {
  data: RemoveBookmarkInput;
};

export type MutationRemoveBookmarkByUidArgs = {
  data: RemoveBookmarkByUidInput;
};

export type MutationSaveLessonCompletionArgs = {
  data: LessonCompletionInput;
};

export type MutationSaveSeriesEpisodeProgressArgs = {
  data: SeriesEpisodeProgressInput;
};

export type MutationSaveVideoWatchTimeArgs = {
  data: VideoWatchTimeInput;
};

export type MutationSoftDeleteContentArticleArgs = {
  data: SoftDeleteContentArticleInput;
};

export type MutationUgcCreateCommentArgs = {
  input: UgcCreateCommentInput;
};

export type MutationUgcCreatePostArgs = {
  input: UgcCreatePostInput;
};

export type MutationUgcDeleteCommentArgs = {
  input: UgcDeleteCommentInput;
};

export type MutationUgcDeletePostArgs = {
  input: UgcDeletePostInput;
};

export type MutationUgcReportCommentArgs = {
  input: UgcReportCommentInput;
};

export type MutationUgcReportPostArgs = {
  input: UgcReportPostInput;
};

export type MutationUgcReportUserArgs = {
  input: UgcReportUserInput;
};

export type MutationUpdateContentArticleStatusArgs = {
  data: UpdateContentArticleStatusInput;
};

export type MutationVideoProgressArgs = {
  data: VideoProgressInput;
};

export type NewsAndCreatorUpdates = {
  __typename?: 'NewsAndCreatorUpdates';
  content: Scalars['String'];
  id: Scalars['BigInt'];
  metadata: NewsAndCreatorUpdatesMetadata;
  sideImage: GraphImage;
  title: Scalars['String'];
};

export type NewsAndCreatorUpdatesMetadata = {
  __typename?: 'NewsAndCreatorUpdatesMetadata';
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  showButton: Scalars['Boolean'];
};

export type OPlusOfferRedemptionData = {
  __typename?: 'OPlusOfferRedemptionData';
  expiresAt?: Maybe<Scalars['String']>;
};

export type Offer = {
  __typename?: 'Offer';
  buttonText?: Maybe<Scalars['String']>;
  collection: Collection;
  description?: Maybe<Scalars['String']>;
  disclaimerHtml?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  isNew: Scalars['Boolean'];
  isSoldout: Scalars['Boolean'];
  loginRequired: Scalars['Boolean'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  offerLimit?: Maybe<Scalars['Float']>;
  offerRedemption?: Maybe<OfferRedemption>;
  redemptionDetailsUrl?: Maybe<Scalars['String']>;
  type: OfferType;
};

export type OfferCode = {
  __typename?: 'OfferCode';
  code: Scalars['String'];
  id: Scalars['BigInt'];
};

export type OfferFilters = {
  isAvailable?: InputMaybe<Scalars['Boolean']>;
};

export type OfferPage = {
  __typename?: 'OfferPage';
  items: Array<Offer>;
  totalCount: Scalars['Int'];
};

export type OfferRedemption = {
  __typename?: 'OfferRedemption';
  data?: Maybe<OfferRedemptionData>;
  id: Scalars['BigInt'];
  redeemedAt: Scalars['DateTime'];
  redeemedBy: Scalars['String'];
};

export type OfferRedemptionData = OPlusOfferRedemptionData;

export enum OfferType {
  Button = 'BUTTON',
  Code = 'CODE',
  Oplus = 'OPLUS'
}

export type OutsidePlusOfferResult = {
  __typename?: 'OutsidePlusOfferResult';
  expiresAt: Scalars['String'];
  result: Scalars['String'];
};

export type OutsideSeoImage = {
  __typename?: 'OutsideSeoImage';
  alt?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
};

export type OutsideioAttachment = {
  __typename?: 'OutsideioAttachment';
  mime: Scalars['String'];
  url: Scalars['String'];
};

export type OutsideioBrand = {
  __typename?: 'OutsideioBrand';
  image: GraphImage;
  url: Scalars['String'];
};

export type OutsideioConfirmationModal = {
  __typename?: 'OutsideioConfirmationModal';
  content: Scalars['String'];
  header: Scalars['String'];
  image: GraphImage;
  learnMore: Scalars['String'];
  subheader: Scalars['String'];
};

export type OutsideioCreator = {
  __typename?: 'OutsideioCreator';
  collectionUrl?: Maybe<Scalars['String']>;
  headline: Scalars['String'];
  image: GraphImage;
  name: Scalars['String'];
};

export type OutsideioCreatorsPage = {
  __typename?: 'OutsideioCreatorsPage';
  brandPartnersSection: BrandPartnersSection;
  creatorsSection: CreatorsSection;
  id: Scalars['BigInt'];
};

export type OutsideioFaqPage = {
  __typename?: 'OutsideioFaqPage';
  faqs: Array<Faq>;
  seo: OutsideioSeo;
};

export type OutsideioHomepage = {
  __typename?: 'OutsideioHomepage';
  benefitsSection: BenefitsSection;
  collaborateSection: CollaborateSection;
  ecoCampaignSection: EcoCampaignSection;
  heroSection: HeroSection;
  homepageAttachments: HomepageAttachments;
  id: Scalars['BigInt'];
  joinTheCommunitySection: JoinTheCommunitySection;
  newsAndCreatorUpdates: Array<NewsAndCreatorUpdates>;
  passportSection: PassportSection;
  seo: OutsideioSeo;
  trailmapSection: TrailmapSection;
};

export type OutsideioPrivacyPolicy = {
  __typename?: 'OutsideioPrivacyPolicy';
  content: Scalars['String'];
  seo: OutsideioSeo;
  title: Scalars['String'];
};

export type OutsideioSeo = {
  __typename?: 'OutsideioSeo';
  description: Scalars['String'];
  modifiedTime: Scalars['DateTime'];
  ogDescription: Scalars['String'];
  ogImage: OutsideSeoImage;
  ogTitle: Scalars['String'];
  publishedTime: Scalars['DateTime'];
  title: Scalars['String'];
};

export type OutsideioSocialLinks = {
  __typename?: 'OutsideioSocialLinks';
  discord: Scalars['String'];
  discordTitle: Scalars['String'];
  instagram: Scalars['String'];
  instagramTitle: Scalars['String'];
  outsideplus: Scalars['String'];
  outsideplusTitle: Scalars['String'];
  twitter: Scalars['String'];
  twitterTitle: Scalars['String'];
};

export type Overview = {
  __typename?: 'Overview';
  description: Scalars['String'];
  title: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginationArgs = {
  offset?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PassportSection = {
  __typename?: 'PassportSection';
  content: Scalars['String'];
  headerImage: GraphImage;
  listItems: Array<Scalars['String']>;
  sideImage: GraphImage;
};

export type PhotoThumbs = {
  __typename?: 'PhotoThumbs';
  l?: Maybe<Scalars['String']>;
  s?: Maybe<Scalars['String']>;
};

export type PhotoTrail = {
  __typename?: 'PhotoTrail';
  difficulty?: Maybe<PhotoTrailDifficulty>;
  title?: Maybe<Scalars['String']>;
  trailid?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type PhotoTrailDifficulty = {
  __typename?: 'PhotoTrailDifficulty';
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type PinkbikeArticle = {
  __typename?: 'PinkbikeArticle';
  authorId?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  authorUrl?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  categoryTags?: Maybe<Array<Maybe<PinkbikeTag>>>;
  formattedContent?: Maybe<Scalars['String']>;
  hot?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  itemCreatedAt?: Maybe<Scalars['String']>;
  itemImageUrl?: Maybe<Scalars['String']>;
  itemSubtitle?: Maybe<Scalars['String']>;
  itemTitle?: Maybe<Scalars['String']>;
  itemType?: Maybe<Scalars['String']>;
  liveDate?: Maybe<Scalars['String']>;
  memberOnly?: Maybe<Scalars['Boolean']>;
  otherTags?: Maybe<Array<Maybe<PinkbikeTag>>>;
  pageview?: Maybe<Scalars['Int']>;
  peopleTags?: Maybe<Array<Maybe<PinkbikeTag>>>;
  relatedRegions?: Maybe<Array<Maybe<PinkbikeRegion>>>;
  seriesTags?: Maybe<Array<Maybe<PinkbikeTag>>>;
  totalComments?: Maybe<Scalars['Int']>;
  totalPhotos?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type PinkbikeArticleResponse = {
  __typename?: 'PinkbikeArticleResponse';
  article?: Maybe<PinkbikeArticle>;
};

export type PinkbikeRegion = {
  __typename?: 'PinkbikeRegion';
  /** Region model for Pinkbike articles. */
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PinkbikeTag = {
  __typename?: 'PinkbikeTag';
  brandid?: Maybe<Scalars['String']>;
  hashtag?: Maybe<Scalars['String']>;
  /** Tag model for Pinkbike articles. */
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeid?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
};

export type PlayerUrl = {
  __typename?: 'PlayerUrl';
  url: Scalars['String'];
};

export type Playlist = {
  __typename?: 'Playlist';
  count?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  feedId: Scalars['ID'];
  heroImage?: Maybe<Scalars['String']>;
  mediaItems: Array<MediaItem>;
  mediaItemsLink: Scalars['String'];
  seasons?: Maybe<Array<Season>>;
  series?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PlaylistFilter = {
  ID: Scalars['ID'];
  drm?: InputMaybe<Scalars['Boolean']>;
  filterType?: InputMaybe<FilterType>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** types of playlists that can be requested */
export enum PlaylistTypes {
  ActionPlaylist = 'ActionPlaylist',
  AdventurePlaylist = 'AdventurePlaylist',
  AllFilms = 'AllFilms',
  AllSeries = 'AllSeries',
  AllShorts = 'AllShorts',
  BikePlaylist = 'BikePlaylist',
  ClimbPlaylist = 'ClimbPlaylist',
  EndurancePlaylist = 'EndurancePlaylist',
  FisReplays = 'FISReplays',
  Featured = 'Featured',
  FeaturedShorts = 'FeaturedShorts',
  Film = 'Film',
  FitnessPlaylist = 'FitnessPlaylist',
  LiveAndUpcomingEvents = 'LiveAndUpcomingEvents',
  LocalChannels = 'LocalChannels',
  RecapsAndReplays = 'RecapsAndReplays',
  Series = 'Series',
  SnowPlaylist = 'SnowPlaylist',
  SustainabilityPlaylist = 'SustainabilityPlaylist',
  UpcomingLiveEvents = 'UpcomingLiveEvents',
  WaterPlaylist = 'WaterPlaylist'
}

export type Podcast = {
  __typename?: 'Podcast';
  createdDate: Scalars['DateTime'];
  disableAds?: Maybe<Scalars['Boolean']>;
  displayTitle: Scalars['String'];
  excludePost?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  podcastFileUrl: Scalars['String'];
  showNotes?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  transcript?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
};

export type Poster = {
  __typename?: 'Poster';
  image: Scalars['String'];
  imageAltTag: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  collections: Array<Collection>;
  creators: Array<ProjectCreatorEdge>;
  description: Scalars['String'];
  externalUrl: Scalars['String'];
  headerImageUrl: Scalars['String'];
  id: Scalars['BigInt'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updated?: Maybe<Scalars['DateTime']>;
};

export type ProjectCreatorEdge = {
  __typename?: 'ProjectCreatorEdge';
  creator: Creator;
  role: CreatorProjectRole;
};

export type PromotionalSection = {
  __typename?: 'PromotionalSection';
  buttonLink?: Maybe<Scalars['String']>;
  buttonText?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  mediaOptions: Scalars['String'];
  subhead?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  video?: Maybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type Query = {
  __typename?: 'Query';
  /**
   * Resolver for getting an external event by Id. Does not currently handle repeating events.
   *
   *
   * **Returns:**
   * the event, and any associated information resolved by the query
   */
  AR_ExternalEvent?: Maybe<Ar_ExternalAthleticEvent>;
  /**
   * Get all sanctioning bodies that events can be sanctioned by
   *
   *
   * **Returns:**
   * List of all sanctioning bodies
   */
  ARegSanctioningBodies?: Maybe<Array<Maybe<ARegSanctioningBody>>>;
  allSeries: Playlist;
  asset?: Maybe<Asset>;
  assetByPublicKey?: Maybe<Asset>;
  /**
   * Resolver for getting an event by Id
   *
   *
   * **Returns:**
   * the event, and any associated information resolved by the query
   */
  athleticEvent?: Maybe<AthleticEvent>;
  /**
   * Resolver for getting an event by it's URL (static or vanity)
   *
   *
   * **Returns:**
   * the event and associated resolved information
   */
  athleticEventByURL?: Maybe<AthleticEvent>;
  /**
   * Gets athletic events in the order they should appear on the event calendar
   *
   *
   * **Returns:**
   * A database query for athletic events
   */
  athleticEventCalendar?: Maybe<AthleticEventCalendarConnection>;
  /**
   * Get event types for a given application type
   *
   *
   * **Returns:**
   * List of the event types for the given app type with the given priority
   */
  athleticEventTypes?: Maybe<Array<Maybe<EventType>>>;
  benefit: Benefit;
  benefits: Array<Benefit>;
  /** Returns user's bookmark list. If the user doesn't have a list with the specified name, it will create a new list. */
  bookmarkList: BookmarkList;
  /** Query for chat with Outside AI assistant Scout */
  chat?: Maybe<ChatResult>;
  collection?: Maybe<Collection>;
  collectionAddresses: Array<CollectionAddress>;
  collectionByAddress?: Maybe<Collection>;
  collectionBySlug?: Maybe<Collection>;
  /** @deprecated Use collections query with filter and sort */
  collectionSymbols: Array<Scalars['String']>;
  collections: CollectionsPage;
  /** @deprecated Use `collections` query instead */
  collectionsByStatus: CollectionsPage;
  contentArticle: ContentArticle;
  /** Returns a report of content articles that have been updated since the passed in date */
  contentArticleUpdatesReport?: Maybe<ContentArticleUpdatesReport>;
  /** Get a ContentItem by it's URL. */
  contentItem?: Maybe<ContentItem>;
  /** Query for a list of ContentItems. */
  contentItemsPage?: Maybe<ContentItemsPage>;
  contract?: Maybe<Contract>;
  course: Course;
  creator?: Maybe<Creator>;
  creators: CreatorPage;
  /** @deprecated Use `collections` query with filter and sort */
  currentlyDroppedCollections: Array<Collection>;
  /**
   * Query Discovery to get recommendations on users to follow
   * - uuid: target user uuid
   * - size: number of recommended users
   */
  discoveryFollowUsers?: Maybe<Array<Maybe<RecUserOutput>>>;
  /**
   * Query Miso to get Authors to Follow using a user UUID
   * - uuid: user uuid
   * - size: number of recommended authors
   * - sourceFilters: authors from the list of sourceFilters will be boosted to the top
   *
   * ex: sourceFilters = ['VELO'], authors write for VELO publisher will be boosted to top
   */
  discoveryMisoFollowAuthors?: Maybe<Array<Maybe<RecAuthorOutput>>>;
  /**
   * Query Miso to get Brands to Follow using a user UUID
   * - uuid: user uuid
   * - size: number of recommended authors
   * - sourceBoost: brand name will be boosted to the top
   *
   * ex: sourceBoost = 'VELO', VELO will be boosted to top
   */
  discoveryMisoFollowRecommendationsBrands?: Maybe<Array<Maybe<SocialProfile>>>;
  ecoCampaign: EcoCampaign;
  ecoCampaigns: Array<EcoCampaign>;
  educationPost: EducationPost;
  eventsBucket?: Maybe<EventsBucket>;
  eventsBucketList: Array<EventsBucketSummary>;
  /** @deprecated use outsideFaqPage.faqs */
  faq: Faq;
  /** Returns MediaItem of Outside 24/7 Fast Channel */
  fastChannel?: Maybe<MediaItem>;
  /** Gets EPG schedule for Outside Fast Channel */
  fastChannelEpg: EpgSchedule;
  /**
   * Get current featured event listings in an area, returned in a random order.
   *
   *
   * **Returns:**
   * IQueryable for the featured event listing
   */
  featuredAthleticEvents?: Maybe<Array<Maybe<FeaturedAthleticEvent>>>;
  /** @deprecated Use `collections` query with filter and sort */
  featuredCollections: Array<Collection>;
  feedServiceActivityReactions: FeedServiceActivityReactionsResult;
  /**
   * Retrieve a list of activities from the Feed Service.
   *
   * The feed target will be pulled from the JWT Payload.
   *
   * We may use either the `page` or the `lastActivityId` argument to retrieve the next set of activities.
   * However, if both are provided, the `lastActivityId` will be preferred as a cursor for the next set of activities.
   *
   * Arguments:
   *   feedTarget: A composite key of <FeedGroup>:<Identity>.  Example: 'user:<rivt_uuid>'.
   *   page: The page number to retrieve. Default: 1.
   *   size: The number of activities to retrieve. Default: 20.
   *   lastActivityId: The last Activity ID of the previous page, to retrieve the next page of activities. Default: None.
   */
  feedServiceAggregateActivities: FeedServiceAggregateActivitiesResult;
  /**
   * Retrieves a single activity based on the input activityId.
   *
   * The feed target / identity will be pulled from the JWT Payload.
   *
   * Arguments:
   *   activityId: The ID of the activity to retrieve.
   */
  feedServiceAggregateActivity?: Maybe<FeedServiceAggregateActivityResult>;
  /** Return a list of blocked users */
  feedServiceBlockedUsers: FeedServiceBlockedUserResults;
  /** Return a list of pending follow requests */
  feedServiceFollowRequests: FeedServiceFollowRequestsResult;
  /**
   * Return the number of follower / following feeds for the JWT user.
   *
   * Arguments:
   *   none
   */
  feedServiceFollowStats: FeedServiceFollowStatResult;
  /**
   * Return a list of feeds that follow the input feed target.
   *
   * Arguments:
   *   feedTarget: A composite key of <FeedGroup>:<Identity>.  Example: 'user:<rivt_uuid>'.
   *   identity: The identity of the feed user. Generally in the format rivt_<uuid>.
   *   offset: The query offset. Default: 0.
   *   limit: The number of targets to retrieve. Default: 20.
   */
  feedServiceFollowers: FeedServiceFollowersResult;
  /**
   * Return a list of feeds the input feed target follows.
   *
   * Arguments:
   *   feedTarget: A composite key of <FeedGroup>:<Identity>.  Example: 'user:<rivt_uuid>'.
   *   identity: The identity of the feed user. Generally in the format rivt_<uuid>.
   *   offset: The query offset. Default: 0.
   *   limit: The number of targets to retrieve. Default: 20.
   */
  feedServiceFollowing: FeedServiceFollowingResult;
  feedServiceNotificationFeed: FeedServiceNotifications;
  /** Return the activities a user has performed */
  feedServiceSourceActivities: FeedServiceAggregateActivitiesResult;
  /** @deprecated WordPress configuration has changed, use "watchCategoryList" instead */
  filmCategoryList: Array<FilmCategory>;
  /** Get a GaiaGPS Track by ID. */
  gaiaTrack?: Maybe<GaiaTrackResponse>;
  getFaqById: Faq;
  getHikesCoord: Hikes;
  getSocialProfile?: Maybe<SocialProfile>;
  instructor: Instructor;
  /** Query Miso for latest content in Outside Watch */
  latestForWatch?: Maybe<Array<Maybe<ContentItem>>>;
  learnArchivePage: LearnArchivePage;
  learnCategories: Array<LearnCategory>;
  learnCategory: LearnCategory;
  learnCta: LearnCta;
  learnHomepage: LearnHomepage;
  learnMarketingModal: LearnMarketingModal;
  lesson: Lesson;
  lessons: Array<Lesson>;
  /** @deprecated Does not support DRM cache. Use "videoMedia" instead */
  mediaItem: MediaItem;
  /** @deprecated It should no longer be used as Piano is handling the access to the Media */
  mediaUrl: MediaUrl;
  /**
   * Query Miso as to get a recommendations of events
   * - latitude: user location latitude
   * - longitude: user location longitude
   * - radius: in miles by default
   */
  misoEvents?: Maybe<Array<Maybe<ContentItem>>>;
  /**
   * Query Miso for user recommendations using a user UUID.
   *
   * Returns a list of ContentItems
   */
  misoForUser?: Maybe<Array<Maybe<ContentItem>>>;
  /**
   * Query Miso for user recommendations using a user UUID.
   *
   * This is functionally backed by the same query as `misoForUser`.
   *
   * I think this was created in an attempt to move us closer to using Editorial as the sole source of
   * content.
   *
   * Returns a struct with items as a list of ContentItems.
   */
  misoForUserContentArticle?: Maybe<MisoForUserContentArticle>;
  /** Query Miso for Watch personalization */
  misoForWatch?: Maybe<Array<Maybe<ContentItem>>>;
  /** Query Miso for a list of ContentItem instances that are latest. */
  misoLatest?: Maybe<Array<Maybe<ContentItem>>>;
  /** Query Miso as a search engine using q as the user-entered query string. */
  misoSearch?: Maybe<Array<Maybe<ContentItem>>>;
  /**
   * Query Miso as a search engine using q as the user-entered query string.
   *
   * Returns items as a list of ContentReference entities.
   */
  misoSearchContentArticle?: Maybe<MisoSearchContentArticle>;
  /**
   * Query Miso for ContentItems similar to the URL provided.
   * - similarTo: target url is required
   */
  misoSimilar?: Maybe<Array<Maybe<ContentItem>>>;
  /** Query Miso for ContentReferences similar to the URL provided. */
  misoSimilarContentArticle?: Maybe<MisoSimilarContentArticle>;
  /**
   * Query Miso as to get a recommendations of trails and routes
   * - item_type: trail or route
   * - tf_activity_types: trailforks activity types (ex: trailrun, mtb, hike)
   * - latitude: user location latitude
   * - longitude: user location longitude
   * - radius: default unit is miles and default radius is 50 miles
   */
  misoTrails?: Maybe<Array<Maybe<ContentItem>>>;
  /** Query Miso for a list of ContentItem instances that are trending. */
  misoTrending?: Maybe<Array<Maybe<ContentItem>>>;
  /**
   * Query Miso for a list of ContentItem instances that are trending.
   *
   * Backed by the same function as misoTrending.
   */
  misoTrendingContentArticle?: Maybe<MisoTrendingContentArticle>;
  newsAndCreatorUpdate: NewsAndCreatorUpdates;
  offer?: Maybe<Offer>;
  offerCode?: Maybe<OfferCode>;
  offerRedemption?: Maybe<OfferRedemption>;
  offers: OfferPage;
  outsideioConfirmationModal: OutsideioConfirmationModal;
  outsideioCreatorsPage: OutsideioCreatorsPage;
  outsideioFaqPage: OutsideioFaqPage;
  outsideioHomepage: OutsideioHomepage;
  outsideioPrivacyPolicy: OutsideioPrivacyPolicy;
  outsideioRequestForm: RequestForm;
  outsideioSocialLinks: OutsideioSocialLinks;
  /** Test query to see if things are working. */
  pb_hello: Scalars['String'];
  pinkbikeArticle?: Maybe<PinkbikeArticleResponse>;
  playerUrl: PlayerUrl;
  playlist: Playlist;
  /** Query Miso for popular content in Outside Watch */
  popularForWatch?: Maybe<Array<Maybe<ContentItem>>>;
  project?: Maybe<Project>;
  projectBySlug?: Maybe<Project>;
  rivt__searchSocialProfiles?: Maybe<Array<Maybe<SocialProfile>>>;
  series: Series;
  set?: Maybe<Set>;
  slides: Array<Slide>;
  /**
   * Return a list of our ContentItem Sources.
   *
   * Sources are the names of our data sources, like BACKPACKER or JW_PLAYER.
   */
  sources?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Query for a list of tags.
   *
   * Tags are matched case-insensitive, where the tag starts with the supplied query.
   *
   * Arguments:
   *   q: a case-insensitive string
   *
   * Returns:
   *   A list of strings matching the query, sorted shortest to longest.
   */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get a Trailforks Photo by ID. */
  trailforksPhoto?: Maybe<TrailforksPhotoResponse>;
  /** Get a Trailforks Profile by ID. */
  trailforksProfile?: Maybe<TrailforksProfileResponse>;
  /** Get a Trailforks Report by ID. */
  trailforksReport?: Maybe<TrailforksReportResponse>;
  /** Get a Trailforks Ridelog by ID. */
  trailforksRidelog?: Maybe<TrailforksRidelogResponse>;
  /** Get a Trailforks Route by ID. */
  trailforksRoute?: Maybe<TrailforksRouteResponse>;
  /** Get a Trailforks Trail by ID. */
  trailforksTrail?: Maybe<TrailforksTrailResponse>;
  /**
   * Return a list of our ContentItem Types.
   *
   * Sources are the names of content types, like article or outside_films.
   */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get all comments for a container by a Container.content_id */
  ugcGetComments?: Maybe<Array<Maybe<UgcComment>>>;
  /**
   * Get a single post by it's ID.
   *
   * postId can be one of:
   *  - UUID
   *  - SQID
   *  - ID
   */
  ugcGetPost?: Maybe<UgcPost>;
  /** Get all posts given an external id (rivt uuid) */
  ugcGetPostsByExternalId?: Maybe<Array<Maybe<UgcPost>>>;
  /** Get signed upload URLs for the given filenames. */
  ugcGetPresignedPosts?: Maybe<Array<Maybe<UgcPresignedPost>>>;
  /** Get all report reasons given a content type */
  ugcGetReportReasons?: Maybe<Array<Maybe<UgcReportReason>>>;
  /**
   * Get athletic events, sorted by date
   *
   *
   * **Returns:**
   * IQueryable for the events
   */
  upcomingAthleticEvents?: Maybe<UpcomingAthleticEventsConnection>;
  upcomingCollection: UpcomingCollection;
  upcomingCollections: Array<UpcomingCollection>;
  upcomingEventsBuckets: Array<EventsBucket>;
  user?: Maybe<User>;
  userLessons: Array<Lesson>;
  videoMedia: MediaItem;
  /** @deprecated This query doesn't allow pagination. Use "watchPlaylist" instead */
  videoPlaylist: Playlist;
  videoPost: VideoPost;
  videoWatchTimes: VideoWatchTimeResponse;
  /** Gets 20 most recently watched but unfinished films/series */
  videosToContinue: Array<Maybe<MediaItem>>;
  watchBrands: Array<WatchBrand>;
  watchCTA: WatchCta;
  watchCategory?: Maybe<WatchCategory>;
  watchCategoryList?: Maybe<Array<WatchCategorySummary>>;
  watchHomepage: WatchHomepage;
  watchMarketingModal: WatchMarketingModal;
  watchPlaylist?: Maybe<WatchPlaylist>;
  watchSitemap: Sitemap;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryAr_ExternalEventArgs = {
  appType: ApplicationType;
  id: Scalars['Int'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryAllSeriesArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryAssetArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryAssetByPublicKeyArgs = {
  publicKey: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryAthleticEventArgs = {
  appType: ApplicationType;
  id: Scalars['Int'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryAthleticEventByUrlArgs = {
  url?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryAthleticEventCalendarArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchParameters?: InputMaybe<SearchEventQueryParamsInput>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryAthleticEventTypesArgs = {
  appType: ApplicationType;
  typePriorities?: InputMaybe<Array<Scalars['Int']>>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryBenefitArgs = {
  id: Scalars['BigInt'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryBookmarkListArgs = {
  data: GetBookmarkListInput;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryChatArgs = {
  q?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryCollectionArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryCollectionByAddressArgs = {
  address: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryCollectionBySlugArgs = {
  slug: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryCollectionsArgs = {
  filter?: InputMaybe<CollectionFilters>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<CollectionsSort>>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryCollectionsByStatusArgs = {
  pagination?: InputMaybe<PaginationArgs>;
  status: CollectionStatusQuery;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryContentArticleArgs = {
  url: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryContentArticleUpdatesReportArgs = {
  reportArguments: UpdateReportInput;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryContentItemArgs = {
  url?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryContentItemsPageArgs = {
  filter?: InputMaybe<ContentItemFilter>;
  mediaFilter?: InputMaybe<MediaFilter>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryContractArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryCourseArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
  isPreview?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryCreatorArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryCreatorsArgs = {
  pagination?: InputMaybe<PaginationArgs>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryDiscoveryFollowUsersArgs = {
  size?: InputMaybe<Scalars['Int']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryDiscoveryMisoFollowAuthorsArgs = {
  size?: InputMaybe<Scalars['Int']>;
  sourceFilters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryDiscoveryMisoFollowRecommendationsBrandsArgs = {
  size?: InputMaybe<Scalars['Int']>;
  sourceBoost?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryEcoCampaignArgs = {
  id: Scalars['BigInt'];
  imageId?: InputMaybe<Scalars['BigInt']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryEducationPostArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryEventsBucketArgs = {
  slug: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFaqArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeaturedAthleticEventsArgs = {
  appType: ApplicationType;
  findArea?: InputMaybe<EventDistanceFilterInput>;
  first?: Scalars['Int'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceActivityReactionsArgs = {
  activityId: Scalars['String'];
  lastReactionId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceAggregateActivitiesArgs = {
  actorCount?: InputMaybe<Scalars['Int']>;
  feedTarget?: InputMaybe<Scalars['String']>;
  lastActivityId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceAggregateActivityArgs = {
  activityId: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceBlockedUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceFollowRequestsArgs = {
  identity?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceFollowStatsArgs = {
  identity?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceFollowersArgs = {
  identity?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceFollowingArgs = {
  identity?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceNotificationFeedArgs = {
  lastActivityId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFeedServiceSourceActivitiesArgs = {
  identity: Scalars['String'];
  isSelf?: InputMaybe<Scalars['Boolean']>;
  lastActivityId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryFilmCategoryListArgs = {
  categoryName?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryGaiaTrackArgs = {
  trackId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryGetFaqByIdArgs = {
  id: Scalars['BigInt'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryGetHikesCoordArgs = {
  lat: Scalars['Float'];
  limit?: InputMaybe<Scalars['Float']>;
  lon: Scalars['Float'];
  max_ascent?: InputMaybe<Scalars['Float']>;
  max_difficulty?: InputMaybe<Scalars['Float']>;
  max_length?: InputMaybe<Scalars['Float']>;
  max_stars?: InputMaybe<Scalars['Float']>;
  min_ascent?: InputMaybe<Scalars['Float']>;
  min_difficulty?: InputMaybe<Scalars['Float']>;
  min_length?: InputMaybe<Scalars['Float']>;
  min_stars?: InputMaybe<Scalars['Float']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryGetSocialProfileArgs = {
  key?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryInstructorArgs = {
  id: Scalars['BigInt'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryLatestForWatchArgs = {
  category?: InputMaybe<Scalars['String']>;
  item_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  subcategory?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryLearnCategoryArgs = {
  category: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryLessonArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
  isPreview?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryLessonsArgs = {
  ids: Array<Scalars['BigInt']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMediaItemArgs = {
  drm?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMediaUrlArgs = {
  mediaId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoEventsArgs = {
  eventTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  radius?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoForUserArgs = {
  page?: InputMaybe<Scalars['Int']>;
  preference?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  size?: InputMaybe<Scalars['Int']>;
  sourceFilters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoForUserContentArticleArgs = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoForWatchArgs = {
  category?: InputMaybe<Scalars['String']>;
  item_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  subcategory?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoLatestArgs = {
  category?: InputMaybe<Scalars['String']>;
  item_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceFilters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tag?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoSearchArgs = {
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceFilters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoSearchContentArticleArgs = {
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoSimilarArgs = {
  memberOnly?: InputMaybe<Scalars['Int']>;
  similarTo?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceFilters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoSimilarContentArticleArgs = {
  memberOnly?: InputMaybe<Scalars['Int']>;
  similarTo?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceFilters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoTrailsArgs = {
  item_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Int']>;
  radius?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  tf_activity_types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tf_condition?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoTrendingArgs = {
  category?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  sourceFilters?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tag?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryMisoTrendingContentArticleArgs = {
  category?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  tag?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryNewsAndCreatorUpdateArgs = {
  id: Scalars['BigInt'];
  imageId?: InputMaybe<Scalars['BigInt']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryOfferArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryOfferCodeArgs = {
  offerRedemptionId: Scalars['Int'];
  signature: Scalars['String'];
  walletPublicKey: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryOfferRedemptionArgs = {
  id: Scalars['Int'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryOffersArgs = {
  filter?: InputMaybe<OfferFilters>;
  pagination?: InputMaybe<PaginationArgs>;
  publicKey: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryOutsideioCreatorsPageArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryOutsideioHomepageArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryOutsideioPrivacyPolicyArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryOutsideioRequestFormArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryPinkbikeArticleArgs = {
  articleId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryPlayerUrlArgs = {
  playerId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryPlaylistArgs = {
  filter: PlaylistFilter;
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryPopularForWatchArgs = {
  category?: InputMaybe<Scalars['String']>;
  item_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  subcategory?: InputMaybe<Scalars['String']>;
  time_period?: InputMaybe<Scalars['Int']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryProjectArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryProjectBySlugArgs = {
  slug: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryRivt__SearchSocialProfilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QuerySeriesArgs = {
  seriesId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QuerySetArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryTagsArgs = {
  q?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryTrailforksPhotoArgs = {
  photoId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryTrailforksProfileArgs = {
  profileId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryTrailforksReportArgs = {
  reportId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryTrailforksRidelogArgs = {
  ridelogId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryTrailforksRouteArgs = {
  routeId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryTrailforksTrailArgs = {
  trailId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryUgcGetCommentsArgs = {
  contentId: Scalars['String'];
  lastId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryUgcGetPostArgs = {
  postId: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryUgcGetPostsByExternalIdArgs = {
  externalId: Scalars['String'];
  lastId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryUgcGetPresignedPostsArgs = {
  filenames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryUgcGetReportReasonsArgs = {
  contentType: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryUpcomingAthleticEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  appType: ApplicationType;
  before?: InputMaybe<Scalars['String']>;
  findArea?: InputMaybe<EventDistanceFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryUpcomingCollectionArgs = {
  id: Scalars['BigInt'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryVideoMediaArgs = {
  id: Scalars['ID'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryVideoPlaylistArgs = {
  playlistType: PlaylistTypes;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryVideoPostArgs = {
  id: Scalars['Float'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryVideosToContinueArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryWatchCategoryArgs = {
  slug: Scalars['String'];
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryWatchHomepageArgs = {
  previewId?: InputMaybe<Scalars['ID']>;
};

/**
 * All initial entry points for reading the graph must be resolvers inside this type.
 * This does not mean they need to be resolvers in this file:
 * If the file is getting too big we should use partial classes to split it up
 * Current Partials:
 * Query.cs
 * CalendarQuery.cs
 */
export type QueryWatchPlaylistArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  offset?: InputMaybe<Scalars['Float']>;
  playlistType: PlaylistTypes;
};

export enum ReactionContentType {
  ContentItem = 'ContentItem',
  MediaItem = 'MediaItem'
}

/** Structure containing information about number of reactions to content */
export type ReactionCount = {
  __typename?: 'ReactionCount';
  /** Total number of reaction to the content recorded for all users. */
  totalReactions: Scalars['Int'];
  /** Number of reactions to the content recorded for the specific user. It returns null when user is not authenticated. */
  userReactions?: Maybe<Scalars['Int']>;
};

export type ReactionCountMap = {
  __typename?: 'ReactionCountMap';
  HighFive: ReactionCount;
};

/** Structure containing information about reactions to content */
export type ReactionEdge = {
  __typename?: 'ReactionEdge';
  contentType: ReactionContentType;
  contentUid: Scalars['String'];
  reactionCount: ReactionCount;
  reference: ReactionReference;
};

export enum ReactionName {
  HighFive = 'HighFive'
}

export type ReactionReference = ContentItem | MediaItem;

export type RecAuthorOutput = {
  __typename?: 'RecAuthorOutput';
  /** @deprecated please utilize SocialProfile fields */
  author_brands?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated please utilize SocialProfile fields */
  author_byline?: Maybe<Scalars['String']>;
  /** @deprecated please utilize SocialProfile fields */
  author_name?: Maybe<Scalars['String']>;
  /** @deprecated please utilize SocialProfile fields */
  author_profile_pic?: Maybe<Scalars['String']>;
  /** @deprecated please utilize SocialProfile fields */
  author_title?: Maybe<Scalars['String']>;
  socialProfile?: Maybe<SocialProfile>;
};

export type RecUserOutput = {
  __typename?: 'RecUserOutput';
  reason?: Maybe<Scalars['String']>;
  socialProfile?: Maybe<SocialProfile>;
  userUuid?: Maybe<Scalars['String']>;
};

export type Recipe = {
  __typename?: 'Recipe';
  additionalInfo?: Maybe<Scalars['JSON']>;
  createdDate: Scalars['DateTime'];
  id: Scalars['Float'];
  ingredients?: Maybe<Scalars['JSON']>;
  instructions: Scalars['String'];
  updatedDate: Scalars['DateTime'];
};

export type RedeemOfferInput = {
  assetPublicKey: Scalars['String'];
  offerId: Scalars['Float'];
  signature: Scalars['String'];
  walletPublicKey: Scalars['String'];
};

export type RedeemOfferOutput = {
  __typename?: 'RedeemOfferOutput';
  data?: Maybe<RedeemOfferResult>;
  success: Scalars['Boolean'];
};

export type RedeemOfferResult = OfferCode | OutsidePlusOfferResult;

export type RemoveBookmarkByUidInput = {
  contentType: BookmarkContentType;
  contentUid: Scalars['String'];
  listName: Scalars['String'];
};

export type RemoveBookmarkInput = {
  bookmarkId: Scalars['Int'];
  listName: Scalars['String'];
};

export type ReportTrail = {
  __typename?: 'ReportTrail';
  alias?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trailid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ReportUgc = {
  __typename?: 'ReportUgc';
  totalComments?: Maybe<Scalars['Int']>;
  totalHighfives?: Maybe<Scalars['Int']>;
  totalPhotos?: Maybe<Scalars['Int']>;
};

export type RequestForm = {
  __typename?: 'RequestForm';
  content?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  seo: OutsideioSeo;
  sideImage?: Maybe<GraphImage>;
  subheader?: Maybe<Scalars['String']>;
};

export type Resource = {
  __typename?: 'Resource';
  target?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type RidelogStats = {
  __typename?: 'RidelogStats';
  altClimb?: Maybe<Scalars['Int']>;
  altDescent?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  timeMoving?: Maybe<Scalars['Int']>;
};

export type RidelogTrails = {
  __typename?: 'RidelogTrails';
  altClimb?: Maybe<Scalars['Int']>;
  altDescent?: Maybe<Scalars['Float']>;
  difficulty?: Maybe<Scalars['Int']>;
  difficultyColor?: Maybe<Scalars['String']>;
  difficultyTitle?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Int']>;
  partial?: Maybe<Scalars['Int']>;
  pr?: Maybe<Scalars['Int']>;
  rankGlobal?: Maybe<Scalars['Int']>;
  rankPersonal?: Maybe<Scalars['Int']>;
  refnum?: Maybe<Scalars['String']>;
  reverse?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  trailid?: Maybe<Scalars['Int']>;
};

export type SaveLessonCompletionPayload = {
  __typename?: 'SaveLessonCompletionPayload';
  lesson?: Maybe<Lesson>;
  successful: Scalars['Boolean'];
  userId?: Maybe<Scalars['String']>;
};

export enum SearchEntryType {
  CompleteEvent = 'COMPLETE_EVENT',
  ExternalEvent = 'EXTERNAL_EVENT',
  OnlyEntry = 'ONLY_ENTRY',
  SubEntry = 'SUB_ENTRY'
}

/** Filters for event search queries. Separate fields are all ANDed together */
export type SearchEventQueryParamsInput = {
  /** Websites to get events from */
  appTypes?: InputMaybe<Array<ApplicationType>>;
  /**
   * If provided, the query will only return events that are in these states.
   * States are two-letter abbreviations such as "NY" or "BC".
   */
  eventStates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Event types to filter to - do not use if displaying more than one app type */
  eventTypes?: InputMaybe<Array<Scalars['Int']>>;
  /** If we are showing external events, what custom calendar(s) to use */
  externalEventCustomCalendarIDs?: InputMaybe<Array<Scalars['Int']>>;
  /** True to include external events in the search */
  includeExternalEvents?: InputMaybe<Scalars['Boolean']>;
  /** True if events should be past the registration open date (does not guarantee event is not full) */
  isRegistrationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Unit for category lengths. Required if MinLength or MaxLength are set */
  lengthUnit?: InputMaybe<EventSearchDistanceUnit>;
  /** If we are showing the map, the coordinates of the view port that will determine if an event is inside current map viewport */
  mapCoordinates?: InputMaybe<Array<InputMaybe<LatLongInput>>>;
  /** Latest start date of the event */
  maxDate?: InputMaybe<Scalars['DateTime']>;
  /** Maximum category length. Unit determined by LengthUnit */
  maxLength?: InputMaybe<Scalars['Int']>;
  /** Earliest start date of the event */
  minDate?: InputMaybe<Scalars['DateTime']>;
  /** Minimum category length. Unit determined by LengthUnit */
  minLength?: InputMaybe<Scalars['Int']>;
  /** Event promoter to limit events to. - do not use if displaying more than one app type */
  promoterId?: InputMaybe<Scalars['Int']>;
  /** Sanctioning bodies to filter events to. */
  sanctioningBodies?: InputMaybe<Array<Scalars['Int']>>;
  /** Search for text contained in the event name or event city */
  searchText?: InputMaybe<Scalars['String']>;
  /** How to sort the resulting events - always ascending */
  sortBy?: InputMaybe<EventSortOptions>;
  /**
   * If provided, the query will only return events that are in these states.
   * States are two-letter abbreviations such as "NY" or "BC".
   */
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Information about user location and filtering distance */
  userDistanceFilter?: InputMaybe<EventDistanceFilterInput>;
};

export type Season = {
  __typename?: 'Season';
  associatedSeriesId?: Maybe<Scalars['String']>;
  episodeCount: Scalars['Float'];
  episodes: Array<Episode>;
  seasonNumber: Scalars['Float'];
  trailer?: Maybe<MediaItem>;
};

export type SeasonProgress = {
  __typename?: 'SeasonProgress';
  episode: Scalars['Int'];
  episodeMedia: MediaItem;
  episodeProgress: VideoProgress;
  season: Scalars['Int'];
};

export type SeasonSlide = {
  __typename?: 'SeasonSlide';
  id: Scalars['BigInt'];
  image: Scalars['String'];
  imageAltTag: Scalars['String'];
  isContentFree: Scalars['Boolean'];
  optionalTitle?: Maybe<Scalars['String']>;
  postId: Scalars['BigInt'];
  seasonNumber: Scalars['Int'];
  series: Series;
  seriesId: Scalars['String'];
  type: HeroSlideType;
};

export enum SecondaryMarket {
  MagicEden = 'MAGIC_EDEN',
  Opensea = 'OPENSEA'
}

export type Series = {
  __typename?: 'Series';
  description: Scalars['String'];
  feedId: Scalars['String'];
  seasons: Array<Season>;
  seriesId: Scalars['ID'];
  title: Scalars['String'];
  trailer: MediaItem;
};

export type SeriesCount = {
  __typename?: 'SeriesCount';
  count: Scalars['Float'];
  type: SeriesCountType;
};

export enum SeriesCountType {
  Episodes = 'EPISODES',
  Seasons = 'SEASONS'
}

export type SeriesEpisodeProgressInput = {
  /** Field returned by MediaItem.mediaItemLink on episode asset */
  mediaItemLink: Scalars['String'];
  /** Position in seconds */
  position: Scalars['Float'];
  /** Field returned by MediaItem.seriesPlaylistId on series trailer or Series.feedid */
  seriesId: Scalars['String'];
  /** Media ID of series trailer asset */
  trailerId: Scalars['String'];
};

export type SeriesPlaylist = {
  __typename?: 'SeriesPlaylist';
  seriesId: Scalars['ID'];
};

export type SeriesProgress = {
  __typename?: 'SeriesProgress';
  episode: Scalars['Int'];
  episodeMedia: MediaItem;
  episodeProgress: VideoProgress;
  season: Scalars['Int'];
};

export type SeriesSlide = {
  __typename?: 'SeriesSlide';
  id: Scalars['BigInt'];
  image: Scalars['String'];
  imageAltTag: Scalars['String'];
  isContentFree: Scalars['Boolean'];
  optionalTitle?: Maybe<Scalars['String']>;
  postId: Scalars['BigInt'];
  series: Series;
  seriesId: Scalars['String'];
  type: HeroSlideType;
};

export type Set = {
  __typename?: 'Set';
  assets: AssetPage;
  collection: Collection;
  description?: Maybe<Scalars['String']>;
  endDatetime?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
  sortOrder: Scalars['Float'];
  startDatetime?: Maybe<Scalars['DateTime']>;
};

export type SetAssetsArgs = {
  filter?: InputMaybe<AssetFilters>;
  pagination?: InputMaybe<PaginationArgs>;
  sort?: InputMaybe<Array<AssetSort>>;
};

export type Sitemap = {
  __typename?: 'Sitemap';
  films: Array<MediaSummary>;
  replays: Array<MediaSummary>;
  series: Array<MediaSummary>;
  shorts: Array<MediaSummary>;
};

export type Slide = {
  __typename?: 'Slide';
  /** @deprecated Moved: use buttonText and imageLink. */
  button?: Maybe<Button>;
  buttonText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** @deprecated Removed from wordpress. */
  disclaimer?: Maybe<Scalars['String']>;
  episode?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  imageAltTag?: Maybe<Scalars['String']>;
  imageLink?: Maybe<Scalars['String']>;
  mediaId?: Maybe<Scalars['String']>;
  mediaItem?: Maybe<MediaItem>;
  mobileImage?: Maybe<Scalars['String']>;
  playlistId?: Maybe<Scalars['String']>;
  postId: Scalars['BigInt'];
  season?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Rivt's SocialProfile entity. */
export type SocialProfile = {
  __typename?: 'SocialProfile';
  authorBrands?: Maybe<Array<Maybe<SocialProfile>>>;
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  byline?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  followStats?: Maybe<FeedServiceFollowStatResult>;
  followStatus?: Maybe<Scalars['String']>;
  /** @deprecated Use isFollowing instead */
  following?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  /** @deprecated Use followStatus instead */
  isFollowing?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  privacySettings?: Maybe<SocialProfilePrivacySettings>;
  publicUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  trailforksProfile?: Maybe<TrailforksProfile>;
  type?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['ID']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SocialProfilePrivacySettings = {
  __typename?: 'SocialProfilePrivacySettings';
  postsVisibleTo?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  showBirthday?: Maybe<Scalars['Boolean']>;
  showDisplayName?: Maybe<Scalars['Boolean']>;
  showGender?: Maybe<Scalars['Boolean']>;
  showLocation?: Maybe<Scalars['Boolean']>;
};

export type SoftDeleteContentArticleInput = {
  url: Scalars['String'];
};

export type SoftDeleteContentArticlePayload = {
  __typename?: 'SoftDeleteContentArticlePayload';
  articleUrl?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['Float']>;
  galleryArticleId?: Maybe<Scalars['Float']>;
  gearId?: Maybe<Scalars['Float']>;
  podcastId?: Maybe<Scalars['Float']>;
  recipeId?: Maybe<Scalars['Float']>;
  videoId?: Maybe<Scalars['Float']>;
};

export type Source = {
  __typename?: 'Source';
  drm?: Maybe<DrmMetadata>;
  file: Scalars['String'];
  fileSize?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
};

export type StringResult = {
  __typename?: 'StringResult';
  value?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chat?: Maybe<ChatResult>;
  counter: Scalars['Int'];
};

export type SubscriptionChatArgs = {
  q?: InputMaybe<Scalars['String']>;
};

export type TableOfContentItem = {
  __typename?: 'TableOfContentItem';
  lessons?: Maybe<Array<Lesson>>;
  title: Scalars['String'];
};

export type Track = {
  __typename?: 'Track';
  file: Scalars['String'];
  kind: Scalars['String'];
};

export type TrailDifficulty = {
  __typename?: 'TrailDifficulty';
  ebike?: Maybe<Scalars['String']>;
  hike?: Maybe<Scalars['String']>;
  mtb?: Maybe<Scalars['String']>;
  trailrun?: Maybe<Scalars['String']>;
};

export type TrailGlobalRankScore = {
  __typename?: 'TrailGlobalRankScore';
  mtb?: Maybe<Scalars['Float']>;
};

export type TrailImages = {
  __typename?: 'TrailImages';
  alt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type TrailRating = {
  __typename?: 'TrailRating';
  ebike?: Maybe<Scalars['Int']>;
  hike?: Maybe<Scalars['Int']>;
  mtb?: Maybe<Scalars['Int']>;
  trailrun?: Maybe<Scalars['Int']>;
};

export type TrailUgc = {
  __typename?: 'TrailUgc';
  ebike?: Maybe<TrailUgcMeta>;
  hike?: Maybe<TrailUgcMeta>;
  mtb?: Maybe<TrailUgcMeta>;
  totalCheckins?: Maybe<Scalars['Int']>;
  totalComments?: Maybe<Scalars['Int']>;
  totalPhotos?: Maybe<Scalars['Int']>;
  totalReports?: Maybe<Scalars['Int']>;
  totalRidelogs?: Maybe<Scalars['Int']>;
  totalVideos?: Maybe<Scalars['Int']>;
  trailrun?: Maybe<TrailUgcMeta>;
};

export type TrailUgcMeta = {
  __typename?: 'TrailUgcMeta';
  totalCheckins?: Maybe<Scalars['Int']>;
  totalPhotos?: Maybe<Scalars['Int']>;
  totalReports?: Maybe<Scalars['Int']>;
  totalVideos?: Maybe<Scalars['Int']>;
};

export type TrailforksPhoto = {
  __typename?: 'TrailforksPhoto';
  created?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  reportid?: Maybe<Scalars['Int']>;
  thumbs?: Maybe<PhotoThumbs>;
  trail?: Maybe<PhotoTrail>;
  ts?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type TrailforksPhotoResponse = {
  __typename?: 'TrailforksPhotoResponse';
  photo?: Maybe<TrailforksPhoto>;
};

export type TrailforksProfile = {
  __typename?: 'TrailforksProfile';
  aboutme?: Maybe<Scalars['String']>;
  activitytype?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  karma?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  pro?: Maybe<Scalars['Int']>;
  profileDeeplink?: Maybe<Scalars['String']>;
  profileLink?: Maybe<Scalars['String']>;
  profilePublic?: Maybe<Scalars['Int']>;
  trust?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
  userimage?: Maybe<Scalars['String']>;
};

export type TrailforksProfileResponse = {
  __typename?: 'TrailforksProfileResponse';
  profile?: Maybe<TrailforksProfile>;
};

export type TrailforksReport = {
  __typename?: 'TrailforksReport';
  activitytypeAlias?: Maybe<Scalars['String']>;
  activitytypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  cityTitle?: Maybe<Scalars['String']>;
  conditionId?: Maybe<Scalars['Int']>;
  conditionText?: Maybe<Scalars['String']>;
  countryTitle?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  nid?: Maybe<Scalars['Int']>;
  private?: Maybe<Scalars['Int']>;
  provTitle?: Maybe<Scalars['String']>;
  regionTitle?: Maybe<Scalars['String']>;
  reportid?: Maybe<Scalars['Int']>;
  ridelogid?: Maybe<Scalars['Int']>;
  statusId?: Maybe<Scalars['Int']>;
  statusText?: Maybe<Scalars['String']>;
  trail?: Maybe<ReportTrail>;
  trailWork?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  ugc?: Maybe<ReportUgc>;
  url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type TrailforksReportResponse = {
  __typename?: 'TrailforksReportResponse';
  report?: Maybe<TrailforksReport>;
};

export type TrailforksRidelog = {
  __typename?: 'TrailforksRidelog';
  activitytypeAlias?: Maybe<Scalars['String']>;
  athletes?: Maybe<Scalars['String']>;
  badges?: Maybe<Array<Maybe<Scalars['String']>>>;
  cityTitle?: Maybe<Scalars['String']>;
  commute?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  photoUrl?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  public?: Maybe<Scalars['Int']>;
  race?: Maybe<Scalars['Int']>;
  regionTitle?: Maybe<Scalars['String']>;
  sensative?: Maybe<Scalars['Int']>;
  stats?: Maybe<RidelogStats>;
  strava?: Maybe<Scalars['Int']>;
  timeIso8601?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalAchievements?: Maybe<Scalars['Int']>;
  totalBadges?: Maybe<Scalars['Int']>;
  totalPhotos?: Maybe<Scalars['Int']>;
  totalSummits?: Maybe<Scalars['Int']>;
  totalTrails?: Maybe<Scalars['Int']>;
  trails?: Maybe<Array<Maybe<RidelogTrails>>>;
  userid?: Maybe<Scalars['Int']>;
};

export type TrailforksRidelogResponse = {
  __typename?: 'TrailforksRidelogResponse';
  ridelog?: Maybe<TrailforksRidelog>;
};

export type TrailforksRoute = {
  __typename?: 'TrailforksRoute';
  actAtv?: Maybe<Scalars['Int']>;
  actEbike?: Maybe<Scalars['Int']>;
  actHike?: Maybe<Scalars['Int']>;
  actHorse?: Maybe<Scalars['Int']>;
  actMoto?: Maybe<Scalars['Int']>;
  actMototrials?: Maybe<Scalars['Int']>;
  actMtb?: Maybe<Scalars['Int']>;
  actSkialpine?: Maybe<Scalars['Int']>;
  actSkibc?: Maybe<Scalars['Int']>;
  actSkixc?: Maybe<Scalars['Int']>;
  actSnowmobile?: Maybe<Scalars['Int']>;
  actSnowshoe?: Maybe<Scalars['Int']>;
  actTrailrun?: Maybe<Scalars['Int']>;
  activitytype?: Maybe<Scalars['Int']>;
  activitytypeAlias?: Maybe<Scalars['String']>;
  activitytypeInfo?: Maybe<ActivitytypeInfo>;
  activitytypes?: Maybe<Array<Maybe<Scalars['Int']>>>;
  aka?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['Int']>;
  bikepacking?: Maybe<Scalars['Int']>;
  biketype?: Maybe<Scalars['Int']>;
  ccteam?: Maybe<Scalars['Int']>;
  changed?: Maybe<Scalars['Int']>;
  cityTitle?: Maybe<Scalars['String']>;
  confirmid?: Maybe<Scalars['Int']>;
  countryTitle?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['Int']>;
  coverPhotoUrl?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  descriptionSnippet?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['Int']>;
  difficultyColor?: Maybe<Scalars['String']>;
  difficultyTitle?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['Int']>;
  directionsJson?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  downloadDisable?: Maybe<Scalars['Int']>;
  ebike?: Maybe<Scalars['Int']>;
  eventDate?: Maybe<Scalars['Int']>;
  eventDateAllday?: Maybe<Scalars['Int']>;
  eventDateLocal?: Maybe<Scalars['Int']>;
  eventTimezone?: Maybe<Scalars['String']>;
  faved?: Maybe<Scalars['Int']>;
  featured?: Maybe<Scalars['Int']>;
  globalRank?: Maybe<Scalars['Int']>;
  globalRankData?: Maybe<Scalars['String']>;
  globalRankScore?: Maybe<Scalars['Float']>;
  hidden?: Maybe<Scalars['Int']>;
  hideAssociation?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  imagemap1?: Maybe<Scalars['Int']>;
  imagemap2?: Maybe<Scalars['Int']>;
  imagemap3?: Maybe<Scalars['Int']>;
  lastCommentTs?: Maybe<Scalars['Int']>;
  lastTotalsTs?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['Float']>;
  lines?: Maybe<Scalars['String']>;
  location?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lockEditing?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Float']>;
  method?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  physicalRating?: Maybe<Scalars['Int']>;
  poi?: Maybe<Scalars['String']>;
  polygons?: Maybe<Scalars['String']>;
  popularity?: Maybe<Scalars['Int']>;
  postgresId?: Maybe<Scalars['Int']>;
  provAbv?: Maybe<Scalars['String']>;
  provRank?: Maybe<Scalars['Int']>;
  provTitle?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Int']>;
  race?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  rid?: Maybe<Scalars['Int']>;
  ridden?: Maybe<Scalars['Int']>;
  routeType?: Maybe<Scalars['Int']>;
  scheduled?: Maybe<Scalars['Int']>;
  scheduledOn?: Maybe<Scalars['Int']>;
  scheduledOnLocal?: Maybe<Scalars['Int']>;
  scheduledTimezone?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  searchTerms?: Maybe<Scalars['String']>;
  seasonType?: Maybe<Scalars['Int']>;
  sections?: Maybe<Scalars['String']>;
  sensative?: Maybe<Scalars['Int']>;
  sourceLink?: Maybe<Scalars['String']>;
  sourceText?: Maybe<Scalars['String']>;
  staticMapUrl?: Maybe<Scalars['String']>;
  stravaSegment?: Maybe<Scalars['Int']>;
  stravaSegmentReverse?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  totalCheckins?: Maybe<Scalars['Int']>;
  totalComments?: Maybe<Scalars['Int']>;
  totalLists?: Maybe<Scalars['Int']>;
  totalPhotos?: Maybe<Scalars['Int']>;
  totalPhotosLinked?: Maybe<Scalars['Int']>;
  totalPoi?: Maybe<Scalars['Int']>;
  totalRidelogs?: Maybe<Scalars['Int']>;
  totalSupporters?: Maybe<Scalars['Int']>;
  totalTrails?: Maybe<Scalars['Int']>;
  totalVideos?: Maybe<Scalars['Int']>;
  trailAssociation?: Maybe<Scalars['Int']>;
  trailPopularityScore?: Maybe<Scalars['Int']>;
  trailTransparency?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  unsanctioned?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
  vid?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
  votes?: Maybe<Scalars['Int']>;
};

export type TrailforksRouteResponse = {
  __typename?: 'TrailforksRouteResponse';
  route?: Maybe<TrailforksRoute>;
};

export type TrailforksTrail = {
  __typename?: 'TrailforksTrail';
  activityTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  alpine?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Int']>;
  condition?: Maybe<Scalars['String']>;
  connector?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  coverPhoto?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<TrailDifficulty>;
  difficultyColor?: Maybe<TrailDifficulty>;
  direction?: Maybe<Scalars['String']>;
  familyFriendly?: Maybe<Scalars['Int']>;
  globalRankScore?: Maybe<TrailGlobalRankScore>;
  hidden?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<TrailImages>>>;
  licenseRequired?: Maybe<Scalars['Int']>;
  physicalRating?: Maybe<Scalars['String']>;
  prov?: Maybe<Scalars['String']>;
  provRank?: Maybe<TrailRating>;
  rating?: Maybe<TrailRating>;
  restrictedAccess?: Maybe<Scalars['Int']>;
  sacScale?: Maybe<Scalars['String']>;
  staticmapImage1?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trailid?: Maybe<Scalars['Int']>;
  trailtype?: Maybe<Scalars['String']>;
  ugc?: Maybe<TrailUgc>;
  unsanctioned?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
};

export type TrailforksTrailResponse = {
  __typename?: 'TrailforksTrailResponse';
  trail?: Maybe<TrailforksTrail>;
};

export type TrailmapSection = {
  __typename?: 'TrailmapSection';
  endText: Scalars['String'];
  image: GraphImage;
  milestones: Array<Milestone>;
  startText: Scalars['String'];
};

/** User Generated Comment. */
export type UgcComment = {
  __typename?: 'UgcComment';
  container: UgcContainer;
  content: Scalars['JSON'];
  createdTs: Scalars['DateTime'];
  id: Scalars['ID'];
  socialProfile?: Maybe<SocialProfile>;
  status: Scalars['String'];
  userIdentity: UgcUserIdentity;
};

/**
 * Container - Comments are attached to this.
 *
 * Entities that have comments can reference them with:
 *
 * {
 *   __typename: UgcContainer,
 *   contentId:'<entity>:<id>'
 * }
 */
export type UgcContainer = {
  __typename?: 'UgcContainer';
  commentCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Maybe<UgcComment>>>;
  contentId: Scalars['ID'];
  createdTs: Scalars['DateTime'];
  id: Scalars['ID'];
  status: Scalars['String'];
  uuid: Scalars['String'];
};

export type UgcCreateCommentInput = {
  /** The ID of the container to attach the comment to. */
  containerId: Scalars['ID'];
  /** The comment. */
  content: Scalars['String'];
};

export type UgcCreatePostInput = {
  /** This is the text of the post. */
  content: Scalars['String'];
  /** Optional list of uploadFilenames to associate with the post. */
  imageFilenames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The brand origin of the post.  E.g., BACKPACKER. */
  originReference?: InputMaybe<Scalars['String']>;
  /**
   * Set the privacyLevel of a post.
   *
   * Options:
   *   - public
   *   - private
   *   - followers
   */
  privacyLevel?: InputMaybe<Scalars['String']>;
};

export type UgcDeleteCommentInput = {
  /** The Primary Key of the comment to delete. */
  id: Scalars['ID'];
};

export type UgcDeletePostInput = {
  /** The Primary Key of the post to delete. */
  id: Scalars['ID'];
};

/** User Generated Post. */
export type UgcPost = {
  __typename?: 'UgcPost';
  /** The content of the post. */
  content?: Maybe<UgcPostContent>;
  /** Created timestamp. */
  createdTs: Scalars['DateTime'];
  /**
   * The Feed Service Activity associated with the post.
   * @deprecated Use feedServiceAggregateActivity instead.
   */
  feedServiceActivity?: Maybe<FeedServiceActivity>;
  /** The Feed Service Aggregate Activity associated with the post. */
  feedServiceAggregateActivity?: Maybe<FeedServiceAggregateActivity>;
  /** The DB ID of the post. */
  id: Scalars['ID'];
  /** The images associated with the post. */
  images?: Maybe<Array<Maybe<UgcPostImage>>>;
  /** The brand origin of the post. */
  originReference?: Maybe<Scalars['String']>;
  /**
   * The privacy level of the post.
   *
   * Options:
   *   - public
   *   - private
   *   - followers
   */
  privacyLevel: Scalars['String'];
  /** A short unique identifier, very You Tube like. */
  sqid: Scalars['String'];
  /** The status of the post. */
  status: Scalars['String'];
  /** Updated timestamp when DB record last touched. */
  updatedTs: Scalars['DateTime'];
  /** The external ID of the post. */
  userIdentity: UgcUserIdentity;
  /** UUID for external reference. */
  uuid?: Maybe<Scalars['String']>;
};

/** User Generated Post Content. */
export type UgcPostContent = {
  __typename?: 'UgcPostContent';
  /** The content of the post. */
  content: Scalars['JSON'];
  createdTs: Scalars['DateTime'];
  id: Scalars['ID'];
};

/** User Generated Post Image. */
export type UgcPostImage = {
  __typename?: 'UgcPostImage';
  createdTs: Scalars['DateTime'];
  foo?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The Signed URL to the public image. */
  publicUrl?: Maybe<Scalars['String']>;
  /** Status of the image record. */
  status: Scalars['String'];
  updatedTs: Scalars['DateTime'];
  /** The original upload filename. */
  uploadFilename?: Maybe<Scalars['String']>;
  /**
   * The Signed URL to the uploaded image.  Not needed once we have image processing in place.
   * @deprecated Use uploadUrl instead.
   */
  uploadFilenameUrl?: Maybe<Scalars['String']>;
  /** The Signed URL to the uploaded image. */
  uploadUrl?: Maybe<Scalars['String']>;
};

/**
 * Struct to hold signed upload url data.
 *
 * Used in ugcGetSignedUploadUrls query.
 */
export type UgcPresignedPost = {
  __typename?: 'UgcPresignedPost';
  /** Fields to include in the POST. */
  fields?: Maybe<Scalars['JSON']>;
  /** The filename of the file to be uploaded. */
  filename: Scalars['String'];
  /** The generated filename to assigned by the server. */
  uploadFilename: Scalars['String'];
  /** The signed upload URL for the file. */
  url: Scalars['String'];
};

export type UgcReportCommentInput = {
  /** The ID of the comment to report */
  id: Scalars['ID'];
  /** The reason for reporting the comment. */
  reason: Scalars['String'];
  /** Details of the reason for reporting the comment. */
  reasonDetails: Scalars['String'];
};

export type UgcReportPostInput = {
  /** The ID of the post to report. */
  id: Scalars['ID'];
  /** The reason for reporting the post. */
  reason: Scalars['String'];
  /** Details of the reason for reporting the post. */
  reasonDetails: Scalars['String'];
};

/** Moderation Report Reason. */
export type UgcReportReason = {
  __typename?: 'UgcReportReason';
  reasonDescription?: Maybe<Scalars['String']>;
  reportReason: Scalars['String'];
};

export type UgcReportUserInput = {
  /** The externalID of the user to report. */
  externalId: Scalars['String'];
  /** The reason for reporting the user. */
  reason: Scalars['String'];
  /** Details of the reason for reporting the user. */
  reasonDetails: Scalars['String'];
};

/** User Generated User Identity. */
export type UgcUserIdentity = {
  __typename?: 'UgcUserIdentity';
  createdTs: Scalars['DateTime'];
  externalId: Scalars['ID'];
  id: Scalars['ID'];
};

/** A connection to a list of items. */
export type UpcomingAthleticEventsConnection = {
  __typename?: 'UpcomingAthleticEventsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UpcomingAthleticEventsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<AthleticEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UpcomingAthleticEventsEdge = {
  __typename?: 'UpcomingAthleticEventsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AthleticEvent>;
};

export type UpcomingCollection = {
  __typename?: 'UpcomingCollection';
  description: Scalars['String'];
  id: Scalars['BigInt'];
  image: GraphImage;
  title: Scalars['String'];
};

export type UpdateContentArticleStatusInput = {
  postStatus: Scalars['String'];
  url: Scalars['String'];
};

export type UpdateContentArticleStatusPayload = {
  __typename?: 'UpdateContentArticleStatusPayload';
  article?: Maybe<ContentArticle>;
};

export type UpdateReportInput = {
  cursor?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  modificationDate: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  rivt_key?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  stripe_customer_id?: Maybe<Scalars['String']>;
};

export type Video = {
  __typename?: 'Video';
  createdDate: Scalars['DateTime'];
  disablePrerollAds: Scalars['Boolean'];
  id: Scalars['Float'];
  mediaId: Scalars['String'];
  updatedDate: Scalars['DateTime'];
  video?: Maybe<MediaItem>;
  videoPlatform: Scalars['String'];
};

export type VideoPost = {
  __typename?: 'VideoPost';
  id?: Maybe<Scalars['BigInt']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  videoPostMetadata?: Maybe<Array<VideoPostMeta>>;
};

export type VideoPostVideoPostMetadataArgs = {
  keys: Array<Scalars['String']>;
};

export type VideoPostMeta = {
  __typename?: 'VideoPostMeta';
  id: Scalars['BigInt'];
  key?: Maybe<Scalars['String']>;
  postId: Scalars['BigInt'];
  value?: Maybe<Scalars['String']>;
};

export type VideoProgress = {
  __typename?: 'VideoProgress';
  isFinished: Scalars['Boolean'];
  progress: Scalars['Float'];
  timeLeft: Scalars['Float'];
  timestamp: Scalars['Float'];
};

export type VideoProgressInput = {
  /** Field returned by MediaItem.mediaItemLink on film asset */
  mediaItemLink: Scalars['String'];
  /** Position in seconds */
  position: Scalars['Float'];
  /** Media ID of trailer asset. It should always be provided if trailer exist */
  trailerId?: InputMaybe<Scalars['String']>;
};

export type VideoProgressResponse = {
  __typename?: 'VideoProgressResponse';
  message: Scalars['String'];
};

export type VideoWatchTimeInput = {
  mediaItemLink: Scalars['String'];
  watchTimeEnd: Scalars['Int'];
  watchTimeStart: Scalars['Int'];
};

export type VideoWatchTimeResponse = {
  __typename?: 'VideoWatchTimeResponse';
  free: Scalars['Int'];
  locked: Scalars['Int'];
  meter: Scalars['Int'];
};

export type WatchBrand = {
  __typename?: 'WatchBrand';
  id: Scalars['BigInt'];
  link?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type WatchCta = {
  __typename?: 'WatchCTA';
  buttonText: Scalars['String'];
  description: Scalars['String'];
  headline: Scalars['String'];
  posters?: Maybe<Array<Poster>>;
  priceDisclaimer?: Maybe<Scalars['String']>;
  priceDiscount?: Maybe<Scalars['String']>;
};

export type WatchCategory = {
  __typename?: 'WatchCategory';
  misoReference: Scalars['String'];
  playlist: Playlist;
  playlistId: Scalars['String'];
  seo?: Maybe<WatchSeo>;
  slug: Scalars['ID'];
  subcategories: Array<WatchSubcategory>;
  title: Scalars['String'];
  type: CategoryType;
};

export type WatchCategorySummary = {
  __typename?: 'WatchCategorySummary';
  slug: Scalars['ID'];
  title: Scalars['String'];
  type: CategoryType;
};

export type WatchHomepage = {
  __typename?: 'WatchHomepage';
  brands?: Maybe<Array<WatchBrand>>;
  description?: Maybe<Scalars['String']>;
  heroSlideshow: Array<HeroSlide>;
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['BigInt']>;
  seo?: Maybe<WatchSeo>;
  /** @deprecated WordPress configuration has changed, use "heroSlideshow" instead */
  slideshow?: Maybe<Array<Slide>>;
  title: Scalars['String'];
};

export type WatchMarketingModal = {
  __typename?: 'WatchMarketingModal';
  benefitsList?: Maybe<Array<Scalars['String']>>;
  detailsLink?: Maybe<Scalars['String']>;
  detailsText?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  discountPrice?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceDuration?: Maybe<Scalars['String']>;
};

export type WatchPlaylist = {
  __typename?: 'WatchPlaylist';
  feedId: Scalars['ID'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  playlist?: Maybe<Playlist>;
};

export type WatchSeo = {
  __typename?: 'WatchSeo';
  description: Scalars['String'];
  modifiedTime: Scalars['DateTimeISO'];
  ogDescription: Scalars['String'];
  ogImage?: Maybe<Scalars['String']>;
  ogImageHeight?: Maybe<Scalars['Int']>;
  ogImageWidth?: Maybe<Scalars['Int']>;
  ogTitle: Scalars['String'];
  publishedTime: Scalars['DateTimeISO'];
  title: Scalars['String'];
};

export type WatchSubcategory = {
  __typename?: 'WatchSubcategory';
  misoReference: Scalars['String'];
  /** Available only on the FIS category type */
  playlist?: Maybe<Playlist>;
  slug: Scalars['ID'];
  title: Scalars['String'];
};

export enum Asset_List_Sort_Keys {
  Name = 'name'
}

export enum Blockchain {
  Ethereum = 'ethereum',
  Solana = 'solana'
}

export enum Collection_List_Sort_Keys {
  StartDate = 'startDate'
}

export enum Sort_Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}
