import type { HTMLProps } from 'react';
import { forwardRef } from 'react';
import type { PianoMountingId } from 'helpers/types/piano';

type Props = Omit<HTMLProps<HTMLDivElement>, 'id'> & {
  id: PianoMountingId;
};

const PianoMountingPoint = forwardRef<HTMLDivElement, Props>(
  ({ id, ...props }, ref) => <div ref={ref} id={id} {...props} />
);

export default PianoMountingPoint;
