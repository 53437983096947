import { isMutableRefObject } from 'helpers/utils/typeGuards/isMutableRefObject';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  enterFullscreen,
  exitFullscreen,
  getFullscreenModeStatus
} from 'helpers/utils/fullscreenPolyfill';

/*
  This custom hook returns custom fullscreen mode toggle,
  so we can control on which node requestFullscreen will be called
  we use that to override native JWPlayer requestFullscreen and point our custom div wrapper with VideoControls component.
  The fullScreenContainerRef should be placed on a container which one want to call fullscreen request on.
*/

export const useCustomFullscreen = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isFullscreenMode, setFullscreenModeStatus] = useState(false);

  const onFullscreenOn = useCallback(() => {
    if (!isMutableRefObject<HTMLDivElement>(containerRef)) return;
    enterFullscreen(containerRef.current);
    setFullscreenModeStatus(false);
  }, [containerRef]);

  const toggleFullScreen = useCallback(() => {
    const isFullscreenModeOn = getFullscreenModeStatus();
    if (isFullscreenModeOn) {
      exitFullscreen();
    } else {
      onFullscreenOn();
    }
    setFullscreenModeStatus(
      (previousFullscreenMode) => !previousFullscreenMode
    );
  }, [onFullscreenOn]);

  const disableFullscreen = useCallback(() => {
    setFullscreenModeStatus(false);
  }, []);

  useEffect(() => {
    const handleFullscreenOff = () => {
      const isFullscreenModeOn = getFullscreenModeStatus();
      if (!isFullscreenModeOn) {
        setFullscreenModeStatus(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenOff);

    return () =>
      document.removeEventListener('fullscreenchange', handleFullscreenOff);
  }, []);

  return {
    containerRef,
    disableFullscreen,
    isFullscreenMode,
    toggleFullScreen
  };
};
