import type { ReactNode } from 'react';
import PageLoader from 'components/01-atoms/PageLoader';
import { useDrmVideo, useMediaItem } from 'apis/mediaItem/queries';
import { useRouter } from 'next/router';
import { useAuthService } from '@pocketoutdoormedia/auth-state-service';

import { ErrorState } from '../EnhancedVideoPlayer/ErrorState';
import ComposedDrmVideoPlayer from '../ComposedDrmVideoPlayer';
import { MeterFlow } from '../../../helpers/types/jwplayer-subgraph';
import { usePortalRenderer } from '../../../helpers/hooks/usePortalRenderer';

type Props = {
  id: string;
  onBack?: VoidFunction;
};

const Container = ({ children }: { children: ReactNode }) => (
  <div className="fixed w-screen h-screen z-50 top-0 bg-black grid place-items-center">
    {children}
  </div>
);

const DrmVideoGate = ({ id, onBack }: Props) => {
  const router = useRouter();
  const { unmountPortal } = usePortalRenderer();

  const { isLoading: isUserStateLoading, userState } = useAuthService();
  // todo: find a better way for fetching mediaItem and get rid of cascading queries.
  const {
    data: mediaItemData,
    isError: isMediaItemError,
    isLoading: isMediaItemLoading
  } = useMediaItem({ id });

  const results = useDrmVideo({
    enabled:
      userState.isAuthenticated &&
      (userState.userData.isSubscriber ||
        mediaItemData?.mediaItem.meterFlow === MeterFlow.Meter),
    id
  });

  const isLoading =
    isUserStateLoading ||
    results.some((result) => result.isLoading) ||
    isMediaItemLoading;
  const isError = results.some((result) => result.isError) || isMediaItemError;

  if (isLoading)
    return (
      <Container>
        <PageLoader />
      </Container>
    );

  if (isError)
    return (
      <Container>
        <ErrorState onClose={onBack} />
      </Container>
    );

  const [mediaItemResult, playerUrlResult] = results;
  const mediaItem = mediaItemResult.data?.mediaItem || mediaItemData.mediaItem;
  const playerUrl = playerUrlResult.data?.playerUrl.url;

  if (!mediaItem.meterFlow) {
    router.push('/500').then(unmountPortal);
    return null;
  }

  return (
    <ComposedDrmVideoPlayer
      mediaItem={mediaItem}
      onBack={onBack}
      playerScript={playerUrl}
    />
  );
};

export default DrmVideoGate;
