import type { ReactNode } from 'react';
import type { BackgroundVariant } from 'components/04-templates/PianoModals/modalPropTypes';

import { Layout } from './Layout';
import { Heading, Price, SubHeading, Text } from './Typography';
import { PrimaryButton, SecondaryButton } from './Buttons';
import { Agreements, AgreementsReminder, SubscriptionSelector } from './Inputs';

type Props = {
  backgroundVariant?: BackgroundVariant;
  children: ReactNode;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
  slogan?: string;
};

const PianoModal = ({
  backgroundVariant,
  children,
  onBack,
  onClose,
  slogan
}: Props) => (
  <Layout
    backgroundVariant={backgroundVariant}
    onBack={onBack}
    onClose={onClose}
    slogan={slogan}
  >
    {children}
  </Layout>
);

PianoModal.Heading = Heading;
PianoModal.SubHeading = SubHeading;
PianoModal.Text = Text;
PianoModal.PrimaryButton = PrimaryButton;
PianoModal.SecondaryButton = SecondaryButton;
PianoModal.RegistrationAgreements = Agreements;
PianoModal.AgreementsReminder = AgreementsReminder;
PianoModal.SubscriptionSelector = SubscriptionSelector;
PianoModal.Price = Price;

export default PianoModal;
