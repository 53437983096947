import type { LoginModalPropTypes } from '../PianoModals';
import { LoginModal } from '../PianoModals';

import type { FlowVariant } from './types';

interface Props extends Partial<LoginModalPropTypes> {
  variant: FlowVariant;
}

const LoginStep = ({ variant, ...props }: Props) => {
  switch (variant) {
    case 'locked':
      return (
        <LoginModal
          backgroundVariant="2"
          heading="Sign in to your Outside Account"
          subHeading="Access only with Outside+ subscription"
          {...props}
        />
      );
    case 'partially-locked':
      return (
        <LoginModal
          backgroundVariant="2"
          heading="Sign in to your Outside Account"
          subHeading="Free access with Outside account"
          {...props}
        />
      );
    case 'free':
      return (
        <LoginModal
          backgroundVariant="2"
          heading="Sign in to your Outside Account"
          {...props}
        />
      );
    default:
      return null;
  }
};

export default LoginStep;
