import type { RegistrationModalValues } from '../PianoModals';
import { RegistrationModal } from '../PianoModals';
import type { ModalPropTypes } from '../PianoModals/modalPropTypes';

import type { FlowVariant } from './types';

interface Props extends Partial<ModalPropTypes<RegistrationModalValues>> {
  variant: FlowVariant;
}

const RegistrationStep = ({ variant, ...props }: Props) => (
  <RegistrationModal
    backgroundVariant={variant === 'free' ? '2' : '1'}
    {...props}
  />
);

export default RegistrationStep;
