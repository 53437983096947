import type { ClassValue } from 'clsx';
import clsx from 'clsx';

export type Strength = 0 | 1 | 2 | 3;

type Props = {
  className?: ClassValue;
  strength?: Strength;
};

const STRENGTH_LABEL = {
  0: 'Strength',
  1: 'Weak',
  2: 'Medium',
  3: 'Strong'
} as const;

const STRENGTH_TEXT_COLOR = {
  0: 'text-darker-gray',
  1: 'text-error',
  2: 'text-yellow-500',
  3: 'text-green-500'
} as const;

const STRENGTH_BACKGROUND_COLOR = {
  0: 'bg-darker-gray',
  1: 'bg-error',
  2: 'bg-yellow-500',
  3: 'bg-green-500'
} as const;

const PasswordStrengthBar = ({ className, strength = 0 }: Props) => (
  <div className={clsx(className, 'flex items-center gap-1')}>
    <span
      className={clsx(
        'text-[10px] leading-4 min-w-[48px] text-center',
        STRENGTH_TEXT_COLOR[strength]
      )}
    >
      {STRENGTH_LABEL[strength]}
    </span>
    <div className="flex gap-1 w-full">
      {Array.from({ length: 3 }, (_, index) => {
        const isFilled = index < strength;
        const barColor = isFilled
          ? STRENGTH_BACKGROUND_COLOR[strength]
          : STRENGTH_BACKGROUND_COLOR['0'];

        return (
          <span key={index} className={clsx('rounded w-full h-1', barColor)} />
        );
      })}
    </div>
  </div>
);

export default PasswordStrengthBar;
