import clsx from 'clsx';
import type { InputHTMLAttributes, LegacyRef, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';

export type DefaultProps = {
  additionalComponent?: ReactNode;
  className?: string;
  error?: string;
  hasIcon?: boolean;
  icon?: ReactNode;
  id?: string;
  inputRef?: Ref<HTMLInputElement>;
  label?: string;
  placeholder?: string;
  size?: 'lg' | 'md';
  type?: string;
};

export type InputProps = DefaultProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

const InputElement = (
  {
    additionalComponent,
    className,
    error,
    hasIcon = false,
    icon,
    id,
    label,
    placeholder,
    role,
    size = 'lg',
    type = 'text',
    ...props
  }: InputProps,
  ref: LegacyRef<HTMLInputElement>
) => {
  const isLarge = size === 'lg';
  const isMedium = size === 'md';
  const hasError = Boolean(error);

  return (
    <div className={clsx('w-full text-left', className)}>
      <div className="relative">
        <input
          {...props}
          ref={ref}
          className={clsx(
            'w-full appearance-none rounded-lg border bg-transparent px-[15px] text-16 leading-6 text-white outline-none placeholder:text-subtle disabled:bg-transparent',
            {
              'border-[#757575] focus:border-teal-600 focus:ring focus:ring-blue-800':
                !hasError,
              'border-red-500 ring-1 ring-red-500 ring-offset-0': hasError,
              'peer py-[15px]': isLarge,
              'pr-12': hasIcon,
              'py-[9px]': isMedium
            }
          )}
          id={id}
          placeholder={label ? ' ' : placeholder}
          role={role || 'textbox'}
          type={type}
        />
        {label && isLarge && (
          <label
            className={clsx(
              'pointer-events-none absolute left-4 top-1 translate-y-0 text-[10px] leading-4 text-subtle duration-[230ms] peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-16 peer-placeholder-shown:leading-6 peer-autofill:top-1 peer-autofill:translate-y-0 peer-autofill:text-[10px] peer-autofill:leading-4 peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-[10px] peer-focus:leading-4'
            )}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {icon && (
          <div className="absolute right-4 top-1/2 h-6 w-6 -translate-y-1/2 text-placeholder">
            {icon}
          </div>
        )}
      </div>
      {additionalComponent && <div className="mt-2">{additionalComponent}</div>}
      {error && <div className="mt-2 text-12 text-red-500">{error}</div>}
    </div>
  );
};

export default forwardRef<HTMLInputElement, InputProps>(InputElement);
