import { VIDEO_PLAYER_ID } from 'helpers/constants/jwplayer';
import calculateSeekTo from 'helpers/utils/calculateSeekTo';
import useSaveVideoProgress from 'apis/mediaItem/hooks';
import FallbackErrorPlayer from 'components/02-molecules/FallbackErrorPlayer';
import { useCallback } from 'react';
import { useRouter } from 'next/router';

import EnhancedVideoPlayer from '../EnhancedVideoPlayer';
import type { MediaItem } from '../../../helpers/types/jwplayer-subgraph';
import { getMediaPlaylist } from '../../../helpers/utils/media/getMediaPlaylist';
import ErrorBoundary from '../ErrorBoundary';
import { removeQueryParams } from '../../../helpers/utils/url/removeQueryParams';

type Props = {
  mediaItem: MediaItem;
  onBack?: VoidFunction;
};

const VideoGate = ({ mediaItem, onBack }: Props) => {
  const router = useRouter();
  const mediaItemToPlay = mediaItem.filmMediaItem || mediaItem;
  const { creditTimestamp } = mediaItemToPlay;

  const seekTo = calculateSeekTo(mediaItemToPlay.userProgress);

  const mediaItemLink = getMediaPlaylist(mediaItemToPlay.mediaId);

  const { saveProgress } = useSaveVideoProgress(mediaItemLink, null);

  const handleOnTime = ({ currentTime }: jwplayer.TimeParam) => {
    if (creditTimestamp && currentTime > creditTimestamp) {
      saveProgress(creditTimestamp);
    } else {
      saveProgress(currentTime);
    }
  };

  const handleOnBackWithUrlCleanup = useCallback(() => {
    onBack?.();
    removeQueryParams({
      paramsToRemove: ['mode'],
      router,
      shallow: true
    });
  }, [onBack, router]);

  return (
    <ErrorBoundary
      errorDetails={{
        location: 'VideoGate player'
      }}
      fallbackComponent={
        <FallbackErrorPlayer
          onBack={handleOnBackWithUrlCleanup}
          ratio="fullscreen"
        />
      }
      type="fallback"
    >
      <EnhancedVideoPlayer
        closeOnOneHundredPercent
        contentType="FILM"
        isSticky
        mediaItem={mediaItemToPlay}
        onBack={handleOnBackWithUrlCleanup}
        onTime={handleOnTime}
        playerId={VIDEO_PLAYER_ID}
        playlist={mediaItemLink}
        seekTo={seekTo}
        shouldUsePiano
      />
    </ErrorBoundary>
  );
};

export default VideoGate;
