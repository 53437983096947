import type { EpisodeMeta, MeterFlow } from 'helpers/types/jwplayer-subgraph';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { piano } from 'lib/piano';
import type { PianoContentType } from 'helpers/types/piano';
import { removeQueryParams } from 'helpers/utils/url/removeQueryParams';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuthService } from '@pocketoutdoormedia/auth-state-service';
import { useUserProfile } from 'apis/user-auth/queries';

type UsePianoFunnelProps = {
  contentType?: PianoContentType;
  enabled?: boolean;
  episodeMeta?: EpisodeMeta;
  meterFlow?: MeterFlow;
};

export const usePianoExperience = ({
  contentType,
  enabled,
  episodeMeta,
  meterFlow
}: UsePianoFunnelProps) => {
  const { userState } = useAuthService();
  const rivtToken = userState.isAuthenticated ? userState.rivtToken : null;
  const { data: userProfile, fetchStatus } = useUserProfile(rivtToken);
  const router = useRouter();
  const firstSignIn = router.query.firstSignIn === 'true';
  const { offerTrial } = useFlags();
  const ldClient = useLDClient();

  useEffect(() => {
    if (!enabled) return;
    piano.setCustomVariables({
      contentType,
      episodeNumber: episodeMeta?.episode ?? null,
      firstSignIn,
      meterFlow,
      trialRedeemed: offerTrial
        ? userProfile?.membership.trial_redeemed
        : undefined
    });

    // eslint-disable-next-line consistent-return
    return () => piano.removeCustomVariables();
  }, [
    enabled,
    meterFlow,
    contentType,
    episodeMeta?.episode,
    offerTrial,
    userProfile?.membership.trial_redeemed,
    firstSignIn
  ]);

  useEffect(() => {
    if (!enabled || fetchStatus !== 'idle') return;

    ldClient.waitUntilReady().then(() => {
      if (firstSignIn) {
        setTimeout(piano.executeExperience, 2000);
        setTimeout(() => {
          removeQueryParams({
            paramsToRemove: ['firstSignIn'],
            router,
            shallow: true
          });
        }, 3000);
      } else {
        piano.executeExperience();
      }
    });

    // router.query.id and router.query.episode is added to the dependencies so that when new content is played the experience is executed again
    // We don't want to add firstSignIn and router to dependencies to prevent double experience execution
  }, [enabled, router.query.id, router.query.episode, fetchStatus, ldClient]);
};
