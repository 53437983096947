import { client } from 'lib/graphqlRequest';
import type {
  MutationSaveSeriesEpisodeProgressArgs,
  MutationVideoProgressArgs,
  QueryPlayerUrlArgs,
  QueryVideoMediaArgs
} from 'helpers/types/jwplayer-subgraph';

import {
  GET_MEDIA_ITEM,
  GET_DRM_MEDIA_ITEM,
  SAVE_VIDEO_PROGRESS,
  SAVE_SERIES_VIDEO_PROGRESS,
  GET_PLAYER_URL,
  GET_SOURCES
} from './graphql';
import type {
  PlayerUrlResponse,
  MediaItemResponse,
  MediaItemParams,
  SaveVideoProgressResponse,
  SaveVideoProgressParams,
  SaveSeriesProgressResponse,
  PlayerUrlParams,
  SaveSeriesProgressParams,
  SourcesResponse
} from './types';

export const getSources = (variables: MediaItemParams) =>
  client.request<SourcesResponse, QueryVideoMediaArgs>(GET_SOURCES, variables);

export const getMediaItem = (variables: MediaItemParams) =>
  client.request<MediaItemResponse, QueryVideoMediaArgs>(
    GET_MEDIA_ITEM,
    variables
  );

export const getDrmMediaItem = (variables: MediaItemParams) =>
  client.request<MediaItemResponse, QueryVideoMediaArgs>(
    GET_DRM_MEDIA_ITEM,
    variables
  );

export const saveVideoProgress = (data: SaveVideoProgressParams) =>
  client.request<SaveVideoProgressResponse, MutationVideoProgressArgs>(
    SAVE_VIDEO_PROGRESS,
    { data }
  );

export const saveSeriesProgress = (data: SaveSeriesProgressParams) =>
  client.request<
    SaveSeriesProgressResponse,
    MutationSaveSeriesEpisodeProgressArgs
  >(SAVE_SERIES_VIDEO_PROGRESS, {
    data
  });

export const getDrmPlayerUrl = (variables: PlayerUrlParams) =>
  client.request<PlayerUrlResponse, QueryPlayerUrlArgs>(
    GET_PLAYER_URL,
    variables
  );
