import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import type {
  ForwardedRef,
  InputHTMLAttributes,
  PropsWithChildren
} from 'react';
import { forwardRef } from 'react';
import Icon from 'components/01-atoms/Icon';
import { OUTSIDE_INC_URLS } from 'helpers/constants';

import { Link } from './Typography';
import { FreeSelectorTile, PaidSelectorTile } from './SelectorTile';

type Props<Value = string> = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'className' | 'onChange' | 'value'
> & {
  className?: ClassValue;
  onChange?: (value: Value) => void;
  value?: Value;
};

type AgreementsReminderProps = {
  className?: string;
};

export const AgreementsReminder = ({
  children,
  className
}: PropsWithChildren<AgreementsReminderProps>) => (
  <span className={clsx('text-headline-10 text-dark-gray block', className)}>
    {children}
    <Link href={OUTSIDE_INC_URLS.TERMS_AND_CONDITIONS} target="_blank">
      Terms of Use
    </Link>{' '}
    and{' '}
    <Link href={OUTSIDE_INC_URLS.PRIVACY_POLICY} target="_blank">
      Privacy&nbsp;Policy
    </Link>
    .
  </span>
);

export const Agreements = forwardRef(
  (
    {
      className,
      hasError,
      ...restProps
    }: InputHTMLAttributes<HTMLInputElement> & { hasError?: boolean },
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <div className={clsx('text-left w-full', className)}>
      <div>
        <label
          className={clsx('flex-auto text-12 flex gap-3', {
            'text-error': hasError
          })}
          htmlFor="agreement"
        >
          <div className="flex items-center relative">
            <input
              ref={ref}
              className={clsx(
                'peer appearance-none w-[18px] h-[18px] bg-transparent border-2 block rounded checked:bg-[#009CDD]',
                {
                  'border-[#009CDD]': !hasError,
                  'border-error': hasError
                }
              )}
              id="agreement"
              type="checkbox"
              {...restProps}
            />
            <Icon
              className="text-white absolute invisible peer-checked:visible"
              type="check"
              width={18}
            />
          </div>
          I agree to receive emails and offers from Outside and its affiliated
          brands. I may opt out at any time.
        </label>
      </div>
      <AgreementsReminder className="mt-2 ml-7">
        By clicking &quot;Create Account&quot;, you agree to Outside’s <br />
      </AgreementsReminder>
    </div>
  )
);

export const SubscriptionSelector = ({
  className,
  onChange,
  value
}: Props<'free' | 'paid'>) => (
  <div className={clsx(className, 'flex flex-col lg:flex-row gap-6 mb-8')}>
    <FreeSelectorTile
      className="flex-1"
      onSelect={() => onChange('free')}
      selected={value === 'free'}
    />
    <PaidSelectorTile
      className="flex-1"
      onSelect={() => onChange('paid')}
      selected={value === 'paid'}
    />
  </div>
);
