import { useCallback } from 'react';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import VideoGate from 'components/03-organisms/VideoGate';
import DrmVideoGate from 'components/03-organisms/DrmVideoGate';

import { usePortalRenderer } from './usePortalRenderer';

type Input = {
  unmountCallback?: VoidFunction;
};

type Output = {
  mountVideo: (mediaItem: MediaItem) => void;
  unmountVideo: () => void;
};

export const useMountVideo = ({ unmountCallback }: Input = {}): Output => {
  const { mountPortal, unmountPortal } = usePortalRenderer();

  const handleBack = useCallback(() => {
    unmountCallback?.();
    unmountPortal();
  }, [unmountCallback, unmountPortal]);

  const mountVideo = useCallback(
    (mediaItem: MediaItem) => {
      const { filmMediaItem, isDrmProtected } = mediaItem;
      const isDrm = filmMediaItem?.isDrmProtected || isDrmProtected;

      if (isDrm) {
        return mountPortal(
          <DrmVideoGate id={mediaItem.filmId} onBack={handleBack} />
        );
      }

      return mountPortal(
        <VideoGate mediaItem={mediaItem} onBack={handleBack} />
      );
    },
    [mountPortal, handleBack]
  );

  return { mountVideo, unmountVideo: handleBack };
};
