import useSaveVideoProgress from 'apis/mediaItem/hooks';
import { DRM_VIDEO_PLAYER_ID } from 'helpers/constants/jwplayer';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import calculateSeekTo from 'helpers/utils/calculateSeekTo';
import FallbackErrorPlayer from 'components/02-molecules/FallbackErrorPlayer';
import { useCallback } from 'react';
import { useRouter } from 'next/router';

import EnhancedVideoPlayer from '../EnhancedVideoPlayer';
import ErrorBoundary from '../ErrorBoundary';
import { removeQueryParams } from '../../../helpers/utils/url/removeQueryParams';

type Props = {
  mediaItem: MediaItem;
  onBack: VoidFunction;
  playerScript?: string;
};

const ComposedDrmVideoPlayer = ({ mediaItem, onBack, playerScript }: Props) => {
  const router = useRouter();
  const { associatedTrailerId, creditTimestamp, mediaItemLink } = mediaItem;

  const seekTo = calculateSeekTo(mediaItem.userProgress);

  const { saveProgress } = useSaveVideoProgress(
    mediaItemLink,
    associatedTrailerId
  );

  const handleOnTime = ({ currentTime }: jwplayer.TimeParam) => {
    if (creditTimestamp && currentTime > creditTimestamp) {
      saveProgress(creditTimestamp);
    } else {
      saveProgress(currentTime);
    }
  };

  const handleOnBackWithUrlCleanup = useCallback(() => {
    onBack?.();
    removeQueryParams({
      paramsToRemove: ['mode'],
      router,
      shallow: true
    });
  }, [onBack, router]);

  return (
    <ErrorBoundary
      errorDetails={{
        location: 'ComposedDrmVideoPlayer player'
      }}
      fallbackComponent={
        <FallbackErrorPlayer
          onBack={handleOnBackWithUrlCleanup}
          ratio="fullscreen"
        />
      }
      type="fallback"
    >
      <EnhancedVideoPlayer
        closeOnOneHundredPercent
        contentType="FILM"
        isSticky
        mediaItem={mediaItem}
        onBack={handleOnBackWithUrlCleanup}
        onTime={handleOnTime}
        playerId={DRM_VIDEO_PLAYER_ID}
        playerScript={playerScript}
        playlist={mediaItemLink}
        seekTo={seekTo}
        shouldUsePiano
      />
    </ErrorBoundary>
  );
};

export default ComposedDrmVideoPlayer;
