import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

import type { IconPosition } from './types';

export const containedButtonVariants = cva(
  'flex justify-center items-center rounded-full font-plus-jakarta-sans font-semibold duration-150 ease-in-out border-2 active:scale-95 focus:border-focus disabled:!bg-btn-disabled disabled:!text-btn-disabled-text disabled:!border-btn-disabled',
  {
    defaultVariants: {
      color: 'primary',
      size: 'lg'
    },
    variants: {
      color: {
        dark: 'bg-neutral-900 text-white border-0 hover:bg-new-neutral-900',
        mono: 'bg-new-neutral-900 text-white border-new-neutral-900 hover:bg-btn-secondary-hover',
        primary:
          'bg-btn-primary border-btn-primary hover:bg-btn-primary-hover active:bg-btn-primary-hover text-black',
        secondary: 'bg-new-neutral-900, text-white border-white'
      },
      size: {
        lg: 'text-16 narrow-x:text-18 leading-5 narrow-x:leading-6 gap-2 px-[22px] narrow-x:px-[30px] py-2.5 narrow-x:py-3.5',
        md: 'text-16 leading-5 gap-2 px-[22px] py-2.5',
        sm: 'text-14 leading-4 gap-1 px-[18px] py-2.5'
      }
    }
  }
);

export const textButtonVariants = cva(
  'flex items-center gap-x-2 bg-transparent font-plus-jakarta-sans disabled:text-btn-disabled-text disabled:hover:text-btn-disabled-text font-semibold duration-150 ease-in-out',
  {
    defaultVariants: {
      color: 'secondary',
      size: 'md'
    },
    variants: {
      color: {
        secondary:
          'text-btn-secondary hover:text-btn-secondary-hover focus:border-focus'
      },
      size: {
        lg: 'text-18 leading-6',
        md: 'text-16 leading-5',
        sm: 'text-14 leading-5'
      }
    }
  }
);

export const getContainedButtonClasses = ({ color, fullWidth, size }) =>
  clsx(containedButtonVariants({ color, size }), {
    'w-full': fullWidth
  });

export const getTextButtonClasses = ({ color, size }) =>
  textButtonVariants({
    color,
    size
  });

export type ButtonSize = VariantProps<typeof containedButtonVariants>['size'];

export const getIconWrapperSizes = (size: ButtonSize) => {
  switch (size) {
    case 'sm':
      return 'w-4';
    case 'md':
      return 'w-5';
    case 'lg':
    default:
      return 'w-5 narrow-x:w-6';
  }
};

export const getIconShiftClasses = (
  iconPosition: IconPosition,
  size: ButtonSize
) => {
  const isLeft = iconPosition === 'left';
  switch (size) {
    case 'sm':
    case 'md':
      return isLeft ? '-ml-1' : '-mr-1';
    case 'lg':
    default:
      return isLeft ? '-ml-1 narrow-x:-ml-2' : '-mr-1 narrow-x:-mr-2';
  }
};
