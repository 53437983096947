import { useEffect, useState } from 'react';

// This hook waits until the old player script is removed and the new one is loaded when there is a player change
// It is used to prevent the player from being initialized with old player ID
export const usePlayerScriptReady = () => {
  const [isPlayerScriptReady, setIsPlayerScriptReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsPlayerScriptReady(true);
    }, 0);
  }, []);

  return isPlayerScriptReady;
};
