export const enterFullscreen = (element: HTMLDivElement) =>
  element[
    [
      'requestFullscreen',
      'mozRequestFullScreen',
      'msRequestFullscreen',
      'webkitRequestFullscreen'
    ].find((prop) => typeof element[prop] === 'function')
  ]?.();

export const exitFullscreen = () =>
  document[
    [
      'exitFullscreen',
      'mozExitFullscreen',
      'msExitFullscreen',
      'webkitExitFullscreen'
    ].find((prop) => typeof document[prop] === 'function')
  ]?.();

export const getFullscreenModeStatus = () => {
  const fullscreenMethod =
    document[
      [
        'fullscreenElement',
        'mozFullScreen',
        'msFullscreenElement',
        'webkitFullscreenElement'
      ].find((prop) => prop !== undefined)
    ];

  return Boolean(fullscreenMethod);
};
