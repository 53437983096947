import { MEDIA_ITEM_FRAGMENT, USER_PROGRESS_FRAGMENT } from 'apis/fragments';
import { gql } from 'graphql-request';

export const GET_SOURCES = gql`
  query getSources($id: ID!) {
    mediaItem: videoMedia(id: $id) {
      sources {
        file
      }
    }
  }
`;

export const GET_MEDIA_ITEM = gql`
  query getMediaItem($id: ID!) {
    mediaItem: videoMedia(id: $id) {
      ...MediaItemFragment
      ...UserProgressFragment
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;

export const GET_DRM_MEDIA_ITEM = gql`
  query getDrmMediaItem($id: ID!) {
    mediaItem: videoMedia(id: $id) {
      mediaItemLink
      ...MediaItemFragment
      ...UserProgressFragment
    }
  }
  ${MEDIA_ITEM_FRAGMENT}
  ${USER_PROGRESS_FRAGMENT}
`;

export const SAVE_VIDEO_PROGRESS = gql`
  mutation VideoProgress($data: VideoProgressInput!) {
    videoProgress(data: $data) {
      message
    }
  }
`;

export const SAVE_SERIES_VIDEO_PROGRESS = gql`
  mutation SeriesVideoProgress($data: SeriesEpisodeProgressInput!) {
    saveSeriesEpisodeProgress(data: $data) {
      message
    }
  }
`;

export const GET_PLAYER_URL = gql`
  query PlayerUrl($playerId: ID!) {
    playerUrl(playerId: $playerId) {
      url
    }
  }
`;
