import { createRoot } from 'react-dom/client';

import { disableNativeJWPlayerFullscreen } from '../../../helpers/hooks/useFullscreenModeObserver';
import FullscreenControlButton from '../../01-atoms/FullscreenControlButton';

const CONTROLS_CONTAINER_SELECTOR =
  '#enhanced-video-player .jw-button-container';

export const createCustomFullscreenButton = () => {
  const childNode = document.createElement('div');
  const parentNode = document.querySelector(CONTROLS_CONTAINER_SELECTOR);

  parentNode.removeChild(parentNode.lastChild);

  parentNode.appendChild(childNode);

  return childNode;
};

export const prepareCustomFullscreen = (
  playerId: string,
  toggleFullScreen: VoidFunction
) => {
  disableNativeJWPlayerFullscreen(playerId);
  const buttonMountingDiv = createRoot(createCustomFullscreenButton());
  buttonMountingDiv.render(
    <FullscreenControlButton toggle={toggleFullScreen} />
  );
};
