import type { ReactNode } from 'react';
import Image from 'next/image';
import type { BackgroundVariant } from 'components/04-templates/PianoModals/modalPropTypes';

import { BackButton, CloseButton } from './Buttons';

export type Props = {
  backgroundVariant?: BackgroundVariant;
  children: ReactNode;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
  slogan?: ReactNode;
};

export const Layout = ({
  backgroundVariant = '1',
  children,
  onBack,
  onClose,
  slogan
}: Props) => (
  <div className="text-white w-screen bg-[#222222aa] h-screen overflow-y-auto fixed bottom-0 z-[10] flex flex-col justify-end font-plus-jakarta-sans">
    <div className="flex bg-[#222222] text-[#D3D3D3] relative overflow-y-auto">
      <div className="flex-1 px-5 py-6 md:px-20 md:py-16 overflow-y-auto">
        {onBack && <BackButton className="mb-[15px]" onClick={onBack} />}
        <div className="flex justify-end">
          {onClose && (
            <CloseButton
              className="xl:hidden mb-[15px] flex"
              iconColor="text-dark-gray"
              onClick={onClose}
            />
          )}
        </div>
        <div className="max-w-[600px] text-center mx-auto">{children}</div>
      </div>
      <div className="flex-1 hidden xl:flex flex-col justify-between relative pt-10 pr-10 pb-20 pl-20">
        <div className="z-[10] flex justify-end">
          {onClose && (
            <CloseButton
              className="mb-[15px] flex-0"
              iconColor="text-black"
              onClick={onClose}
            />
          )}
        </div>
        <div className="z-[10]">
          <h2 className="font-plus-jakarta-sans text-35 font-800 whitespace-pre-line text-white">
            {slogan}
          </h2>
        </div>
        <Image
          alt={`image-${backgroundVariant}`}
          className="object-cover object-center"
          fill
          priority
          quality={70}
          src={`/piano-modal/background-${backgroundVariant}.jpg`}
        />
      </div>
    </div>
  </div>
);
