import { useQuery } from '@tanstack/react-query';

import { getUserProfile } from './queryFunction';

export const USER_PROFILE_QUERY_KEY = 'userProfile';

export const useUserProfile = (rivtToken: string) =>
  useQuery({
    enabled: !!rivtToken,
    queryFn: () => getUserProfile(rivtToken).then((response) => response.data),
    queryKey: [USER_PROFILE_QUERY_KEY, rivtToken]
  });
