import Icon from 'components/01-atoms/Icon';

type Props = {
  onClick: VoidFunction;
};

export const BackButton = ({ onClick }: Props) => (
  <button
    aria-label="Go back"
    className="absolute top-5 left-5 narrow-x:top-16 narrow-x:left-23 z-40"
    onClick={onClick}
    title="Close"
    type="button"
  >
    <Icon type="arrowLeftCircle" width={40} />
  </button>
);
