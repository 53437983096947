import type { ReactElement } from 'react';
import { useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { usePortalRendererUpdaterContext } from 'contexts/PortalRendererContext';

type Output = {
  mountPortal: (Component: ReactElement) => void;
  unmountPortal: VoidFunction;
};

export const usePortalRenderer = (): Output => {
  const router = useRouter();
  const renderComponent = usePortalRendererUpdaterContext();

  const unmountPortal = useCallback(() => {
    renderComponent(null);
  }, [renderComponent]);

  const mountPortal = useCallback(
    (Component: ReactElement) => {
      renderComponent(Component);
    },
    [renderComponent]
  );

  useEffect(() => {
    router.beforePopState(({ as }) => {
      // Will run when leaving the current page; on back/forward actions
      if (as !== router.asPath) {
        unmountPortal();
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router, unmountPortal]);

  return { mountPortal, unmountPortal };
};
