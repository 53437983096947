import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

export type PlayerState = 'idle' | 'paused' | 'playing';
export type PlayerLocked = 'blocked' | 'ready';

export type ContextState = {
  currentState: PlayerState;
  isPlayerReady: boolean;
  playerRef: JWPlayer;
  targetState: PlayerLocked;
};

export type ContextUpdater = {
  setCurrentState: Dispatch<SetStateAction<PlayerState>>;
  setPlayerReady: Dispatch<SetStateAction<boolean>>;
  setPlayerRef: Dispatch<SetStateAction<JWPlayer>>;
  setTargetState: Dispatch<SetStateAction<PlayerLocked>>;
};

export const PlayerStateContext = createContext<ContextState>(null);
export const PlayerUpdaterContext = createContext<ContextUpdater>(null);

export const usePlayerStateContext = () => {
  const ctx = useContext(PlayerStateContext);

  if (!ctx) {
    throw new Error('Component beyond PlayerStateContext');
  }

  return ctx;
};

export const usePlayerUpdaterContext = () => {
  const ctx = useContext(PlayerUpdaterContext);

  if (!ctx) {
    throw new Error('Component beyond PlayerUpdaterContext');
  }

  return ctx;
};
